import React, {Component} from "react";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import UploadFileComponent from "../common/FileUploadComponent";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const defaultState = {
	file: null,

	uploading: false,
	uploadProgress: 0,
	uploadError: null,
	name: '',
};

class EditorUploadPdfDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
			});
		}
	}

	render() {
		const isCollectionChild = 'COLLECTION' === this.props.document?.childType;
		const acceptedContentTypes = 'application/pdf, .doc, .docx, image/png, image/jpeg, image/tiff, image/bmp image/gif' + ((isCollectionChild || this.props.collectionChildAdd) ? ', .xls*' : '');
		const hasPlacedSignatures = (this.props.document?.signers || []).some(signer => signer.signRequestState === 'SIGNED');
		const pdfConvertableFileFormat = this.state.file?.name.endsWith('.doc') || this.state.file?.name.endsWith('.docx') || this.state.file?.name.endsWith('.ppt') || this.state.file?.name.endsWith('.pptx');

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					label={this.props.t('document.name')}
					required
					value={this.state.name}
					onChange={this.onChangeName}
					autoComplete="off"
					fullWidth
					autoFocus
					id="input-document-name-text"
					inputProps={{maxLength: 251}}
					sx={{mt: 1, mb: 2}}
				/>
				<UploadFileComponent disabled={false}
									 onAddFile={this.onAddFile}
									 uploadProgress={this.state.uploadProgress}
									 uploadedFile={this.state.file}
									 uploadBoxType={'static'}
									 accepted={acceptedContentTypes}/>
				{this.state.uploadError && <Box sx={{mt: 1, mb: 1}}>
					<Alert severity="error">{this.props.t(this.state.uploadError)}</Alert>
				</Box>}
				{hasPlacedSignatures && <Box sx={{mt: 1}}>
					<Alert severity="warning">{this.props.t('editor.changeDocumentSignaturesWarning')}</Alert>
				</Box>}
				{pdfConvertableFileFormat && <Alert severity="info" sx={{mt: 1}}>
					{this.props.t('document.uploadFileConversionWarning')}
				</Alert>}
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-back">{this.props.t('back')}</Button>
				<Button variant="contained"
						disabled={!this.canContinue() || this.state.uploading}
						onClick={this.onChangePdf}
						id="btn-create-confirm"
				>
					{this.props.t('editor.changeDocumentUpload')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onAddFile = (file) => {
		if (!file) {
			return;
		}
		if (file?.size >= 104857600) {
			this.setState({uploadError: this.props.t('upload.invalidSize').replace('{0}', (104857600 / 1024 / 1024) + 'MB')});
		} else {
			const currentName = this.state.name;

			this.setState({
				file,
				name: currentName.length === 0 ? file.name : currentName,
				uploadError: null,
			});
		}
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	canContinue = () => {
		return !!this.state.file && this.state.name.trim().length > 0;
	}

	onChangePdf = () => {
		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				this.onProgressUpdate(Math.round((event.loaded * 100) / event.total));
			}
		})

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (!xhr.responseText) {
						this.props.onSessionDestroy();
						return;
					}
					const response = JSON.parse(xhr.responseText);
					if (!!response?.id && response.id > 0) {
						const rootDocument = this.props.editorEditInfo.document;
						let updatedDocument;
						if (rootDocument.documentCollection) {
							let updatedChildren;
							if (this.props.collectionChildAdd) {
								updatedDocument = {
									...response,
									childSeq: rootDocument.children.length+1
								};
								updatedChildren = rootDocument.children.concat(updatedDocument);
							} else {
								updatedDocument = response;
								updatedChildren = rootDocument.children.map(doc => doc.id === response.id ? response : doc);
							}
							this.props.onEditorUpdateDocument({
								...rootDocument,
								children: updatedChildren
							});
						} else {
							this.props.onEditorUpdateDocument(response);
						}

						if (!!this.props.onUploadSuccess) {
							this.props.onUploadSuccess(updatedDocument);
						} else {
							this.props.onClose();
						}
					} else if (!!response.error) {
						this.setState({
							uploadError: this.props.t('serverError.' + response.error),
							uploadProgress: 0
						});
					} else {
						this.setState({
							uploadError: 'upload.unknownError',
							uploadProgress: 0
						});
					}

				}
			}
		};

		xhr.onloadend = () => {
			this.setState({
				uploading: false
			});
		}

		const url = this.props.collectionChildAdd ? `/api/internal/document/${this.props.editorEditInfo.document.id}/add-child` : `/api/internal/document/${this.props.document.id}/upload`;
		xhr.open('POST', url, true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		const formData = new FormData();
		formData.append('file', this.state.file, encodeURIComponent(this.state.name));

		this.setState({
			uploading: true,
		})
		document.getElementById('file').value = null;

		xhr.send(formData);
	}

	onProgressUpdate = (progress) => {
		this.setState({uploadProgress: progress});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {
			onEditorUpdateDocument: (document) => {
				dispatch({
					type: 'EDITOR_UPDATE_DOCUMENT',
					document
				});
			},
		}
	}
)(EditorUploadPdfDialog));