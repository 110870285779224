export const EMAIL_LANGUAGES = [
	{value: 'ENGLISH', lang: 'en'},
	{value: 'DUTCH', lang: 'nl'},
	{value: 'FRENCH', lang: 'fr'},
];

export const DEFAULT_SIGN_EMAIL_HEADERS = {
	en: 'Dear $signer,\n' +
		'\n' +
		'You are invited by {0} to electronically sign document $document.',
	nl: 'Beste $signer,\n' +
		'\n' +
		'U bent uitgenodigd door {0} om document $document elektronisch te ondertekenen.',
	fr: 'Cher/chère $signer,\n' +
		'\n' +
		'Vous êtes invité par {0} à signer electroniquement le document $document.',
};

export const DEFAULT_SIGN_COLLECTION_EMAIL_HEADERS = {
	en: 'Dear $signer,\n' +
		'\n' +
		'You are invited by {0} to electronically sign collection $document.',
	nl: 'Beste $signer,\n' +
		'\n' +
		'U bent uitgenodigd door {0} om dossier $document elektronisch te ondertekenen.',
	fr: 'Cher/chère $signer,\n' +
		'\n' +
		'Vous êtes invité par {0} à signer electroniquement les documents $document.',
};

export const DEFAULT_APPROVE_EMAIL_HEADERS = {
	en: 'Dear $approver,\n' +
		'\n' +
		'You are invited by {0} to approve document $document.',
	nl: 'Beste $approver,\n' +
		'\n' +
		'U bent uitgenodigd door {0} om document $document goed te keuren.',
	fr: 'Cher/chère $approver,\n' +
		'\n' +
		'Vous êtes invité par {0} à approuvé le document $document.',
};

export const DEFAULT_APPROVE_COLLECTION_EMAIL_HEADERS = {
	en: 'Dear $approver,\n' +
		'\n' +
		'You are invited by {0} to approve collection $document.',
	nl: 'Beste $approver,\n' +
		'\n' +
		'U bent uitgenodigd door {0} om dossier $document goed te keuren.',
	fr: 'Cher/chère $approver,\n' +
		'\n' +
		'Vous êtes invité par {0} à approuvé les documents $document.',
};
