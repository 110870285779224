import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {formatInTimeZone} from "date-fns-tz";
import {withRouter} from "../common/RouterHelper";


class EditorActionLogsDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onEditorFetchActionLogs(this.props.document.id);
		}
	}

	render() {
		const signingMethodsTranslationMap = new Map(this.props.document?.signatureTypeConfigs?.map(config => ([config.name, config.label])));

		const columns = [
			{
				field: 'performedAt',
				headerName: this.props.t('editor.actionLogPerformedAt'),
				editable: false,
				type: 'dateTime',
				width: 200,
				valueFormatter: (value) => {
					return formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss");
				}
			},
			{
				field: 'ipAddress',
				headerName: this.props.t('editor.actionLogIpAddress'),
				editable: false,
				width: 150,
			},
			{
				field: 'type',
				editable: false,
				headerName: this.props.t('editor.actionLogEvent'),
				flex: 1,
				renderCell: (cellValues) => this.props.t('editor.actionLog_' + cellValues.row.type).replace(/\{[0-9]+\}/g, (p) => {
					const index = parseInt(p.substring(1, p.length - 1));

					// check if any value keys should be translated
					if (signingMethodsTranslationMap.has(cellValues.row.data[index])) {
						return signingMethodsTranslationMap.get(cellValues.row.data[index]);
					}

					// for download logs we concatenate the artifact types
					if (cellValues.row.type === 'DOWNLOADED' && index === 1) {
						const artifactTypes = cellValues.row.data.slice(1);
						return artifactTypes.map(type => this.props.t('editor.downloadArtifact_' + type))
							.map((type, index) => index > 0 ? type.toLowerCase() : type)
							.join(', ');
					}

					return cellValues.row.data[index] || '?';
				})
			}
		];

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="lg">
			<DialogTitle>{this.props.t('editor.actionLogs')}</DialogTitle>
			<DialogContent>
				<DataGrid
					autoPageSize
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					disableColumnMenu
					pagination

					columns={columns}

					initialState={{
						sorting: {
							sortModel: [{field: 'performedAt', sort: 'desc'}],
						}
					}}

					loading={!this.props.editorActionLogs}
					rows={(this.props.editorActionLogs || [])}
					density="compact"
					sx={{height: 500}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose}
						id="btn-action-logs-back"
				>
					{this.props.t('back')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			editorActionLogs: state.editor.actionLogs,
		}
	},
	dispatch => {
		return {
			onEditorFetchActionLogs: (documentId) => {
				dispatch({
					type: 'EDITOR_FETCH_ACTION_LOGS',
					documentId
				});
			},
		}
	}
)(EditorActionLogsDialog)));
