import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, IconButton, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import LoadingComponent from "../common/LoadingComponent";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DelegateListAddCreateDialog from "../delegate/DelegateListAddCreateDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";

class CompanyDelegatesComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			filterValueUC: '',

			addCreateDialogOpen: false,
			deleteDialogOpen: false,
			selectedListId: null,
			create: false,
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchRelevantUsers();
		this.props.onCompanyFetchDelegateLists();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyDelegateActionSuccessFully && !prevProps.companyDelegateActionSuccessFully) {
			if (this.state.create && !this.state.selectedListId && !!this.props.companyDelegateList && !prevProps.companyDelegateList) {
				this.setState({selectedListId: this.props.companyDelegateList.id}, this.props.onCompanyFetchDelegateLists);
			} else {
				this.props.onCompanyFetchDelegateLists();
			}
		} else if (!this.state.create && !!this.state.selectedListId && !!this.props.companyDelegateList && !prevProps.companyDelegateList) {
			this.setState({
				addCreateDialogOpen: true,
			})
		}
	}

	render() {
		const companyRelevantUsers = this.props.companyRelevantUsers;
		const companyDelegateLists = this.props.companyDelegateLists;

		if (!companyRelevantUsers || !companyDelegateLists) {
			return <LoadingComponent/>;
		}

		const dataGridColumns = [
			{
				field: 'type',
				headerName: this.props.t('delegates.type'),
				editable: false,
				width: 200,
				valueGetter: (value) => this.props.t('delegates.type_' + value)
			},
			{
				field: 'forPerson',
				headerName: this.props.t('delegates.for'),
				editable: false,
				flex: 1,
				valueGetter: (value) => !!value ? (value?.firstName + ' ' + value?.lastName) : this.props.t('delegates.forEveryone')
			},
			{
				field: 'delegates',
				headerName: this.props.t('delegates.count'),
				editable: false,
				width: 200,
				valueGetter: (value) => value?.length || 0
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('delegates.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listEdit')}
						onClick={() => this.onOpenDialogForEditing(cellValues.row.id)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listDelete')}
						onClick={() => this.onOpenDeleteDialog(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('delegates.listsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError} />
			<Box sx={{mt: 2, ml: 2}}>
				<Typography>{this.props.t('delegates.description')}</Typography>
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
					<Button variant="contained"
							onClick={this.onOpenDialogForAdding}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.companyBusy}
							id="btn-delegate-list-create"
					>
						{this.props.t('delegates.listCreate')}
					</Button>
				</Box>
				<DataGrid
					disableColumnFilter
					disableRowSelectionOnClick
					disableColumnSelector
					pagination

					initialState={{
						pagination: {
							paginationModel: { pageSize: 10, page: 0 },
						},
					}}

					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-delegates-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					getRowId={(row) => row.type + (row?.forPerson?.id || '')}
					rows={companyDelegateLists.filter(list =>
						!this.state.filterValue ||
						(list?.forPerson?.firstName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						(list?.forPerson?.lastName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						(list?.forPerson?.email || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0
					)}
					pageSizeOptions={[10]}
					loading={this.props.companyBusy}
					density="compact"
					autoHeight
				/>

				<DelegateListAddCreateDialog
						delegateList={this.props.companyDelegateList}
						relevantUsers={companyRelevantUsers}
						open={this.state.addCreateDialogOpen}
						onClose={this.onCloseAddCreateDialog}
						onSave={this.onSaveDelegateList}
						onRefresh={this.onRefreshDelegateList}
						onDownloadDelegateFile={this.props.onCompanyDownloadDelegateFile}
						onDeleteDelegateFile={this.props.onCompanyDeleteDelegateFile}
						getUploadFileUrl={(delegateListId, delegateId) => `/api/internal/company/current/delegate-list/${delegateListId}/delegate/${delegateId}/upload`}
				/>

				<ConfirmationDialog
						title={this.props.t('delegates.listDelete')}
						confirm={this.props.t('delegates.listDeleteConfirm')}
						open={this.state.deleteDialogOpen}
						onClose={this.onCloseDeleteDialog}
						onConfirm={this.onConfirmDeletion}
					/>
			</Box>
		</Box>
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onOpenDialogForAdding = () => {
		this.setState({create: true, addCreateDialogOpen: true});
	}

	onOpenDialogForEditing = (id) => {
		this.setState({selectedListId: id, create: false}, () => this.props.onCompanyFetchDelegateList(id));
	}

	onRefreshDelegateList = () => {
		this.props.onCompanyFetchDelegateList(this.state.selectedListId);
	}

	onCloseAddCreateDialog = () => {
		this.setState({addCreateDialogOpen: false, create: false}, () => this.props.onCompanyClearDelegateList());
	}

	onSaveDelegateList = (delegateList, closeDialog) => {
		this.setState({addCreateDialogOpen: !closeDialog}, () => this.props.onCompanyCreateUpdateDelegateList(delegateList));
	}

	onOpenDeleteDialog = (selectedListId) => {
		this.setState({selectedListId, deleteDialogOpen: true});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false, selectedListId: null});
	}

	onConfirmDeletion = () => {
		const id = this.state.selectedListId
		this.setState({selectedListId: null, deleteDialogOpen: false}, () => this.props.onCompanyDeleteDelegateList(id));
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyRelevantUsers: state.company.relevantUsers,
			companyDelegateLists: state.company.delegateLists,
			companyDelegateList: state.company.delegateList,
			companyDelegateActionSuccessFully: state.company.delegateActionSuccessFully
		}
	},
	dispatch => {
		return {
			onCompanyFetchRelevantUsers: () => {
				dispatch({
					type: 'COMPANY_FETCH_RELEVANT_USERS'
				})
			},
			onCompanyFetchDelegateLists: () => {
				dispatch({
					type: 'COMPANY_FETCH_DELEGATE_LISTS'
				})
			},
			onCompanyCreateUpdateDelegateList: (delegateList) => {
				dispatch({
					type: 'COMPANY_CREATE_UPDATE_DELEGATE_LIST',
					delegateList
				})
			},
			onCompanyFetchDelegateList: (id) => {
				dispatch({
					type: 'COMPANY_FETCH_DELEGATE_LIST',
					id
				})
			},
			onCompanyDeleteDelegateList: (id) => {
				dispatch({
					type: 'COMPANY_DELETE_DELEGATE_LIST',
					id
				})
			},
			onCompanyClearDelegateList: () => {
				dispatch({
					type: 'COMPANY_CLEAR_DELEGATE_LIST'
				})
			},
			onCompanyDownloadDelegateFile: (delegateListId, delegateId) => {
				dispatch({
					type: 'COMPANY_DOWNLOAD_DELEGATE_FILE',
					delegateListId, delegateId
				});
			},
			onCompanyDeleteDelegateFile: (delegateListId, delegateId) => {
				dispatch({
					type: 'COMPANY_DELETE_DELEGATE_FILE',
					delegateListId, delegateId
				});
			},
		}
	}
)(CompanyDelegatesComponent));
