import React, {Component, createRef, forwardRef, useCallback} from "react";
import {withTranslation} from "react-i18next";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Typography} from "@mui/material";
import ReactResizeDetector from "react-resize-detector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeleteIcon from "@mui/icons-material/Delete";
import {faFileArrowUp, faPencil} from "@fortawesome/free-solid-svg-icons";

const Canvas = forwardRef(({width, height}, ref) => {

	const data = {
		drawing: false,
		useTouch: false
	};

	const refCallback = useCallback((canvas) => {
		if (!canvas) return;

		const ctx = canvas.getContext('2d');
		ref.current = {
			clear: () => {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
			},
			export: () => {
				// offscreen canvas, resize to 320 width
				const targetWidth = 320;
				const targetHeight = 320 * canvas.height / canvas.width;

				// note: using downstepping (2 iterations), scaling the full canvas at once introduces too much quality loss
				const resizeIterations = 2;

				const sourceCanvasWidth = canvas.width;
				const sourceCanvasHeight = canvas.height;

				let ratio;
				const ratio1 = targetWidth / sourceCanvasWidth;
				const ratio2 = targetHeight / sourceCanvasHeight;

				if (ratio1 < ratio2) {
					ratio = ratio1;
				}
				else {
					ratio = ratio2;
				}

				const intermediateCanvas = document.createElement("canvas");
				const intermediateCanvas2dContext = intermediateCanvas.getContext("2d");

				const intermediate2Canvas = document.createElement("canvas");
				const intermediate2Canvas2dContext = intermediate2Canvas.getContext("2d");

				intermediateCanvas.width = sourceCanvasWidth;
				intermediateCanvas.height = sourceCanvasHeight;
				intermediateCanvas2dContext.drawImage(canvas, 0, 0);

				intermediate2Canvas.width = sourceCanvasWidth;
				intermediate2Canvas.height = sourceCanvasHeight;
				intermediate2Canvas2dContext.drawImage(canvas, 0, 0, intermediateCanvas.width, intermediateCanvas.height, 0, 0, intermediate2Canvas.width, intermediate2Canvas.height);

				const roundRatio = ratio * resizeIterations;
				for (let i = 1; i <= resizeIterations; i++) {
					// downscale
					const downScaledWidth = sourceCanvasWidth * roundRatio / i;
					const downScaledHeight = sourceCanvasHeight * roundRatio / i;

					intermediateCanvas.width = downScaledWidth;
					intermediateCanvas.height = downScaledHeight;
					intermediateCanvas2dContext.drawImage(intermediate2Canvas, 0, 0, intermediate2Canvas.width, intermediate2Canvas.height, 0, 0, intermediateCanvas.width, intermediateCanvas.height);

					// copy back for next iteration
					intermediate2Canvas.width = downScaledWidth;
					intermediate2Canvas.height = downScaledHeight;
					intermediate2Canvas2dContext.drawImage(intermediateCanvas, 0, 0, intermediateCanvas.width, intermediateCanvas.height, 0, 0, intermediate2Canvas.width, intermediate2Canvas.height);
				}

				// draw final
				const targetCanvas = document.createElement("canvas");
				targetCanvas.width = targetWidth;
				targetCanvas.height = targetHeight;

				const targetCanvas2dContext = targetCanvas.getContext("2d");
				targetCanvas2dContext.drawImage(intermediate2Canvas, 0, 0, intermediate2Canvas.width, intermediate2Canvas.height, 0, 0, targetCanvas.width, targetCanvas.height);
				return targetCanvas.toDataURL('image/png');
			}
		};

		const start = (e) => {
			const {left, top} = canvas.getBoundingClientRect();
			const x = (e.clientX || (e?.touches || [])[0]?.clientX) - left;
			const y = (e.clientY || (e?.touches || [])[0]?.clientY) - top;
			data.drawing = true;
			data.prevX = x;
			data.prevY = y;
		};

		const stop = (e) => {
			data.drawing = false;
		}

		const move = (e) => {
			const {drawing, prevX, prevY} = data;
			if (drawing) {
				const {left, top} = canvas.getBoundingClientRect();
				const x = (e.clientX || (e?.touches || [])[0]?.clientX) - left;
				const y = (e.clientY || (e?.touches || [])[0]?.clientY) - top;
				data.prevX = x;
				data.prevY = y;
				ctx.beginPath();
				ctx.moveTo(prevX, prevY);
				ctx.lineTo(x, y);
				ctx.stroke();
			}
		}

		canvas.addEventListener('mousedown', (e) => {
			if (data.useTouch) return;
			start(e);
		});
		canvas.addEventListener('mouseup', (e) => {
			if (data.useTouch) return;
			stop(e);
		});
		canvas.addEventListener('mousemove', (e) => {
			if (data.useTouch) return;
			move(e);
		});
		canvas.addEventListener('touchstart', (e) => {
			data.useTouch = true;
			start(e);
		});
		canvas.addEventListener('touchend', (e) => {
			data.useTouch = true;
			stop(e);
		});
		canvas.addEventListener('touchmove', (e) => {
			data.useTouch = true;
			move(e);
		});
	}, [])

	return <canvas
		ref={refCallback}
		width={width || 0}
		height={height || 0}
		style={{
			cursor: 'crosshair',
			background: 'repeating-linear-gradient(-45deg, #FFFFFF, #FFFFFF 10px, #F2F2F2 10px, #F2F2F2 20px)',
		}}
	>
	</canvas>;

});

const defaultState = {
	drawDialogOpen: false,
	popupOpen: false
};

class VisualSignatureOrParaphComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;

		this.canvasRef = createRef();
	}

	render() {
		const isSignature = this.props.type === 'signature';
		const visualData = this.props.visualSignature;

		return <Box sx={{...(!!this.props.sx && this.props.sx)}}>
			<Typography>
				{this.props.t(isSignature ? 'visualSignatureOrParaph.signatureHeader' : 'visualSignatureOrParaph.paraphHeader')}
			</Typography>

			{!visualData && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Button
					variant="contained"
					size="small"
					color="secondary"
					startIcon={<FontAwesomeIcon icon={faFileArrowUp} size="lg"/>}
					onClick={this.onUploadNewVisualSignatureOrParaph}
					disabled={this.props.disabled}
					id={`btn-visual-${this.props.type}-upload`}
				>
					{this.props.t('visualSignatureOrParaph.upload')}
				</Button>
				<Button
					variant="contained"
					size="small"
					color="secondary"
					startIcon={<FontAwesomeIcon icon={faPencil} size="lg"/>}
					onClick={this.onOpenDrawDialog}
					disabled={this.props.disabled}
					id={`btn-visual-${this.props.type}-draw`}
				>
					{this.props.t('visualSignatureOrParaph.draw')}
				</Button>
			</Box>}

			{!!visualData && <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
				<img
					style={{
						maxWidth: '200px',
						maxHeight: '50px',
						zIndex: 1000,
						cursor: 'zoom-in',
						border: '1px solid black',
						borderRadius: 5
					}}
					src={'data:image/png;base64,' + visualData}
					alt={this.props.t(isSignature ? 'visualSignatureOrParaph.signatureCurrent' : 'visualSignatureOrParaph.paraphCurrent')}
					onClick={this.onPopupOpen}
				/>

				<IconButton
					variant="contained"
					color="primary"
					title={this.props.t('visualSignatureOrParaph.delete')}
					onClick={this.onDeleteVisualSignature}
					disabled={this.props.disabled}
				>
					<DeleteIcon/>
				</IconButton>
			</Box>
			}

			{!!visualData && this.state.popupOpen && <Modal
				open={true}
				onClose={this.onPopupClose}
				style={{display:'flex',alignItems:'center',justifyContent:'center'}}
			>
				<img
					style={{
						maxWidth: '40%',
						border: '1px solid black',
						borderRadius: 5,
						zIndex: 1000,
						backgroundColor: '#FFF'
					}}
					src={'data:image/png;base64,' + visualData}
					alt={this.props.t(isSignature ? 'visualSignatureOrParaph.signatureCurrent' : 'visualSignatureOrParaph.paraphCurrent')}
					onClick={this.onPopupClose}
				/>
			</Modal>
			}

			<input id={`file-${this.props.type}`} type='file' accept=".png, .jpeg, .jpg"
				   style={{display: 'none'}}></input>

			<Dialog open={this.state.drawDialogOpen}
					onClose={this.onCloseDrawDialog}
					fullWidth
					maxWidth="md"
					fullScreen={window.innerWidth < 400}
			>
				<DialogTitle>{this.props.t(isSignature ? 'visualSignatureOrParaph.signatureDraw' : 'visualSignatureOrParaph.paraphDraw')}</DialogTitle>
				<DialogContent>
					<ReactResizeDetector handleWidth>
						{({width}) => {
							return <Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Canvas ref={this.canvasRef}
										width={isSignature ? width : Math.min(200, width)}
										height={isSignature ? width / 6 : Math.min(200, width)}/>
							</Box>
						}}
					</ReactResizeDetector>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.onCloseDrawDialog}
						id={`btn-visual-${this.props.type}-draw-cancel`}
					>
						{this.props.t('back')}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.onClearDrawing}
						id={`btn-visual-${this.props.type}-draw-clear`}
					>
						{this.props.t('visualSignatureOrParaph.clear')}
					</Button>
					<Button
						variant="contained"
						onClick={this.onConfirmDrawing}
						id={`btn-visual-${this.props.type}-draw-confirm`}
					>
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>;
	}

	onPopupOpen = () => {
		this.setState({popupOpen: true});
	}

	onPopupClose = () => {
		this.setState({popupOpen: false});
	}

	onUploadNewVisualSignatureOrParaph = () => {
		if (this.props.disabled) {
			return;
		}

		const fileEl = document.getElementById(`file-${this.props.type}`);
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index1 = result.indexOf(';');
					const index2 = result.indexOf(',');
					if (index1 > 0 && index2 > index1) {
						this.props.onChangeVisualSignature(result.substring(index2 + 1), result.substring(5, index1), file.size);
					}
					// clear value so that we can reselect the same file again if necessary
					fileEl.value = null;
				}
				reader.readAsDataURL(file);
			}
		};

		fileEl.click();
	}

	onDeleteVisualSignature = () => {
		this.props.onChangeVisualSignature(null, null, null);
	}

	onOpenDrawDialog = () => {
		this.setState({drawDialogOpen: true});
	}

	onCloseDrawDialog = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.setState({drawDialogOpen: false});
		}
	}

	onClearDrawing = () => {
		this.canvasRef?.current?.clear();
	}

	onConfirmDrawing = () => {
		const data = this.canvasRef?.current?.export();
		this.setState({drawDialogOpen: false}, () => {
			if (!!data) {
				const index1 = data.indexOf(';');
				const index2 = data.indexOf(',');
				if (index1 > 0 && index2 > index1) {
					this.props.onChangeVisualSignature(data.substring(index2 + 1), data.substring(5, index1), data.length);
				}
			}
		});
	}

}

export default withTranslation()(VisualSignatureOrParaphComponent);
