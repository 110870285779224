import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import TemplateSelectionDialog from "../template/TemplateSelectionDialog";
import {withRouter} from "../common/RouterHelper";

class DocumentCreateFromTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: null,
            folderId: null,
            template: null,
            templateSelectionDialogOpen: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.props.onDocumentFetchOverviewInfo();
        } else if (!this.state.folderId && !!this.props.documentOverviewInfo) {
            const folderId = this.determineInitialFolderId();
            if (!!folderId) {
                this.setState({folderId});
            }
        }
    }

    render() {
        return <Dialog open={this.props.open}
                       onClose={this.onClose}
                       onKeyUp={this.onKeyUp}
                       fullWidth
                       maxWidth="md"
        >
            <DialogTitle>{this.props.t('document.createFromTemplate')}</DialogTitle>
            <DialogContent>
                <Stepper activeStep={-1} orientation="vertical">
                    <Step active>
                        <StepLabel>{this.props.t('document.createName')}</StepLabel>
                        <StepContent>
                            <TextField
                                variant="outlined"
                                label={this.props.t('document.name')}
                                required
                                value={this.state.name || ''}
                                onChange={this.onChangeName}
                                id="input-document-name-text"
                                autoComplete="off"
                                fullWidth
                                autoFocus
                                inputProps={{maxLength: 251}}
                            />
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel>{this.props.t('document.createFolder')}</StepLabel>
                        <StepContent>
                            <FormControl fullWidth>
                                <Select
                                    value={this.state.folderId || ''}
                                    onChange={this.onChangeFolderId}
                                    disabled={this.props.documentBusy}
                                    inputProps={{id: 'input-document-folder-select'}}
                                >
                                    {(this.props.documentOverviewInfo?.folders || [])
                                        .filter(folder => folder.userCanCreateAndEditDocuments)
                                        .map(folder =>
                                                <MenuItem key={folder.id} value={folder.id}>
                                                    {folder.name}
                                                </MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel>{this.props.t('document.createTemplate')}</StepLabel>
                        <StepContent>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    variant="outlined"
                                    required
                                    value={this.state.template?.name || ''}
                                    disabled
                                    sx={{mr: 2, flex: 1}}
                                />

                                <Button
                                    variant="contained"
                                    onClick={this.onTemplateSelectionDialogOpen}
                                    id="btn-document-create-from-template-select"
                                    sx={{width: '260px'}}
                                >
                                    {this.props.t('document.selectTemplate')}
                                </Button>
                            </Box>

                        </StepContent>
                    </Step>


                    <Step active>
                        <StepLabel>{this.props.t('document.createFinish')}</StepLabel>
                        <StepContent>
                            <Typography>{this.props.t('document.createFinishDescription')}</Typography>
                        </StepContent>
                    </Step>
                </Stepper>
            </DialogContent>

            <DialogActions>
                <Button onClick={this.props.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
                <Button variant="contained" disabled={!this.canContinue()}
                        onClick={this.onCreate} id="btn-settings-confirm">
                    {this.props.t('document.create')}
                </Button>
            </DialogActions>

            <TemplateSelectionDialog
                open={this.state.templateSelectionDialogOpen}
                onTemplateSelected={this.onTemplateSelected}
                onClose={this.onTemplateSelectionDialogClosed}
            />
        </Dialog>
    }

    determineInitialFolderId = () => {
        return (this.props.documentOverviewInfo?.folders || [])
            .filter(folder => folder.userCanCreateAndEditDocuments)
            .map(folder => folder.id)
            .at(0);
    }

    canContinue = () => {
        return (!!this.state.name?.trim()  && !!this.state.folderId && !!this.state.template);
    }

    onChangeName = (e) => {
        this.setState({name: e.target.value});
    }

    onChangeFolderId = (e) => {
        this.setState({folderId: e.target.value});
    }

    onTemplateSelectionDialogOpen = () => {
        this.setState({templateSelectionDialogOpen: true});
    }

    onTemplateSelectionDialogClosed = () => {
        this.setState({templateSelectionDialogOpen: false});
    }

    onTemplateSelected = (template) => {
        let name = this.state.name;
        if (!name) {
            name = template?.name;
        }
        this.setState({templateSelectionDialogOpen: false, template, name});
    }

    onKeyUp = (e) => {
        if (e.key === 'Enter' && this.canContinue) {
            this.onCreate();
        }
    }

    onCreate = () => {
        this.props.onCreate({
            name: this.state.name,
            folderId: this.state.folderId,
            templateId: this.state.template.id
        })
    }

    onClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            this.props.onClose();
        }
    }
}

export default withRouter(withTranslation()(connect(
    state => {
        return {
            documentOverviewInfo: state.document.overviewInfo,
        }
    },
    dispatch => {
        return {
            onDocumentFetchOverviewInfo: () => {
                dispatch({
                    type: 'DOCUMENT_FETCH_OVERVIEW_INFO',
                });
            },
        }
    }
)(DocumentCreateFromTemplate)));
