const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// data
	data: null,

	// documents finished
	documentsFinished: null,
	documentsRequested: null,
};

const approval = (state = defaultState, action) => {
	switch (action.type) {
		case 'APPROVAL_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'APPROVAL_FETCH_DATA':
			return {
				...state,
				busy: true,
				serverError: null,
				data: null,
				documentsFinished: null,
				documentsRequested: null,
			}

		case 'APPROVAL_FETCH_DATA_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				data: action.data
			}

		case 'APPROVAL_APPROVE_OR_DECLINE_START':
			return {
				...state,
				busy: true,
				serverError: null,
				documentsFinished: [],
				documentsRequested: action.ids
			}

		case 'APPROVAL_APPROVE_OR_DECLINE_UPDATE':
			return {
				...state,
				data: {
					...state.data,
					documents: (state.data?.documents || []).map(doc =>
						doc.documentId === action.document.documentId ? action.document : doc)
				},
				documentsFinished: [
					...state.documentsFinished,
					action.document.documentId
				]
			}

		case 'APPROVAL_APPROVE_OR_DECLINE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
			}

		case 'APPROVAL_APPROVE_OR_DECLINE_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError,
			}

		case 'APPROVAL_MESSAGE_FETCH_COUNT':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'APPROVAL_MESSAGE_FETCH_COUNT_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				data: {
					...state.data,
					documents: state.data.documents.map(doc => doc.documentId === action.documentId ? ({
						...doc,
						messageCount: action.data
					}) : doc)
				}
			}

		default:
			return state;
	}
}

export default approval;
