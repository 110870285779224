import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Tab, Tabs, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FlexibleToolbar from "../common/FlexibleToolbar";
import ServerErrorComponent from "../common/ServerErrorComponent";
import TaskIcon from '@mui/icons-material/Task';
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {formatInTimeZone} from "date-fns-tz";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS
} from "../common/DataGridColumUtil";
import {DEBOUNCE} from "../common/DebounceUtil";
import {withRouter} from "../common/RouterHelper";

const COLUMN_CONFIG_KEY = 'doc-approval-overview';

class DocumentApprovalOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'documentName', sort: 'asc'}],
			// selection
			selectedIds: [],
			// tabs
			tab: 'WAIT_FOR_APPROVAL',
			// download menu
			downloadMenuAnchorEl: null,
			// columns
			columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['documentName', 'documentDescription', 'documentFolderName', 'orderType', 'creatorName']),
		}

		this.possibleTabs = [
			'ALL',
			'WAIT_FOR_APPROVAL',
			'DOWNLOAD'
		];
	}

	render() {
		const columnConfiguration = this.state.columnConfiguration;

		const idsOfDocumentsThatCanBeApproved = this.props.documentApprovalOverviewList
			.filter(entry => this.state.selectedIds.indexOf(entry.documentId) >= 0 && entry.approvalCount > 0)
			.map(entry => entry.documentId)
		;

		const allPossibleDataGridColumns = [
			{
				field: 'documentName',
				headerName: this.props.t('document.name'),
				editable: false,
				optional: false,
				...COLUMN_WIDTH(columnConfiguration, 'documentName', {flex: 1}),
				renderCell: (cellValues) => <Fragment>
					{cellValues.row.documentCollection && <FileCopyIcon fontSize="small" sx={{mr: 0.5}}/>}
					{cellValues.row.documentName +
						(cellValues.row.approvalCount > 1 ? (' (' + cellValues.row.approvalCount + ')') : '')}
				</Fragment>
			},
			{
				field: 'requestedAt',
				headerName: this.props.t('document.requestedAt'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'requestedAt', {flex: 1}),
				valueGetter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'documentDescription',
				headerName: this.props.t('document.description'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'documentDescription', {flex: 1}),
			},
			{
				field: 'documentFolderName',
				headerName: this.props.t('document.folderName'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'documentFolderName', {flex: 1}),
			},
			{
				field: 'approvalOrderType',
				headerName: this.props.t('document.approvalOrderType'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				...COLUMN_WIDTH(columnConfiguration, 'approvalOrderType', {flex: 1}),
				valueGetter: (value) => this.props.t('document.approvalOrderType_' + value)
			},
			{
				field: 'creatorName',
				headerName: this.props.t('document.creator'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'creatorName', {flex: 1}),
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('document.actions'),
				editable: false,
				sortable: false,
				resizable: false,
				disableColumnMenu: true,
				disableReorder: true,
				optional: false,
				width: 90,
				renderCell: (cellValues) => {
					const canApprove = cellValues.row.approvalCount > 0;
					return <Fragment>
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('document.download')}
							onClick={() => this.props.onDocumentSingleDownloadArtifacts(cellValues.row.documentId, ['DOCUMENT'])}
						>
							<DownloadIcon fontSize="small"/>
						</IconButton>
						<IconButton
							variant="contained"
							color="primary"
							title={canApprove ? this.props.t('document.approve') : this.props.t('document.view')}
							onClick={() => this.onDocumentNavigateToApprove([cellValues.row.documentId])}
						>
							{canApprove ?
								<TaskIcon fontSize="small"/> :
								<VisibilityIcon fontSize="small"/>}
						</IconButton>

					</Fragment>
				}
			},
		];

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('document.approvalOverviewHeader')}</Typography>
				<ServerErrorComponent serverError={this.props.documentServerError}/>
				<Box sx={{mb: 1}}>
					<Tabs value={this.state.tab} onChange={this.onChangeTab}>
						{this.possibleTabs.map(tab =>
							<Tab key={tab} value={tab} label={this.props.t('document.approvalOverviewTab_' + tab)}/>
						)}
					</Tabs>
				</Box>
				<Typography variant="body2"
							sx={{mt: 2, mb: 2}}>{this.props.t('document.downloadDisclaimer')}</Typography>
				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableColumnPinning
					columns={filteredColumns}
					onColumnResize={this.onResizeColumn}
					onColumnOrderChange={this.onColumnOrderChange}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-document-approval-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
							possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
							columns: filteredColumns.map(column => column.field),
							onChangeColumns: this.onChangeExtraColumns,
							onResetColumnsWidth: this.onResetColumnsWidth,
						}
					}}

					loading={this.props.documentBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableRowSelectionOnClick
					checkboxSelection
					onRowSelectionModelChange={this.onRowSelectionModelChange}

					getRowId={(row) => row.documentId}
					rows={this.props.documentApprovalOverviewList}
					rowCount={this.props.documentApprovalOverviewCount}
					density="compact"/>

				<Box sx={{mt: 2}}>
					{this.state.tab !== 'DOWNLOAD' && <Button
						variant="contained"
						startIcon={<TaskIcon/>}
						disabled={0 === idsOfDocumentsThatCanBeApproved.length}
						onClick={() => this.onDocumentNavigateToApprove(idsOfDocumentsThatCanBeApproved)}
						sx={{mr: 2}}
						id="btn-approval-bulk"
					>
						{this.props.t('document.approveDocuments') + ' (' + idsOfDocumentsThatCanBeApproved.length + ')'}
					</Button>}
					{this.state.tab === 'DOWNLOAD' && <Fragment>
						<Button
							variant="contained"
							startIcon={<DownloadIcon/>}
							disabled={0 === this.state.selectedIds.length}
							onClick={this.onDownloadMenuOpen}
							id="btn-approval-download"
						>
							{this.props.t('document.download') + ' (' + this.state.selectedIds.length + ')'}
						</Button>
						<Menu
							anchorEl={this.state.downloadMenuAnchorEl}
							open={!!this.state.downloadMenuAnchorEl}
							id="download-menu"
							onClose={this.onDownloadMenuClose}
							onClick={this.onDownloadMenuClose}
						>
							{[
								{
									label: this.props.t('document.downloadDocuments'),
									icon: <InsertDriveFileIcon fontSize="small"/>,
									types: ['DOCUMENT']
								},
								{
									label: this.props.t('document.downloadEvidenceReports'),
									icon: <AssignmentIcon fontSize="small"/>,
									types: ['EVIDENCE_REPORT']
								},
								{
									label: this.props.t('document.downloadAll'),
									icon: <FolderZipIcon fontSize="small"/>,
									types: ['DOCUMENT', 'EVIDENCE_REPORT']
								}
							].map((item, index) =>
								<MenuItem key={index}
										  onClick={() => this.props.onDocumentDownloadArtifacts(this.state.selectedIds, item.types)}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</MenuItem>)
							}
						</Menu>
					</Fragment>}
				</Box>
			</Paper>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, () => this.onDocumentFetchApprovalOverviewList());
	}

	onChangeTab = (e, value) => {
		this.setState({tab: value}, this.onDocumentFetchApprovalOverviewList);
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, DEBOUNCE(this.onDocumentFetchApprovalOverviewList, 300))
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}
	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onDocumentFetchApprovalOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onDocumentFetchApprovalOverviewList);
	}

	onRowSelectionModelChange = (selectedIds) => {
		this.setState({selectedIds});
	}

	onDocumentFetchApprovalOverviewList = () => {
		this.props.onDocumentFetchApprovalOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null,
			...(this.state.tab === 'WAIT_FOR_APPROVAL' && {
				stateFilter: 'WAITING'
			})
		})
	}

	onDownloadMenuOpen = (e) => {
		this.setState({downloadMenuAnchorEl: e.currentTarget});
	}

	onDownloadMenuClose = (e) => {
		this.setState({downloadMenuAnchorEl: null});
	}

	onDocumentNavigateToApprove = (ids) => {
		this.props.router.navigate('/approval/ids=' + ids.join('-'))
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			documentBusy: state.document.busy,
			documentServerError: state.document.serverError,
			documentApprovalOverviewList: state.document.approvalOverviewList,
			documentApprovalOverviewCount: state.document.approvalOverviewCount,
		}
	},
	dispatch => {
		return {
			onDocumentFetchApprovalOverviewList: (request) => {
				dispatch({
					type: 'DOCUMENT_FETCH_APPROVAL_OVERVIEW_LIST',
					request
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
			onDocumentDownloadArtifacts: (ids, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ARTIFACTS',
					ids,
					types
				});
			},
		}
	}
)(DocumentApprovalOverviewComponent)));
