import React, {Component} from "react";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ServerErrorComponent from "../common/ServerErrorComponent";

class SigningItsMeBusyDialog extends Component {

	constructor(props) {
		super(props);

		const statusPolling = !!this.props.signRequestIds && !!this.props.itsmeStatus;

		this.state = { statusPolling };

		if (statusPolling) {
			this.props.onSigningItsmeStatusLoop(
				this.props.signRequestIds,
				this.props.itsmeStatus.sessionId);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.statusPolling && this.props.open && !prevProps.open) {
			this.setState({
				statusPolling: true
			}, () => this.props.onSigningItsmeStatusLoop(
				this.props.signRequestIds,
				this.props.itsmeStatus.sessionId)
			);
		}
		if (this.state.statusPolling &&
			'FINISHED' === this.props.signingItsmeState &&
			!this.props.signingServerError) {
			this.props.onClose();
		}
	}

	render() {
		const serverError = this.props.signingServerError;
		const serverState = this.props.signingItsmeState;
		const serverProgress = this.props.signingItsmeProgress;

		return <Dialog open={this.props.open} onClose={this.onClose} fullWidth maxWidth="sm">
			<DialogTitle>{this.props.t('signing.itsmeSessionBusyTitle')}</DialogTitle>
			<DialogContent>
				{serverState === 'WAIT_FOR_ITSME_USER_IDENTIFICATION' && !serverError && <Typography variant="body2">{this.props.t('signing.itsmeSessionBusyStarted')}</Typography>}
				{serverState === 'WAIT_FOR_ITSME_SIGNING' && !serverError && <Typography variant="body2">{this.props.t('signing.itsmeSessionBusySigning')}</Typography>}
				{!serverError && <Box sx={{ display: 'flex', mt: 1}}>
					{serverProgress === 0 && <LinearProgress variant="indeterminate" sx={{ flexGrow: 1}} />}
					{serverProgress !== 0 && <LinearProgress variant="determinate" value={serverProgress} sx={{ flexGrow: 1}} />}
				</Box>}
				{!!serverError && serverError !== 'SIGNER_DATA_MISMATCH_ERROR' && <ServerErrorComponent serverError={serverError}/>}
				{!!serverError && serverError === 'SIGNER_DATA_MISMATCH_ERROR' && <Box sx={{ mt: 1, mb: 1 }}>
					<Alert severity="error">
						{this.props.t('serverError.' + serverError) + ': ' + this.props.t('signing.signerDataMismatchUnexpected', {
							attribute: this.props.t('signing.signerDataMismatch' + this.props.signingserverErrorExtra?.attribute)
						})}
						<br/>
						{this.props.t('signing.signerDataMismatchExpected') + ': ' + this.props.signingserverErrorExtra.expected}
						<br/>
						{this.props.t('signing.signerDataMismatchGiven') + ': ' + this.props.signingserverErrorExtra.given}
					</Alert>
				</Box>}

			</DialogContent>
			<DialogActions>
				{serverError &&
					<Button onClick={this.onClose} id="btn-signing-itsme-busy-cancel">{this.props.t('cancel')}</Button>}
			</DialogActions>
		</Dialog>
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			signingServerError: state.signing.serverError,
			signingserverErrorExtra: state.signing.serverErrorExtra,
			signingItsmeState: state.signing.itsmeState,
			signingItsmeProgress: state.signing.itsmeProgress,
		}
	},
	dispatch => {
		return {
			onSigningItsmeStatusLoop: (signRequestIds, itsmeSessionId) => {
				dispatch({
					type: 'SIGNING_ITSME_STATUS_LOOP',
					signRequestIds,
					itsmeSessionId
				});
			},
		}
	}
)(SigningItsMeBusyDialog));
