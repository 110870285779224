import React, {Component, Fragment, useState} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Link,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SwapDocumentIcon from "./SwapDocumentIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EditorUploadPdfDialog from "./EditorUploadPdfDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {COLLECTION_CHILD_ERROR} from "./EditorUtils";
import {withRouter} from "../common/RouterHelper";

const DocumentCollectionChildNameEditDialog = ({documentName, onConfirm, onClose}) => {

	const {t} = useTranslation();
	const [name, setName] = useState(documentName);

	const onKeyUp = (e) => {
		if (e.key === 'Enter') {
			onConfirm(name);
		}
	}

	return <Dialog
		open
		onClose={onClose}
		maxWidth="sm"
		fullWidth
		onKeyUp={onKeyUp}
	>
		<DialogTitle>{t('editor.documentNameEdit')}</DialogTitle>
		<Box sx={{
			display: 'flex', flexDirection: 'column', margin: 2, gap: 2
		}}>
			<TextField
				variant="outlined"
				label={t("document.name")}
				required
				value={name || ''}
				onChange={(e) => setName(e.target.value)}
				autoComplete="off"
				autoFocus
			/>
		</Box>
		<DialogContent>
			<DialogActions>
				<Button onClick={onClose}
						id="btn-confirmation-cancel"
				>
					{t('cancel')}
				</Button>
				<Button variant="contained"
						onClick={() => onConfirm(name)}
						disabled={!name || name.trim().length === 0}
						id="btn-confirmation-confirm"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}


class EditorGeneralComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			childNameDialogOpen: false,
			changePdfDialogOpen: false,
			selectedChildDocumentId: null,
			addCollectionChildDialogOpen: false,
		}
	}

	render() {
		const rootDocument = this.props.editorEditInfo.document;
		const reasonActive = this.props.editorEditInfo.reasonActive; // is the option to provide reasons globally enabled?
		const expirationSettingsEnabled = this.props.editorEditInfo.allowChangingDaysForSignatureExpiration;
		const document = this.props.document;
		const forwardingSettingEnabled = this.props.document.allowChangingForwarding;
		const readOnly = document.readOnly;
		const hasItsmeSignatures = (document?.enabledSignatureTypes || []).indexOf('ITSME') >= 0;
		const isTemplate = document.state === 'TEMPLATE';
		const isVirtualCollectionChild = rootDocument.id === 0 && this.props.child;
		const folderId = isVirtualCollectionChild ? document.folderId : rootDocument.folderId;

		return <Box>

			{!isTemplate && !document.documentCollection && this.renderDescriptionComponent(document)}

			{(!this.props.child || isVirtualCollectionChild) && <>
				<Typography sx={{fontWeight: 700, mt: 1}}>{this.props.t('editor.generalFolder')}</Typography>
				<Box sx={{mt: 1}}>
					<FormControl size="small" disabled={readOnly}>
						<Select
							value={folderId || ''}
							onChange={this.onChangeSelectedFolderId}
						>
							{(this.props.editorEditInfo.folders || [])
								.filter(folder => readOnly || folder.userCanCreateAndEditDocuments)
								.map(folder =>
									<MenuItem key={folder.id} value={folder.id}>
										{folder.name}
									</MenuItem>
								)
							}
						</Select>
					</FormControl>
				</Box>
			</>}

			{!document.documentCollection && !document.attachment && <>
				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalPdf')}</Typography>
				{document.hasPdf && <>
					<Typography
						sx={{mt: 1}}>{this.props.t('editor.generalPdfADetected') + ': ' + (!!document.conformanceLevel ? document.conformanceLevel : this.props.t('editor.generalPdfADetectedNone'))}</Typography>
					{hasItsmeSignatures && !document.conformanceLevel &&
						<Alert severity="info">{this.props.t('editor.generalPdfADetectedNoneItsme')}</Alert>}
					{document.pdfRecreatedShown && <>
						<Alert severity="info">{this.props.t('editor.generalPdfRecreated')}</Alert>
					</>}
				</>
				}

				{(isTemplate && !document.hasPdf) && <>
					<Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
						<Typography sx={{
							mt: 2,
							mb: 1}}>{this.props.t('editor.generalTemplatePages')}</Typography>
						<TextField
							value={document.pageCount || 1}
							onChange={this.onChangeTemplateDocumentPageCount}
							disabled={readOnly}
							type="number"
							size="small"
							sx={{mt: 1, width: '90px'}}
						/>
					</Box>
				</>}
			</>}

			{isTemplate && <>
				<Divider sx={{mt: 2}}><Typography sx={{fontWeight: 700}}>
					{this.props.t('editor.generalTemplateOptions')}
					</Typography>
				</Divider>

				{document.hasPdf && this.renderDescriptionComponent(document)}
			</>
			}

			{!document.documentCollection && !document.attachment && (isVirtualCollectionChild || !this.props.child) && <>
				<Typography
					sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalConfidentiality')}</Typography>
				<FormControlLabel
					control={<Checkbox checked={document?.confidential || false} sx={{p: 0}}
									   onChange={this.onChangeDocumentConfidential}/>}
					label={this.props.t('editor.generalConfidentialitySet')}
					disabled={readOnly}
					sx={{m: 0, mt: 1}}
				/>
				{!readOnly && !!document?.confidential && !document.createdByCurrentUser &&
					<Alert severity="warning" sx={{mt: 1}}>
						{this.props.t('editor.generalConfidentialWarningWhenNotCreator')}
					</Alert>}
			</>}

			{!isTemplate && !document.attachment && <>
				{reasonActive && <>
					<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalMotivation')}</Typography>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={document.overrideReasonSettings || false} sx={{p: 0}}
											   onChange={this.onChangeOverrideReasonSettings}/>}
							label={this.props.t('editor.generalOverrideLegalNoticeSettings')}
							disabled={readOnly}
							sx={{m: 0}}/>
					</Box>

					{document.overrideReasonSettings && <Box sx={{ml: 2, mt: 0.5}}>
						<Box>
							<FormControlLabel
								control={<Checkbox checked={document.reasonAvailable || false} sx={{p: 0}}
												   onChange={this.onChangeReasonAvailable}/>}
								label={this.props.t('editor.generalReasonAvailable')}
								disabled={readOnly || !document.overrideReasonSettings}
								sx={{m: 0}}/>
						</Box>

						<Box sx={{ml: 2, mt: 0.5}}>
							<FormControlLabel
								control={<Checkbox checked={document.reasonLegalNoticeMandatory || false} sx={{p: 0}}
												   onChange={this.onChangeReasonLegalNoticeMandatory}
												   disabled={readOnly || !document.overrideReasonSettings || !document.reasonAvailable}/>}
								label={this.props.t('editor.generalReasonLegalNoticeMandatory')}
								sx={{
									verticalAlign: 'start',
									alignItems: 'start',
									'& .MuiCheckbox-root': {
										alignItems: 'start'
									},
									m: 0
								}}
							/>
							<TextField
								variant="outlined"
								label={this.props.t('editor.generalReasonLegalNoticeText')}
								required
								value={document.reasonLegalNoticeText || ''}
								onChange={this.onChangeReasonLegalNoticeText}
								autoComplete="off"
								fullWidth
								sx={{mt: 1}}
								disabled={readOnly || !document.overrideReasonSettings || !document.reasonLegalNoticeMandatory || !document.reasonAvailable}
							/>
						</Box>
					</Box>}
				</>}

				{!document.attachment && (isVirtualCollectionChild || !this.props.child) && expirationSettingsEnabled && <>
					<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalExpiration')}</Typography>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={document.overrideExpirationSettings || false} sx={{p: 0}}
											   onChange={this.onChangeOverrideExpirationSettings}/>}
							label={this.props.t('editor.generalExpirationOverride')}
							disabled={readOnly}
							sx={{m: 0}}/>
					</Box>
					{document.overrideExpirationSettings && <Box sx={{ml: 2, mt: 0.5}}>
						<Box>
							<FormControlLabel
								control={<Checkbox checked={document.enableExpirationSettings || false}
												   sx={{p: 0, pd: 1}}
												   onChange={this.onChangeEnableExpirationSettings}/>}
								label={this.props.t('editor.generalExpirationEnable')}
								disabled={readOnly}
								sx={{m: 0}}/>
						</Box>
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<TextField
								variant="outlined"
								size="small"
								value={rootDocument.daysForSignatureExpiration}
								onChange={this.onChangeDaysForExpiration}
								sx={{mr: 1, width: 100}}
								disabled={readOnly || !document.enableExpirationSettings}
							/>
							<span>{this.props.t('editor.generalExpirationDays')}</span>
						</Box>
					</Box>}
				</>}

				{!document.attachment && (isVirtualCollectionChild || !this.props.child) && forwardingSettingEnabled && <>
					<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalForwarding')}</Typography>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={document.allowForwarding || false} sx={{p: 0}}
											   onChange={this.onChangeAllowForwarding}/>}
							label={this.props.t('editor.generalAllowForwarding')}
							disabled={readOnly}
							sx={{m: 0}}/>
					</Box>
				</>}
			</>
			}

			{document.documentCollection && this.renderChildDocumentComponent(document)}
		</Box>
	}

	renderDescriptionComponent(document) {
		const rootDocument = this.props.editorEditInfo.document;
		const readOnly = document.readOnly;
		const description = (document.documentDescription || '');

		return <>
			{this.props.child && rootDocument.id !== 0 && <>
				<FormControlLabel
					control={<Checkbox checked={document?.attachment || false}
									   disabled={readOnly || document?.state === 'ATTACHMENT_GENERIC'}
									   onChange={this.onChangeDocumentAttachment}/>}
					label={this.props.t('editor.generalAttachment')}
					sx={{mt: 1}}
				/>
				{document?.attachment && ((document?.signers || []).some(signer => !signer.signed)) && <Alert severity="warning">
					{this.props.t('editor.generalAttachmentHasIncompleteSignersWarning')}
				</Alert>}
			</>}

			<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.generalDescription')}</Typography>
			<TextField
				multiline
				rows={3}
				value={description}
				onChange={this.onChangeDocumentDescription}
				fullWidth
				disabled={readOnly}
				size="small"
				sx={{mt: 1}}
			/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
				<Typography variant="body2">{description.length + "/500"}</Typography>
			</Box>
			{document.documentDescriptionMandatory && description.length === 0 &&
				<Alert severity="warning">{this.props.t('editor.generalDescriptionMandatory')}</Alert>}
		</>;
	}

	renderChildDocumentComponent(parentDocument) {
		const readOnly = parentDocument.readOnly;
		const allChildrenPreparing = parentDocument.children.every(child => child.state === 'PREPARING');

		return <>
			<Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('editor.documentCollectionOverview')}</Typography>
				<IconButton
					title={this.props.t('editor.documentChildAdd')}
					disabled={readOnly}
					onClick={this.onOpenAddCollectionChildDialog}>
					<AddIcon/>
				</IconButton>
			</Box>
			{parentDocument.children
				.sort((d1, d2) => d1.childSeq - d2.childSeq)
				.map((childDocument, idx) => (<Box key={childDocument.id} sx={{display: 'flex', alignItems: 'center', maxWidth: 'inherit'}}>
					<Box sx={{display: 'flex', flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 0}}>
						<Link
							component="button"
							underline="hover"
							onClick={() => this.onSelectChildDocument(childDocument)}>
							<Typography variant="body2"
										title={childDocument.name}
										color={COLLECTION_CHILD_ERROR(childDocument) ? 'red' : undefined}
										sx={{ml: 0.5, wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'nowrap', overflow: 'hidden'}}
							>
								{childDocument.name}
							</Typography>
						</Link>
					</Box>

					{!allChildrenPreparing && <Box>
						<Typography variant="body2" sx={{mr: 1, ml: 1, color: 'grey'}}>{this.props.t('document.status_' + childDocument.state)}</Typography>
					</Box>}

					<Box sx={{
						display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end', flexShrink: 0
					}}>
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('editor.orderUp')}
							onClick={() => this.onChildOrderUp(childDocument.id, childDocument.childSeq)}
							disabled={childDocument.childSeq <= 1 || readOnly}
						>
							<ArrowUpwardIcon/>
						</IconButton>
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('editor.orderDown')}
							onClick={() => this.onChildOrderDown(childDocument.id, childDocument.childSeq, parentDocument.children.length)}
							disabled={childDocument.childSeq >= parentDocument.children.length || readOnly}
						>
							<ArrowDownwardIcon/>
						</IconButton>

						<Divider orientation="vertical" flexItem/>

						<IconButton
							color="primary"
							title={this.props.t('editor.documentNameEdit')}
							disabled={readOnly}
							id={'btn-editor-collection-child-name-edit-' + idx}
							onClick={() => this.onOpenChildNameDialog(childDocument.id)}>
							<EditIcon/>
						</IconButton>
						<IconButton
							 color="primary"
							 title={this.props.t('editor.templateApply')}
							 disabled={readOnly || childDocument.attachment}
							 id={'btn-editor-collection-child-template-apply-' + idx}
							 onClick={() => this.props.onSelectApplyTemplate(childDocument.id)}>
							<FontAwesomeIcon icon={faFileArrowDown} size="sm"/>
						</IconButton>
						<IconButton
							color="primary"
							title={this.props.t('editor.documentChange')}
							disabled={readOnly}
							id={'btn-editor-collection-child-change-document-' + idx}
							onClick={() => this.onOpenChangePdfDialog(childDocument.id)}>
							<SwapDocumentIcon/>
						</IconButton>
						<IconButton
							color="primary"
							title={this.props.t('editor.documentRemove')}
							disabled={parentDocument.children.length === 1 || readOnly}
							id={'btn-editor-collection-child-remove-document-' + idx}
							onClick={() => this.props.onRemoveChildDocument(childDocument.id)}
						>
							<DeleteIcon/>
						</IconButton>
					</Box>
				</Box>))}

			<EditorUploadPdfDialog
				title={this.props.t('editor.changeDocumentTitle')}
				document={parentDocument.children.find((child) => child.id === this.state.selectedChildDocumentId)}
				open={this.state.changePdfDialogOpen}
				onClose={this.onCloseChangePdfDialog}
			/>

			{this.state.childNameDialogOpen &&
				<DocumentCollectionChildNameEditDialog
					documentName={parentDocument.children.find((child) => child.id === this.state.selectedChildDocumentId)?.name}
					onConfirm={this.onConfirmNameEditing}
					onClose={this.onCloseChildNameDialog}
				/>
			}

			<EditorUploadPdfDialog
				title={this.props.t('editor.documentChildAdd')}
				collectionChildAdd
				open={this.state.addCollectionChildDialogOpen}
				onUploadSuccess={this.onCollectionChildAdded}
				onClose={this.onCloseAddCollectionChildDialog}
			/>
		</>
	}

	onChangeTemplateDocumentPageCount = (e) => {
		if (!e.target.value) return;

		const pageCount = parseInt(e.target.value);
		if (pageCount < 1 || pageCount > 9999) return;

		this.props.onChangeDocumentPageCount(pageCount);
	}

	onChangeDocumentAttachment = (e, checked) => {
		this.props.onChangeDocumentAttachment(checked);
	}

	onChangeDocumentDescription = (e) => {
		this.props.onChangeDocumentDescription(e.target.value);
	}

	onChangeSelectedFolderId = (e) => {
		this.props.onChangeSelectedFolderId(e.target.value);
	}

	onChangeDocumentConfidential = (e, checked) => {
		this.props.onChangeDocumentConfidential(checked);
	}

	onChangeOverrideReasonSettings = (e, checked) => {
		this.props.onChangeOverrideReasonSettings(checked);
	}

	onChangeReasonAvailable = (e, checked) => {
		this.props.onChangeReasonAvailable(checked);
	}

	onChangeReasonLegalNoticeMandatory = (e, checked) => {
		this.props.onChangeReasonLegalNoticeMandatory(checked);
	}

	onChangeReasonLegalNoticeText = (e) => {
		this.props.onChangeReasonLegalNoticeText(e.target.value);
	}

	onChangeOverrideExpirationSettings = (e, checked) => {
		this.props.onChangeOverrideExpirationSettings(checked);
	}

	onChangeEnableExpirationSettings = (e, checked) => {
		this.props.onChangeEnableExpirationSettings(checked);
	}

	onChangeDaysForExpiration = (e) => {
		const value = parseInt(e.target.value);
		this.props.onChangeDaysForExpiration(!value ? 0 : value);
	}

	onChangeAllowForwarding = (e, checked) => {
		this.props.onChangeAllowForwarding(checked);
	}

	onChildOrderUp = (id, seq) => {
		if (seq <= 1) {
			return;
		}

		this.props.onChangeCollectionChildSeqDecrement(id, seq);
	}

	onChildOrderDown = (id, seq, max) => {
		if (seq >= max) {
			return;
		}

		this.props.onChangeCollectionChildSeqIncrement(id, seq);
	}

	onOpenChangePdfDialog = (childId) => {
		this.setState({changePdfDialogOpen: true, selectedChildDocumentId: childId});
	}

	onCloseChangePdfDialog = () => {
		this.setState({changePdfDialogOpen: false, selectedChildDocumentId: null});
	}

	onOpenAddCollectionChildDialog = () => {
		this.setState({addCollectionChildDialogOpen: true});
	}

	onCloseAddCollectionChildDialog = () => {
		this.setState({addCollectionChildDialogOpen: false});
	}

	onCollectionChildAdded = (child) => {
		this.setState({addCollectionChildDialogOpen: false}, () => this.props.onAddCollectionDocumentChild(child));
	}

	onOpenChildNameDialog = (childId) => {
		this.setState({childNameDialogOpen: true, selectedChildDocumentId: childId});
	}

	onCloseChildNameDialog = () => {
		this.setState({childNameDialogOpen: false, selectedChildDocumentId: null});
	}

	onConfirmNameEditing = (name) => {
		const documentId = this.state.selectedChildDocumentId;
		this.setState({selectedChildDocumentId: null, childNameDialogOpen: false}, () => this.props.onChangeDocumentName(documentId, name));
	}

	onSelectChildDocument = (childDocument) => {
		this.props.onChangeSelectedChildDocumentId(childDocument.id);
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {}
	}
)(EditorGeneralComponent)));
