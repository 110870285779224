const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// overview info
	overviewInfo: null,

	// overviews
	generalOverviewList: [],
	generalOverviewCount: 0,
	approvalOverviewList: [],
	approvalOverviewCount: 0,
	signingOverviewList: [],
	signingOverviewCount: 0,

	// reminders
	reminderSentResult: null,

	// indicate that the action on the document was successful
	documentActionSuccessfully: false,

	// messaging
	messageRelevantUsers: [],
	messageCreated: true,
	messagePartial: [],
	messageCount: 0,
};

const document = (state = defaultState, action) => {
	switch (action.type) {
		case 'DOCUMENT_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'DOCUMENT_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'DOCUMENT_FETCH_OVERVIEW_INFO':
		case 'DOCUMENT_FETCH_GENERAL_OVERVIEW_LIST':
		case 'DOCUMENT_FETCH_APPROVAL_OVERVIEW_LIST':
		case 'DOCUMENT_FETCH_SIGNING_OVERVIEW_LIST':
		case 'DOCUMENT_EXPORT_GENERAL_OVERVIEW':
		case 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS':
		case 'DOCUMENT_DOWNLOAD_ARTIFACTS':
		case 'DOCUMENT_DOWNLOAD_ALL_DOCUMENT_ARTIFACTS':
		case 'DOCUMENT_MAKE_COLLECTION':
		case 'DOCUMENT_SEND_REMINDERS':
		case 'DOCUMENT_MESSAGE_FETCH_PARTIAL':
		case 'DOCUMENT_MESSAGE_FETCH_COUNT':
			return {
				...state,
				busy: true,
				serverError: null,
				reminderSentResult: null,
			}

		case 'DOCUMENT_FETCH_OVERVIEW_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				overviewInfo: action.data
			}

		case 'DOCUMENT_FETCH_GENERAL_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				generalOverviewList: action.list,
				generalOverviewCount: action.count
			}

		case 'DOCUMENT_FETCH_APPROVAL_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				approvalOverviewList: action.list,
				approvalOverviewCount: action.count
			}

		case 'DOCUMENT_FETCH_SIGNING_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				signingOverviewList: action.list,
				signingOverviewCount: action.count
			}

		case 'DOCUMENT_DELETE_DOCUMENTS':
		case 'DOCUMENT_DELETE_ALL_DOCUMENTS':
			return {
				...state,
				busy: true,
				serverError: null,
				documentActionSuccessfully: false,
				reminderSentResult: null,
			}

		case 'DOCUMENT_DELETE_DOCUMENTS_SUCCESS':
			return {
				...state,
				busy: true,
				serverError: null,
				documentActionSuccessfully: true,
				reminderSentResult: null,
			}

		case 'DOCUMENT_CREATE_FROM_TEMPLATE':
			return {
				...state,
				busy: true,
				serverError: null,
				reminderSentResult: null,
			}

		case 'DOCUMENT_SEND_REMINDERS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				reminderSentResult: action.data,
			}

		case 'DOCUMENT_MESSAGE_FETCH_RELEVANT_USERS':
			return {
				...state,
				busy: true,
				serverError: null,
				messageRelevantUsers: [],
			}

		case 'DOCUMENT_MESSAGE_FETCH_RELEVANT_USERS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				messageRelevantUsers: action.data
			}

		case 'DOCUMENT_MESSAGE_FETCH_PARTIAL_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				messagePartial: action.data
			}

		case 'DOCUMENT_MESSAGE_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				messageCreated: false
			}

		case 'DOCUMENT_MESSAGE_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				messageCreated: true
			}

		case 'DOCUMENT_MESSAGE_FETCH_COUNT_SUCCESS':
			return {
				...state,
				busy: false,
				messageCount: action.data
			}

		// clear current reminder result when switching from document overview to editor
		case 'EDITOR_FETCH_EDIT_INFO':
			return {
				...state,
				reminderSentResult: null,
			}

		default:
			return state;
	}
}

export default document;
