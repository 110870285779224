import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Link, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";

export default (props) => {

	const {t} = useTranslation();

	const busy = useSelector(state => state.session.busy);
	const qrCode = useSelector(state => state.session.authenticatorQRCode);
	const qrCodeConfirmed = useSelector(state => state.session.authenticatorConfirmed);
	const serverError = useSelector(state => {
		const serverError = state.session.serverError;
		return ['AUTHENTICATION_FACTOR_METHOD_INVALID', 'AUTHENTICATION_FACTOR_CHALLENGE_INVALID', 'AUTHENTICATION_FACTOR_CHALLENGE_EXPIRED'].indexOf(serverError) >= 0 ? serverError + '_AUTHENTICATOR' : serverError;
	});

	const dispatch = useDispatch();

	const onSessionAuthenticatorSet = () =>
		dispatch({type: 'SESSION_AUTHENTICATOR_SET'});
	const onSessionAuthenticatorConfirm =
		(code) => dispatch({type: 'SESSION_AUTHENTICATOR_CONFIRM', code});
	const onSessionAuthenticatorInit =
		() => dispatch({type: 'SESSION_AUTHENTICATOR_INIT'});

	const [activeStep, setActiveStep] = useState(props.update ? 1 : 0);
	const [code, setCode] = useState('');

	useEffect(() => {
		if (qrCodeConfirmed) {
			props.onFinished();
		}
	}, [qrCodeConfirmed]);

	const onCodeChange = (e) => {
		setCode(e.target.value);
	}

	const onCancel = () => {
		onSessionAuthenticatorInit();
		props.onCancel();
	}

	const onPrevious = () => {
		switch (activeStep) {
			case 1:
				if (!props.update) {
					setActiveStep(0);
					break;
				}
			case 2:
				onSessionAuthenticatorSet();
				setActiveStep(1);
				break;
		}
	}

	const onNext = () => {
		switch (activeStep) {
			case 0:
				onSessionAuthenticatorSet();
				setActiveStep(1);
				break;
			case 1:
				setActiveStep(2);
				break;
			case 2:
				onSessionAuthenticatorConfirm(code);
				break;
		}
	}

	const onKeyPress = useCallback((e) => {
		if ('Enter' === e.key) {
			onNext();
		}
	}, [onNext]);

	const possibleSteps = [
		t('user.authenticatorStepDownload'),
		t('user.authenticatorStepScanQrCode'),
		t('user.authenticatorChallenge')
	];

	// do the request for QR code
	useEffect(() => {
		if (props.open) {
			setActiveStep(props.update ? 1 : 0);
			if (props.update) {
				onSessionAuthenticatorSet();
			}
		}
	}, [props.open]);

	return <>
		<Dialog
			open={props.open}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{t('user.authenticatorTitle')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={activeStep} sx={{mt: 1, mb: 3}} alternativeLabel>
					{possibleSteps.map((label, index) => (
						<Step key={index}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<Box>
					{0 === activeStep && <>
						<Box>
							<Typography>{t('user.authenticatorGuide')}</Typography>
							<ul>
								<li><Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Google Authenticator (Google Play)</Link></li>
								<li><Link href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank">Microsoft Authenticator (Google Play)</Link></li>
								<li><Link href="https://apps.apple.com/nl/app/google-authenticator/id388497605" target="_blank">Google Authenticator (App Store)</Link></li>
								<li><Link href="https://apps.apple.com/nl/app/microsoft-authenticator/id983156458" target="_blank">Microsoft Authenticator (App Store)</Link></li>
							</ul>
						</Box>
					</>}

					{1 === activeStep && <>
						<Box>
							{t('user.authenticatorScanQrCode')}
						</Box>
						<Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
							{!!qrCode ?
								<img src={`data:image/png;base64,${qrCode}`} alt="QR code"/> :
								<CircularProgress/>}
						</Box>
					</>}

					{2 === activeStep && <>
						<Box sx={{mb: 1}}>{t('user.authenticatorEnterChallenge')}</Box>
						<TextField
							required
							id="challenge"
							label={t('user.authenticatorChallenge')}
							fullWidth
							variant="outlined"
							value={code}
							onChange={onCodeChange}
							autoComplete="off"
							onKeyPress={onKeyPress}
						/>
					</>}

				</Box>
				{serverError && <Box sx={{mt: 2, mb: 2}}>
					<Alert severity="error">{t('serverError.' + serverError)}</Alert>
				</Box>}
				<Box sx={{display: 'flex', gap: 1, justifyContent: 'flex-end', alignItems: 'center', mt: 2}}>
					<Button variant="outlined"
							onClick={onCancel}
					>
						{t('cancel')}
					</Button>
					<Button variant="contained"
							onClick={onPrevious}
							disabled={busy || activeStep === (props.update ? 1 : 0)}
					>
						{t('previous')}
					</Button>
					<Button variant="contained"
							onClick={onNext}
							disabled={busy || (activeStep === 2 && !code)}
					>
						{t(activeStep === 2 ? 'save' : 'next')}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>

	</>;

}
