import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DelegateListAddCreateDialog from "../delegate/DelegateListAddCreateDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";

class FolderDelegatesDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filterValue: '',
			filterValueUC: '',

			addCreateDialogOpen: false,
			deleteDialogOpen: false,
			selectedListId: null,
			create: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onFolderFetchRelevantUsers();
			this.props.onFolderFetchDelegateLists(this.props.folderId);
		}
		if (this.props.folderDelegateActionSuccessFully && !prevProps.folderDelegateActionSuccessFully) {
			if (this.state.create && !this.state.selectedListId && !!this.props.folderDelegateList && !prevProps.folderDelegateList) {
				this.setState({selectedListId: this.props.folderDelegateList.id}, () => this.props.onFolderFetchDelegateLists(this.props.folderId));
			} else {
				this.props.onFolderFetchDelegateLists(this.props.folderId);
			}
		} else if (!this.state.create && !!this.state.selectedListId && !!this.props.folderDelegateList && !prevProps.folderDelegateList) {
			this.setState({
				addCreateDialogOpen: true,
			})
		}
	}

	render() {
		const folderRelevantUsers = this.props.folderRelevantUsers;
		const folderDelegateLists = this.props.folderDelegateLists;
		const haveAllInfo = !!folderRelevantUsers && !!folderDelegateLists;

		const dataGridColumns = [
			{
				field: 'type',
				headerName: this.props.t('delegates.type'),
				editable: false,
				width: 200,
				valueGetter: (value) => this.props.t('delegates.type_' + value)
			},
			{
				field: 'forPerson',
				headerName: this.props.t('delegates.for'),
				editable: false,
				flex: 1,
				valueGetter: (value) => !!value ? (value?.firstName + ' ' + value?.lastName) : this.props.t('delegates.forEveryone')
			},
			{
				field: 'delegates',
				headerName: this.props.t('delegates.count'),
				editable: false,
				width: 200,
				valueGetter: (value) => value?.length || 0
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('delegates.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listEdit')}
						onClick={() => this.onOpenDialogForEditing(cellValues.row.id)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.listDelete')}
						onClick={() => this.onOpenDeleteDialog(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="lg">
				<DialogTitle>{this.props.t('folder.delegates')}</DialogTitle>
				{!haveAllInfo && <DialogContent><LoadingComponent/></DialogContent>}
				{haveAllInfo && <DialogContent>
					<ServerErrorComponent serverError={this.props.folderServerError} />

					<Box sx={{mt: 2, ml: 2}}>
						<Typography>{this.props.t('delegates.description')}</Typography>
						<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
							<Button variant="contained"
									onClick={this.onOpenDialogForAdding}
									startIcon={<AddIcon/>}
									sx={{mr: 1}}
									id="btn-folder-delegates-create"
							>
								{this.props.t('delegates.listCreate')}
							</Button>
						</Box>
						<DataGrid
							autoHeight
							disableColumnSelector
							disableColumnFilter
							disableRowSelectionOnClick
							pagination

							initialState={{
								pagination: {
									paginationModel: { pageSize: 10, page: 0 },
								},
							}}

							columns={dataGridColumns}
							slots={{toolbar: FlexibleToolbar}}
							slotProps={{
								toolbar: {
									filterId: 'input-folder-delegates-search-text',
									filterValue: this.state.filterValue,
									onChangeFilterValue: this.onFilterValueChange,
								}
							}}

							getRowId={(row) => row.type + (row?.forPerson?.id || '')}
							rows={folderDelegateLists.filter(list =>
								!this.state.filterValue ||
								(list?.forPerson?.firstName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
								(list?.forPerson?.lastName || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
								(list?.forPerson?.email || '').toUpperCase().indexOf(this.state.filterValueUC) >= 0
							)}
							pageSizeOptions={[10]}
							density="compact"
						/>
					</Box>
				</DialogContent>}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-folder-delegates-cancel">{this.props.t('back')}</Button>
				</DialogActions>
			</Dialog>

			<DelegateListAddCreateDialog
				delegateList={this.props.folderDelegateList}
				relevantUsers={folderRelevantUsers}
				open={this.state.addCreateDialogOpen}
				onClose={this.onCloseAddCreateDialog}
				onSave={this.onSaveDelegateList}
				onRefresh={this.onRefreshDelegateList}
				onDownloadDelegateFile={(listId, delegateId) => this.props.onFolderDownloadDelegateFile(this.props.folderId, listId, delegateId)}
				onDeleteDelegateFile={(listId, delegateId) => this.props.onFolderDeleteDelegateFile(this.props.folderId, listId, delegateId)}
				getUploadFileUrl={(delegateListId, delegateId) => `/api/internal/folder/${this.props.folderId}/delegate-list/${delegateListId}/delegate/${delegateId}/upload`}
			/>

			<ConfirmationDialog
				title={this.props.t('delegates.listDelete')}
				confirm={this.props.t('delegates.listDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onConfirmDeletion}
			/>
		</Fragment>;
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onOpenDialogForAdding = () => {
		this.setState({create: true, addCreateDialogOpen: true});
	}

	onOpenDialogForEditing = (id) => {
		this.setState({selectedListId: id, create: false}, () => this.props.onFolderFetchDelegateList(this.props.folderId, id));
	}

	onCloseAddCreateDialog = () => {
		this.setState({addCreateDialogOpen: false}, this.props.onFolderClearDelegateList);
	}

	onSaveDelegateList = (delegateList, closeDelegateDialog) => {
		this.setState({addCreateDialogOpen: !closeDelegateDialog}, () => this.props.onFolderCreateUpdateDelegateList(this.props.folderId, delegateList));
	}

	onRefreshDelegateList = () => {
		this.props.onFolderFetchDelegateList(this.props.folderId, this.state.selectedListId);
	}

	onOpenDeleteDialog = (selectedListId) => {
		this.setState({selectedListId, deleteDialogOpen: true});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false, selectedListId: null});
	}

	onConfirmDeletion = () => {
		const id = this.state.selectedListId
		this.setState({selectedListId: null, deleteDialogOpen: false}, () => this.props.onFolderDeleteDelegateList(this.props.folderId, id));
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			folderBusy: state.folder.busy,
			folderServerError: state.folder.serverError,
			folderRelevantUsers: state.folder.relevantUsers,
			folderDelegateLists: state.folder.delegateLists,
			folderDelegateList: state.folder.delegateList,
			folderDelegateActionSuccessFully: state.folder.delegateActionSuccessFully
		}
	},
	dispatch => {
		return {
			onFolderFetchRelevantUsers: () => {
				dispatch({
					type: 'FOLDER_FETCH_RELEVANT_USERS',
				});
			},
			onFolderFetchDelegateLists: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_DELEGATE_LISTS',
					folderId
				});
			},
			onFolderFetchDelegateList: (folderId, delegateListId) => {
				dispatch({
					type: 'FOLDER_FETCH_DELEGATE_LIST',
					folderId,
					delegateListId
				});
			},
			onFolderCreateUpdateDelegateList: (folderId, delegateList) => {
				dispatch({
					type: 'FOLDER_CREATE_UPDATE_DELEGATE_LIST',
					folderId,
					delegateList
				})
			},
			onFolderDeleteDelegateList: (folderId, delegateListId) => {
				dispatch({
					type: 'FOLDER_DELETE_DELEGATE_LIST',
					folderId,
					delegateListId
				})
			},
			onFolderClearDelegateList: () => {
				dispatch({
					type: 'FOLDER_CLEAR_DELEGATE_LIST'
				})
			},
			onFolderDownloadDelegateFile: (folderId, delegateListId, delegateId) => {
				dispatch({
					type: 'FOLDER_DOWNLOAD_DELEGATE_FILE',
					folderId, delegateListId, delegateId
				});
			},
			onFolderDeleteDelegateFile: (folderId, delegateListId, delegateId) => {
				dispatch({
					type: 'FOLDER_DELETE_DELEGATE_FILE',
					folderId, delegateListId, delegateId
				});
			},
		}
	}
)(FolderDelegatesDialog));
