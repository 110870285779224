import React, {Component, useCallback, useEffect, useMemo, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Paper, TextField} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SendIcon from '@mui/icons-material/Send';
import {withRouter} from "../common/RouterHelper";
import {DEBOUNCE} from "../common/DebounceUtil";
import {format} from "date-fns";
import ServerErrorComponent from "../common/ServerErrorComponent";

const TimelineComponent = ({timeline, visibleMessageId, visibleMessagePrev, onFetchNewItems, relevantUsers}) => {

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		if (visibleMessageId > 0) {
			setTimeout(() => {
				const el = document.getElementById('entry-' + visibleMessageId);
				if (!!el) {
					el.scrollIntoView(visibleMessagePrev ? {block: 'start' } : { behavior: 'smooth', block: 'end' });

					// hack to give focus back to textfield
					document.getElementById('message-input').focus();
				}
			}, 0); // needs rerender
		}
	}, [visibleMessageId]);

	const onLocalScroll = useCallback(DEBOUNCE((e) => {
		const newOffset = e.target.scrollTop;
		if (newOffset !== offset) {
			if (newOffset === 0 && offset > 0) {
				onFetchNewItems();
			}
			setOffset(newOffset);
		}
	}, 100), [offset]);

	const timelineWithTextSegments = useMemo(() => timeline.map(entry => {
		const textSegments = [];
		const text = entry.text;

		let prevIndex = 0;
		let index = text.indexOf('@<');
		while (index >= 0) {
			const endIndex = text.indexOf('>', index);
			if (endIndex > index) {
				if (index > prevIndex) {
					textSegments.push(text.substring(prevIndex, index));
				}
				textSegments.push(text.substring(index, endIndex + 1));
				prevIndex = endIndex + 1;
			} else {
				prevIndex = index + 1;
			}
			index = text.indexOf('@<', prevIndex);
		}

		if (prevIndex < text.length) {
			textSegments.push(text.substring(prevIndex));
		}

		return {
			...entry,
			textSegments
		}

	}), [timeline]);

	return <Paper
		variant="outlined"
		sx={{flexBasis: '30vh', overflowY: 'scroll'}}
		onScroll={onLocalScroll}
	>
		{timelineWithTextSegments.map(entry => <Box id={'entry-' + entry.id} key={'entry-' + entry.id} sx={{display: 'flex', justifyContent: (entry.creator ? 'flex-end' : 'flex-begin')}}>
			<Box sx={{
				p: 1,
				m: 1,
				borderRadius: 1,
				color: entry.creator ? 'primary.contrastText' : 'secondary.contrastText',
				backgroundColor: (entry.creator ? 'primary.main' : 'secondary.main'),
				fontSize: 'small',
				minWidth: '100px',
				maxWidth: '50%',
			}}>
				<Box sx={{display: 'flex', alignItems: 'center', gap: '2px', flexWrap: 'wrap'}}>
					{entry.textSegments.map((segment, index) => {
						let user;

						if (segment.startsWith('@<')) {
							const index = segment.indexOf('>', 2);
							if (index > 2) {
								const id = parseInt(segment.substring(2, index));
								if (!isNaN(id)) {
									user = relevantUsers.filter(user => user.id === id)?.[0];
								}
							}
						}

						if (!!user) {
							return <Box key={index} sx={{textDecoration: 'underline'}}>{'@' + user.fullName}</Box>
						} else {
							return <Box key={index}>{segment}</Box>;
						}
					})}
				</Box>
				<Box sx={{fontSize: 'x-small', display: 'flex', justifyContent: 'space-between', gap: 1}}>
					<Box>{entry?.person?.fullName}</Box>
					<Box>{format(entry.createdAt, 'yyyy-LL-dd HH:mm')}</Box>
				</Box>
			</Box>
		</Box>)}
	</Paper>;
}

const FETCH_SIZE = 15;

const MODE_FETCH_PREV = 0;
const MODE_FETCH_LATEST = 1;
const MODE_FETCH_LATEST_INITIAL = 2;

const defaultState = {
	mode: MODE_FETCH_LATEST_INITIAL,
	sentinelMessageId: -1,
	visibleMessageId: -1,
	timeline: [],
	text: '',
	personDropDownMenuAnchorEl: null
};

class DocumentMessageTimelineDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			...defaultState
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState, () => {
				this.props.onDocumentMessageFetchRelevantUsers(this.props.documentId);
				this.onDocumentMessageFetchPartial();
			});
		}
		if (this.props.documentMessagePartial !== prevProps.documentMessagePartial) {
			let timeline = this.state.timeline;
			let visibleMessageId = this.state.visibleMessageId;
			if (MODE_FETCH_PREV === this.state.mode) {
				const firstId = timeline?.[0]?.id;
				const updates = this.props.documentMessagePartial;
				let index = 0;
				while (index < updates.length && updates[index].id < firstId) {
					index++;
				}
				if (updates.length > 0) {
					visibleMessageId = timeline?.[0]?.id;
					timeline = updates.slice(0, index).concat(timeline);
				}
			} else if (MODE_FETCH_LATEST === this.state.mode || MODE_FETCH_LATEST_INITIAL === this.state.mode) {
				const lastId = timeline?.[timeline.length - 1]?.id;
				const updates = this.props.documentMessagePartial;
				let index;
				if (!lastId) {
					index = 0;
				} else {
					index = updates.length - 1;
					while (index >= 0 && updates[index].id > lastId) {
						index--;
					}
					if (updates[index].id <= lastId) {
						index++;
					}
				}
				if (updates.length > 0) {
					if (MODE_FETCH_LATEST === this.state.mode) {
						visibleMessageId = index === 0 ? this.state.visibleMessageId : updates[index].id;
					} else {
						visibleMessageId = updates[updates.length - 1].id;
					}
				}
				timeline = timeline.concat(updates.slice(index, updates.length));
			}
			this.setState({timeline, visibleMessageId});
		}
		if (this.props.documentMessageCreated && !prevProps.documentMessageCreated) {
			this.onDocumentMessageFetchPartialLatestMessages();
		}
	}

	render() {
		return <>
			<Dialog
				open={this.props.open}
				onClose={this.onClose}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>{this.props.t('document.messageTimeline')}</DialogTitle>
				<DialogContent>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>

						<ServerErrorComponent serverError={this.props.documentServerError} />

						<TimelineComponent
							timeline={this.state.timeline}
							visibleMessageId={this.state.visibleMessageId}
							visibleMessagePrev={MODE_FETCH_PREV === this.state.mode}
							onFetchNewItems={this.onDocumentMessageFetchPartialPreviousMessages}
							relevantUsers={this.props.documentMessageRelevantUsers.map(ru => ru.person)}
						/>

						<Box sx={{display: 'flex', gap: 1}}>
							<IconButton
								onClick={this.onOpenPersonDropDownMenu}
								disabled={this.props.documentBusy}
								id="btn-user-add"
								size="small"
								sx={{width: '40px'}}
							>
								<PersonAddIcon />
							</IconButton>

							<TextField
								id="message-input"
								autoComplete="off"
								value={this.state.text}
								size="small"
								onKeyDown={this.onKeyDown}
								onChange={this.onChangeText}
								disabled={this.props.documentBusy}
								sx={{flexGrow: 1}}
							/>

							<Menu
								anchorEl={this.state.personDropDownMenuAnchorEl}
								open={!!this.state.personDropDownMenuAnchorEl}
								onClose={this.onClosePersonDropDownMenu}
							>
								{this.props.documentMessageRelevantUsers.filter(ru => ru.addressable).map(ru => ru.person).map(person =>
									<MenuItem
										key={person.id}
										onClick={(e) => this.onAddPerson(e, person)}
									>
										{person.fullName + ' <' + person.email + '>'}
									</MenuItem>)
								}
							</Menu>

							<IconButton
								onClick={this.onCreate}
								disabled={!this.state.text || this.props.documentBusy}
								id="btn-message-create"
								size="small"
								sx={{width: '40px'}}
							>
								<SendIcon />
							</IconButton>
						</Box>

						<Alert severity="info">{this.props.t('document.messageDescription')}</Alert>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={this.props.onClose}
						id="btn-message-timeline-close"
					>
						{this.props.t('close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}

	onChangeText = (e) => {
		const text = e.target.value;
		const state = {text};
		if (text.charAt(text.length - 1) === '@' &&
				this.state.text.length < text.length) { // make sure when backspacing we don't get the popup again
			state.personDropDownMenuAnchorEl = e.target;
		}
		this.setState(state);
	}

	onAddPerson = (e, person) => {
		e.preventDefault();

		let text = this.state.text;

		// @ char should already be present
		if (text.charAt(text.length - 1) !== '@') {
			text += '@'
		}
		text = text + '<' + person.id + '>';

		this.setState({text, personDropDownMenuAnchorEl: null});
	}

	onOpenPersonDropDownMenu = (e) => {
		this.setState({personDropDownMenuAnchorEl: e.target})
	}

	onClosePersonDropDownMenu = () => {
		this.setState({personDropDownMenuAnchorEl: null})
	}

	onDocumentMessageFetchPartialLatestMessages = () => {
		this.setState({mode: MODE_FETCH_LATEST}, this.onDocumentMessageFetchPartial);
	}

	onDocumentMessageFetchPartialPreviousMessages = () => {
		const {timeline, sentinelMessageId} = this.state;
		const newSentinelMessageId = timeline?.[0]?.id
		if (!!newSentinelMessageId && newSentinelMessageId !== sentinelMessageId) {
			this.setState({sentinelMessageId: newSentinelMessageId, mode: MODE_FETCH_PREV}, this.onDocumentMessageFetchPartial);
		}
	}

	onDocumentMessageFetchPartial = () => {
		const {mode, timeline} = this.state;

		const firstMessageId = timeline?.[0]?.id;
		const request = {
			size: FETCH_SIZE,
			beforeMessageId: (MODE_FETCH_PREV === mode ? timeline?.[0]?.id : undefined)
		}

		this.props.onDocumentMessageFetchPartial(this.props.documentId, request);
	}

	onKeyDown = (e) => {
		if (!this.state.personDropDownMenuAnchorEl && e.key === 'Enter' && !!this.state.text) {
			this.onCreate();
		}
	}

	onCreate = () => {
		const {text} = this.state;
		this.setState({text: ''}, () => this.props.onDocumentMessageCreate(this.props.documentId, {text}));
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			documentBusy: state.document.busy,
			documentServerError: state.document.serverError,
			documentMessageRelevantUsers: state.document.messageRelevantUsers,
			documentMessagePartial: state.document.messagePartial,
			documentMessageCreated: state.document.messageCreated
		}
	},
	dispatch => {
		return {
			onDocumentMessageFetchRelevantUsers: (documentId) => {
				dispatch({
					type: 'DOCUMENT_MESSAGE_FETCH_RELEVANT_USERS',
					documentId
				});
			},
			onDocumentMessageCreate: (documentId, request) => {
				dispatch({
					type: 'DOCUMENT_MESSAGE_CREATE',
					documentId,
					request
				});
			},
			onDocumentMessageFetchPartial: (documentId, request) => {
				dispatch({
					type: 'DOCUMENT_MESSAGE_FETCH_PARTIAL',
					documentId,
					request
				});
			},
		}
	}
)(DocumentMessageTimelineDialog)));
