import React, {Component} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {connect} from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import UserCreationDialog from "../user/UserCreationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {ROWS_PER_PAGE_OPTIONS} from "../common/Constants";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS
} from "../common/DataGridColumUtil";

const defaultState = {
	filterValue: '',
	page: 0,
	pageSize: 25,
	sortModel: [{field: 'firstName', sort: 'asc'}],

	selectedUsers: [],

	extraColumns: [],
	columnWidths: [],

	userCreationDialogOpen: false,
};

const COLUMN_CONFIG_KEY = 'editor-users';

class EditorAddUserDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
				columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['firstName', 'lastName', 'email', 'state']),
			}, () =>{
				this.props.onUserClearError();
				this.onFetchPossibleUsers();
			});
		}
		if (this.props.open &&
			this.props.userActionSuccessfully && !prevProps.userActionSuccessfully &&
			this.props.userCreatedUser && !prevProps.userCreatedUser) {
			this.props.onSelect([this.props.userCreatedUser], true);
		}
	}

	render() {
		const columnConfiguration = this.state.columnConfiguration;
		if (!columnConfiguration) {
			return;
		}

		const folder = this.props.editorSelectedFolder;

		const allPossibleDataGridColumns = [
			{
				field: 'firstName',
				headerName: this.props.t('user.firstName'),
				...COLUMN_WIDTH(columnConfiguration, 'firstName', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'lastName',
				headerName: this.props.t('user.lastName'),
				...COLUMN_WIDTH(columnConfiguration, 'lastName', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'memo',
				headerName: this.props.t('user.memo'),
				...COLUMN_WIDTH(columnConfiguration, 'memo', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true
			},
			{
				field: 'email',
				headerName: this.props.t('user.email'),
				...COLUMN_WIDTH(columnConfiguration, 'email', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'state',
				headerName: this.props.t('user.state'),
				...COLUMN_WIDTH(columnConfiguration, 'state', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				renderCell: (cellValues) => this.props.t('user.state_' + cellValues.row.state, {folder: folder?.name || ''})
			}
		]

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		const canInviteRegisteredUsers = folder?.userInviteSettings?.canInviteRegisteredUsers && this.props.editorEditInfo?.canInviteRegisteredUsers && !this.props.kioskMode;
		const canInviteGuests = folder?.userInviteSettings?.canInviteGuests && this.props.editorEditInfo?.canInviteGuests;
		const canInviteContacts = folder?.userInviteSettings?.canInviteContacts && this.props.editorEditInfo?.canInviteContacts;

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   disableRestoreFocus
					   fullWidth
					   maxWidth="lg">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				{(canInviteRegisteredUsers || canInviteGuests || canInviteContacts) &&
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
						<Button variant="contained"
								onClick={this.onUserCreationDialogOpen}
								startIcon={<AddIcon/>}
								sx={{mr: 1}}
								disabled={this.props.userBusy}
								id="btn-user-add"
						>
							{this.props.t('user.create')}
						</Button>
					</Box>
				}

				<ServerErrorComponent serverError={this.props.userServerError}/>

				<DataGrid
					disableColumnSelector
					disableColumnFilter
					checkboxSelection={!this.props.singleSigner}
					keepNonExistentRowsSelected

					rowSelectionModel={this.state.selectedUsers.map(user => user.id)}
					onRowSelectionModelChange={this.onChangeSelectedValues}
					disableColumnPinning
					columns={filteredColumns}
					onColumnResize={this.onResizeColumn}
					onColumnOrderChange={this.onColumnOrderChange}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-editor-user-search-text',
							filterValue: this.state.filterValue,
							filterFocus: true,
							onChangeFilterValue: this.onFilterValueChange,
							possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
							columns: filteredColumns.map(column => column.field),
							onChangeColumns: this.onChangeExtraColumns,
							onResetColumnsWidth: this.onResetColumnsWidth,
						}
					}}

					loading={this.props.editorBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					rows={this.props.editorPossibleUsersList}
					rowCount={this.props.editorPossibleUsersCount}

					density="compact"
					sx={{height: 500}}
				/>
				<UserCreationDialog
					open={this.state.userCreationDialogOpen}
					onClose={this.onUserCreationDialogClose}
					onCreate={this.onUserCreate}
					allowRegisteredUserCreation={canInviteRegisteredUsers}
					allowGuestCreation={canInviteGuests}
					allowContactCreation={canInviteContacts}
					documentFolderId={this.props.folderId}
					documentFolderName={folder?.name}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						onClick={this.onSelect}
						id="btn-selection-confirm"
						disabled={this.state.selectedUsers.length === 0}
				>
					{this.props.t('select')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.state.selectedUsers.length !== 0) {
			this.onSelect();
		}
	}

	onChangeSelectedValues = (selectedIds) => {
		const cachedSelectedUserIds = this.state.selectedUsers.map(user => user.id);
		const newIds = selectedIds.filter(id => !cachedSelectedUserIds.includes(id));
		const newUsers = this.props.editorPossibleUsersList.filter(user => newIds.includes(user.id));
		// concat current cached users with new ones (if any) and filter to remove any removed ones
		const newSelectedUsers = this.state.selectedUsers.concat(newUsers).filter(user => selectedIds.includes(user.id));
		this.setState({selectedUsers: newSelectedUsers});
	}

	onSelect = () => {
		this.props.onSelect(this.state.selectedUsers, true);
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onFetchPossibleUsers)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFetchPossibleUsers)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFetchPossibleUsers);
	}

	onFetchPossibleUsers = () => {
		this.props.onEditorFetchPossibleUsers(this.props.documentId, {
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null,
			folderId: this.props.folderId,
			kioskMode: this.props.kioskMode
		});
	}

	onUserCreationDialogOpen = () => {
		this.setState({userCreationDialogOpen: true});
	}

	onUserCreationDialogClose = () => {
		this.setState({userCreationDialogOpen: false});
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onUserCreate = (request) => {
		this.setState({userCreationDialogOpen: false},
			() => this.props.onUserCreate(request));
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	(state, ownProps) => {
		return {
			editorBusy: state.editor.busy,
			editorServerError: state.editor.serverError,
			editorPossibleUsersList: state.editor.possibleUsersList,
			editorPossibleUsersCount: state.editor.possibleUsersCount,
			editorEditInfo: state.editor.editInfo,
			editorSelectedFolder: state.editor.editInfo?.folders?.filter(folder => folder.id === ownProps.folderId)?.at(0),

			userBusy: state.user.busy,
			userServerError: state.user.serverError,
			userActionSuccessfully: state.user.userActionSuccessfully,
			userCreatedUser: state.user.createdUser,
		}
	},
	dispatch => {
		return {
			onEditorFetchPossibleUsers: (documentId, request) => {
				dispatch({
					type: 'EDITOR_FETCH_POSSIBLE_USERS',
					documentId,
					request
				});
			},
			onUserClearError: () => {
				dispatch({
					type: 'USER_CLEAR_ERROR'
				})
			},
			onUserCreate: (request) => {
				dispatch({
					type: 'USER_CREATE',
					request
				})
			},
		}
	}
)(EditorAddUserDialog));
