import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, IconButton, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {ROWS_PER_PAGE_OPTIONS} from "../common/Constants";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {formatInTimeZone} from "date-fns-tz";
import ReplayIcon from '@mui/icons-material/Replay';

class ConnectorInventoryGridComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [],
			filterValue: '',
		}
	}

	componentDidMount() {
		this.onFetchConnectorInventoryList();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.instanceId !== this.props.instanceId) {
			this.onFetchConnectorInventoryList();
		}

		if (this.props.connectorItemRestarted && !prevProps.connectorItemRestarted) {
			this.onFetchConnectorInventoryList();
		}
	}

	render() {
		const dataGridColumns = [
			{
				field: 'action',
				headerName: this.props.t('connector.actionType'),
				editable: false,
				flex: 1,
				valueGetter: (value) => this.props.t('connector.actionType_' + value)
			},
			{
				field: 'folderName',
				headerName: this.props.t('connector.folder'),
				editable: false,
				flex: 1,
			},
			{
				field: 'userEmail',
				headerName: this.props.t('connector.user'),
				editable: false,
				flex: 1,
			},
			{
				field: 'documentName',
				headerName: this.props.t('connector.documentName'),
				editable: false,
				flex: 1,
			},
			{
				field: 'externalId',
				headerName: this.props.t('connector.externalId'),
				editable: false,
				flex: 1,
			},
			{
				field: 'state',
				headerName: this.props.t('connector.state'),
				editable: false,
				flex: 1,
				valueGetter: (value) => this.props.t('connector.inventoryState_' + value)
			},
			{
				field: 'retries',
				headerName: this.props.t('connector.syncAttempts'),
				editable: false,
				flex: 1,
			},
			{
				field: 'lastUpdateAt',
				headerName: this.props.t('connector.lastUpdateAt'),
				editable: false,
				flex: 1,
				valueFormatter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('connector.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						size="small"
						variant="contained"
						color="primary"
						title={this.props.t('connector.restart')}
						disabled={this.props.busy}
						onClick={() => this.onRestartInventoryEntry(cellValues.row)}
						disabled={cellValues.row.state !== 'ERROR'}
					>
						<ReplayIcon fontSize="small" />
					</IconButton>
				</Fragment>)
			}
		];

		return <Box>
			<Box sx={{mb: 2, mt: 2}}>
				<Typography sx={{fontWeight: 700}} variant="h7">{this.props.t('connector.inventoryGrid')}
				</Typography>
			</Box>
			<DataGrid
				pagination
				paginationMode="server"
				paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
				onPaginationModelChange={this.onPaginationModelChange}
				disableColumnSelector
				columns={dataGridColumns}
				disableColumnFilter
				disableRowSelectionOnClick

				sortingMode="server"
				sortModel={this.state.sortModel}
				onSortModelChange={this.onSortModelChange}

				rows={this.props.inventoryList || []}
				rowCount={this.props.inventoryCount || 0}
				pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
				loading={this.props.busy}
				density="compact"
				autoHeight

				slots={{
					toolbar: FlexibleToolbar,
				}}
				slotProps={{
					toolbar: {
						filterId: 'input-inventory-overview-search-text',
						filterValue: this.state.filterValue,
						onChangeFilterValue: this.onFilterValueChange,
						refreshAction: this.onFetchConnectorInventoryList
					},
				}}
			/>
		</Box>
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onFetchConnectorInventoryList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onFetchConnectorInventoryList);
	}

	onFilterValueChange = (e) => {
		this.setState({
			filterValue: e.target.value,
		}, this.onFetchConnectorInventoryList)
	}

	onFetchConnectorInventoryList = () => {
		this.props.onFetchConnectorInventoryList(
			this.props.instanceId,
			{
				page: this.state.page,
				pageSize: this.state.pageSize,
				filterValue: this.state.filterValue,
				sortField: this.state.sortModel.length > 0 ? {
					name: this.state.sortModel[0].field,
					sortOrder: this.state.sortModel[0].sort.toUpperCase()
				} : null
			}
		);
	}

	onRestartInventoryEntry = (entry) => {
		this.props.onRestartConnectorInventoryEntry(this.props.instanceId, entry.id);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			busy: state.company.connectorSettings?.inventoryOverviewBusy,
			inventoryList: state.company.connectorSettings?.inventoryList,
			inventoryCount: state.company.connectorSettings?.inventoryCount,
			connectorItemRestarted: state.company.connectorItemRestarted,
		}
	},
	dispatch => {
		return {
			onFetchConnectorInventoryList: (instanceId, request) => {
				dispatch({
					type: 'COMPANY_FETCH_CONNECTOR_INVENTORY_LIST',
					instanceId,
					request,
				})
			},
			onRestartConnectorInventoryEntry: (instanceId, entryId) => {
				dispatch({
					type: 'COMPANY_RESTART_CONNECTOR_INVENTORY_ENTRY',
					instanceId,
					entryId,
				})
			},
		}
	}
)(ConnectorInventoryGridComponent));