import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "./FlexibleToolbar";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS,
} from "./DataGridColumUtil";

const defaultState = {
	filterValue: '',
	filterValueUC: '',
	selectedIds: [],

	extraColumns: [],
	columnWidths: [],
};

const COLUMN_CONFIG_KEY = 'select-user';

class SelectUserDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
				columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['firstName', 'lastName', 'email']),
			});
		}
	}

	render() {
		const columnConfiguration = this.state.columnConfiguration;
		if (!columnConfiguration) return;

		const allPossibleDataGridColumns = [
			{
				field: 'firstName',
				headerName: this.props.t('user.firstName'),
				...COLUMN_WIDTH(columnConfiguration, 'firstName', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'lastName',
				headerName: this.props.t('user.lastName'),
				...COLUMN_WIDTH(columnConfiguration, 'lastName', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'memo',
				headerName: this.props.t('user.memo'),
				...COLUMN_WIDTH(columnConfiguration, 'memo', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true
			},
			{
				field: 'email',
				headerName: this.props.t('user.email'),
				...COLUMN_WIDTH(columnConfiguration, 'email', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: false
			},
			{
				field: 'state',
				headerName: this.props.t('user.state'),
				...COLUMN_WIDTH(columnConfiguration, 'state', {flex: 1}),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				valueFormatter: (value) => this.props.t('user.state_' + value)
			}
		]

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   disableRestoreFocus
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="lg"
		>
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<DataGrid
					disableColumnSelector
					disableColumnFilter
					checkboxSelection
					keepNonExistentRowsSelected
					pagination

					hideFooterSelectedRowCount={!this.props.multiselect}
					rowSelectionModel={this.state.selectedIds}
					onRowSelectionModelChange={this.onChangeSelectedValues}
					disableColumnPinning
					columns={filteredColumns}
					onColumnResize={this.onResizeColumn}
					onColumnOrderChange={this.onColumnOrderChange}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-select-user-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
							possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
							columns: filteredColumns.map(column => column.field),
							onChangeColumns: this.onChangeExtraColumns,
							onResetColumnsWidth: this.onResetColumnsWidth,
						}
					}}

					rows={(this.props.users || []).filter(user =>
						user.firstName.toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						user.lastName.toUpperCase().indexOf(this.state.filterValueUC) >= 0 ||
						user.email.toUpperCase().indexOf(this.state.filterValueUC) >= 0)}
					autoPageSize
					density="compact"
					sx={{height: 500}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" onClick={this.onSelect} id="btn-selection-confirm"
						disabled={this.state.selectedIds.length === 0}>{this.props.t('select')}</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.state.selectedIds.length !== 0) {
			this.onSelect();
		}
	}

	onChangeSelectedValues = (selectedIds) => {
		if (this.props.multiselect) {
			this.setState({selectedIds});
		} else {
			const currentSelection = this.state.selectedIds[0];
			this.setState({selectedIds: selectedIds.filter(el => !currentSelection || el !== currentSelection)});
		}
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onSelect = () => {
		this.props.onSelect(this.state.selectedIds);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(SelectUserDialog);
