import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Box, Button, Link, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import eidLogo from "../../img/eid.png"
import ServerErrorComponent from "../common/ServerErrorComponent";
import SessionThemedContainer from "./SessionThemedContainer";
import SessionLoginEidDialog from "./SessionLoginEidDialog";
import {IS_MOBILE_DEVICE} from "../common/Constants";
import CookieBanner from "../common/CookieBanner";
import {Redirect, withRouter} from "../common/RouterHelper";

const defaultState = {
	redirect: false,
	eidDialogOpen: false,
	error: null
}

const AlternativeLoginButton = (props) => {
	return <Button
		color="primary"
		variant="outlined"
		fullWidth
		onClick={props.onClick}
		disabled={props.disabled}
		sx={{mb: 2, p: 0.7, display: 'flex', justifyContent: 'flex-start'}}
	>
		<Avatar src={props.logo}
				sx={{width: 24, height: 24, mr: 1}}
				alt={props.logoAlt}
		/>
		<Box sx={{flexGrow: 1, textAlign: 'center'}}>{props.title}</Box>
	</Button>
}

class SessionLoginComponent extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = defaultState;
	}

	componentDidMount() {
		if (!this.props.sessionChecked) {
			this.props.onSessionCheck();
		} else {
			if (this.props.sessionCreated) {
				// session already exists, login page has no use, redirect the user to a useful page
				this.setState({redirect: true});
			} else {
				this.props.onFetchCompanyInfo();
			}
		}

		// check for error message in search params
		const data = this.props?.router?.params?.data;
		if (data && data.startsWith('error=')) {
			// set the error and cleanup the URL
			this.setState({error: data.substring('error='.length)}, () => this.props.router.navigate('/login'));
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.busy || this.state.redirect) return;

		if (this.props.sessionCreated) {
			// session already exists, login page has no use, redirect the user to a useful page
			this.setState({redirect: true});
		} else if (!this.props.sessionLoginInfo) {
			this.props.onFetchCompanyInfo();
		}
	}

	render() {
		if (this.state.redirect) {
			return (<Redirect to="/"/>);
		}

		if (!this.props.sessionLoginInfo) {
			return <LoadingComponent/>;
		}

		const {oidcInstances, companyId, companyStyleSettings, useEidMiddlewarePinInput, oidcLoginOnly} = this.props.sessionLoginInfo;
		const serverError = this.state.error || this.props.serverError;

		return <SessionThemedContainer style={companyStyleSettings} title={this.props.t('session.login')}>

			{!oidcLoginOnly && <Box component="form" noValidate onSubmit={this.onSubmit}>
				<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1}}>
					<TextField
						margin="normal"
						required
						id="email"
						label={this.props.t('session.email')}
						name="email"
						autoComplete="email"
						disabled={this.props.loginBusy || this.props.mfaMethod === 'AUTHENTICATOR'}
						autoFocus
						sx={{flexGrow: 1, mt: 0}}
					/>
					<TextField
						margin="normal"
						required
						name="password"
						label={this.props.t('session.password')}
						type="password"
						disabled={this.props.loginBusy || this.props.mfaMethod === 'AUTHENTICATOR'}
						id="password"
						autoComplete="current-password"
						sx={{flexGrow: 1, mt: 0}}
					/>
					{this.props.mfaMethod === 'AUTHENTICATOR' &&
						<TextField
							margin="normal"
							required
							fullWidth
							id="auth-challenge"
							label={this.props.t('user.authenticatorChallenge')}
							name="auth-challenge"
							disabled={this.props.loginBusy}
							autoFocus
							autoComplete="off"
							helperText={this.props.t('user.authenticatorEnterChallenge')}
							sx={{flexGrow: 1, mt: 0}}
						/>
					}
				</Box>

				<Button type="submit" fullWidth disabled={this.props.loginBusy}
						variant="contained" color="primary">
					{this.props.t('session.login')}
				</Button>

				<Link component="button" onClick={() => this.props.router.navigate('/forgot-password')} underline="hover" sx={{mt: 0.5, display: 'block'}}>
					{this.props.t('session.forgotPassword')}
				</Link>
			</Box>}

			{serverError &&
				<Box sx={{width: '100%'}}>
					<ServerErrorComponent serverError={this.props.t(serverError)}/>
				</Box>
			}

			{this.props.busy && <Box sx={{mt: 1}}>
				<LoadingComponent/>
			</Box>}

			{!this.props.busy && (!IS_MOBILE_DEVICE || oidcInstances?.length > 0) &&
				<Box sx={{mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
					{!oidcLoginOnly && <><Typography variant="body2" sx={{color: 'grey'}}>{this.props.t('session.alternativeLogins')}</Typography>

						{!IS_MOBILE_DEVICE &&
							<AlternativeLoginButton
								onClick={this.onOpenEidDialog}
								disabled={this.props.busy || this.props.loginBusy}
								logo={eidLogo}
								logoAlt="eid-login"
								title={this.props.t('session.loginMethod_EID')}
							/>}
					</>}

					{(oidcInstances || []).map((oidcInstance) =>
						<AlternativeLoginButton
							key={oidcInstance.id}
							onClick={() => this.props.onSelectOidcInstance(oidcInstance.id)}
							disabled={this.props.busy || this.props.loginBusy}
							logo={oidcInstance.logoBase64 ? 'data:image/png;base64,' + oidcInstance.logoBase64 : ''}
							logoAlt={oidcInstance.name}
							title={oidcInstance.name}
						/>)}
				</Box>
			}

			<CookieBanner/>

			{this.state.eidDialogOpen && <SessionLoginEidDialog
				style={companyStyleSettings}
				open={this.state.eidDialogOpen}
				useMiddlewarePinInput={useEidMiddlewarePinInput}
				onClose={this.onCloseEidDialog}
				companyId={companyId}
			/>}
		</SessionThemedContainer>
	}

	onOpenEidDialog = () => {
		this.setState({eidDialogOpen: true});
	}

	onCloseEidDialog = () => {
		this.setState({eidDialogOpen: false});
	}

	onSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const secret = (this.props.mfaMethod === 'PASSWORD') ? data.get('password') : data.get('auth-challenge');

		this.props.onLoginPasswordMfa(data.get('email'), secret, this.props.sessionLoginInfo?.companyId, this.props.mfaMethod);
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			busy: state.session.busy,
			sessionLoginInfo: state.session.loginInfo,
			sessionCreated: state.session.created,
			sessionChecked: state.session.checked,
			serverError: ['AUTHENTICATION_FACTOR_METHOD_INVALID', 'AUTHENTICATION_FACTOR_CHALLENGE_INVALID', 'AUTHENTICATION_FACTOR_CHALLENGE_EXPIRED']
				.indexOf(state.session.serverError) >= 0 ? state.session.serverError + '_AUTHENTICATOR' : state.session.serverError,
			loginBusy: state.session.loginBusy,
			mfaMethod: state.session.mfaMethod
		}
	},
	dispatch => {
		return {
			onSessionCheck: () => {
				dispatch({
					type: 'SESSION_CHECK',
					loginPageOrigin: true
				})
			},
			onFetchCompanyInfo: () => {
				dispatch({
					type: 'SESSION_FETCH_COMPANY_INFO',
					currentHostName: window.location.hostname
				})
			},
			onLoginPasswordMfa: (email, secret, companyId, method) => {
				dispatch({
					type: 'SESSION_LOGIN_PASSWORD_MFA',
					email,
					secret,
					companyId,
					method
				})
			},
			onSelectOidcInstance: (oidcInstanceId) => {
				dispatch({
					type: 'SESSION_CREATE_OIDC',
					oidcInstanceId
				})
			}
		}
	}
)(SessionLoginComponent)));
