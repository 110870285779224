const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// company list overview
	overviewList: [],
	overviewCount: 0,

	// indicate that the action on the company was successful
	companyActionSuccessfully: false,

	// list of minimal data from companies to switch to
	switchList: null,

	// settings
	generalSettings: null,
	styleSettings: null,
	emailSettings: null,
	adminSettings: null,
	connectorSettings: null,
	signingCertificateSettings: null,
	settingsPendingChanges: false,
	settingsPendingChangesSavePossible: true,

	// email
	emailServerConnectionResult: null,

	// relevant users (to manage delegate lists)
	relevantUsers: null,

	// connector
	connectorInstanceActionSuccessFully: false,
	connectorActivityActionSuccessFully: false,

	// delegate
	delegateLists: null,
	delegateList: null,
	delegateActionSuccessFully: false,

	// api keys
	companyApiKeyOverview: null,
	companyApiKeysActionSuccessfully: false,

	// webhookconfigs
	webhookConfigList: [],
	webhookConfigActionSuccessFully: false,

	// statistics
	statsSignatures: null,
	statsSignaturesItsme: null,
	statsApprovals: null,

	// action logs
	actionLogList: [],
	actionLogCount: 0
};

const company = (state = defaultState, action) => {
	switch (action.type) {
		case 'COMPANY_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'COMPANY_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'COMPANY_FETCH_GENERAL_SETTINGS':
		case 'COMPANY_FETCH_NOTIFICATION_SETTING':
		case 'COMPANY_FETCH_STYLE_SETTINGS':
		case 'COMPANY_FETCH_SWITCH_LIST':
		case 'COMPANY_FETCH_RELEVANT_USERS':
		case 'COMPANY_FETCH_DELEGATE_LISTS':
		case 'COMPANY_FETCH_ACTION_LOGS':
		case 'COMPANY_FETCH_CONNECTOR_INSTANCE_LIST':
		case 'COMPANY_FETCH_CONNECTOR_ACTIVITY_LIST':
		case 'COMPANY_FETCH_AVAILABLE_CONNECTOR_TYPES':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false
			}

		case 'COMPANY_UPDATE_GENERAL_SETTINGS':
		case 'COMPANY_UPDATE_EMAIL_SETTINGS':
		case 'COMPANY_UPDATE_NOTIFICATION_SETTINGS':
		case 'COMPANY_UPDATE_STYLE_SETTINGS':
		case 'COMPANY_TESTS_SEND_EMAILS':
		case 'COMPANY_TEST_SEND_EMAIL':
			return {
				...state,
				busy: true,
				serverError: null,
			}


		case 'COMPANY_FETCH_GENERAL_SETTINGS_SUCCESS': {
			const generalSettings = action.data;

			// bit hackish pendingApprovalAndSignaturesExpirationEnabled doesn't exist in backend,
			// but use it here of the checkbox state
			if (generalSettings?.expirationSettings?.pendingApprovalAndSignaturesExpirationInDays > 0) {
				generalSettings.expirationSettings.pendingApprovalAndSignaturesExpirationEnabled = true;
			}

			return {
				...state,
				busy: false,
				serverError: null,
				generalSettings,
				settingsPendingChanges: false,
			}
		}

		case 'COMPANY_FETCH_EMAIL_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false,
				emailAuthenticatedDomains: null,
				emailAuthenticatedDomainsValidationSummaryList: null,
				emailServerConnectionResult: null
			}

		case 'COMPANY_FETCH_EMAIL_SETTINGS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				emailSettings: {
					...action.data,
					personalizeEmailsCompany: !!action.data.address
				},
				settingsPendingChanges: false,
			}
		}

		case 'COMPANY_FETCH_NOTIFICATION_SETTINGS_SUCCESS': {
			const notificationSettings = action.data;

			// bit hackish pendingApprovalAndSignaturesReminderEnabled doesn't exist in backend,
			// but use it here of the checkbox state
			if (notificationSettings?.pendingApprovalAndSignaturesReminderInDays > 0) {
				notificationSettings.pendingApprovalAndSignaturesReminderEnabled = true;
			}


			return {
				...state,
				busy: false,
				serverError: null,
				notificationSettings,
				settingsPendingChanges: false,
			}
		}

		case 'COMPANY_FETCH_RELEVANT_USERS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				relevantUsers: action.data,
			}
		}

		case 'COMPANY_FETCH_DELEGATE_LISTS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				delegateLists: action.data,
			}
		}

		case 'COMPANY_FETCH_CONNECTOR_INVENTORY_LIST':
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					inventoryOverviewBusy: true
				},
			}

		case 'COMPANY_FETCH_CONNECTOR_INVENTORY_LIST_SUCCESS': {
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					inventoryList: action.data.list,
					inventoryCount: action.data.count,
					inventoryOverviewBusy: false
				},
				connectorItemRestarted: false
			}
		}

		case 'COMPANY_SEARCH_CONNECTOR_USER': {
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					userSearchBusy: true,
					users: []
				},
			}
		}

		case 'COMPANY_SEARCH_CONNECTOR_USER_SUCCESS': {
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					userSearchBusy: false,
					users: action.data
				},
			}
		}

		case 'COMPANY_SEARCH_CONNECTOR_FOLDER': {
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					folderSearchBusy: true,
					folders: []
				},
			}
		}

		case 'COMPANY_SEARCH_CONNECTOR_FOLDER_SUCCESS': {
			return {
				...state,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					folderSearchBusy: false,
					folders: action.data
				},
			}
		}

		case 'COMPANY_CREATE_CONNECTOR_INSTANCE':
		case 'COMPANY_UPDATE_CONNECTOR_INSTANCE_CONFIG':
		case 'COMPANY_DELETE_CONNECTOR_INSTANCE':
			return {
				...state,
				busy: true,
				serverError: null,
				connectorInstanceActionSuccessFully: false,
				connectorTestResult: null,
				settingsPendingChanges: false,
			}

		case 'COMPANY_FETCH_CONNECTOR_INSTANCE_LIST_SUCCESS': {
			return {
				...state,
				busy: false,
				connectorSettings: {
					...state.connectorSettings,
					instanceList: action.data
				},
			}
		}

		case 'COMPANY_FETCH_CONNECTOR_ACTIVITY_LIST_SUCCESS': {
			return {
				...state,
				busy: false,
				connectorSettings: {
					...state.connectorSettings,
					activityList: action.data.list,
					activityCount: action.data.count
				},
			}
		}

		case 'COMPANY_FETCH_AVAILABLE_CONNECTOR_TYPES_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				connectorSettings: {
					...state.connectorSettings,
					availableConnectorTypes: action.data,
				},
			}
		}

		case 'COMPANY_CREATE_CONNECTOR_INSTANCE_SUCCESS':
		case 'COMPANY_UPDATE_CONNECTOR_INSTANCE_SUCCESS':
		case 'COMPANY_DELETE_CONNECTOR_INSTANCE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				connectorInstanceActionSuccessFully: true,
				connectorTestResult: null,
				settingsPendingChanges: false
			}

		case 'COMPANY_CREATE_CONNECTOR_ACTIVITY':
		case 'COMPANY_UPDATE_CONNECTOR_ACTIVITY':
		case 'COMPANY_DELETE_CONNECTOR_ACTIVITY':
			return {
				...state,
				busy: true,
				serverError: null,
				connectorActivityActionSuccessFully: false,
				settingsPendingChanges: false,
			}

		case 'COMPANY_CREATE_CONNECTOR_ACTIVITY_SUCCESS':
		case 'COMPANY_UPDATE_CONNECTOR_ACTIVITY_SUCCESS':
		case 'COMPANY_DELETE_CONNECTOR_ACTIVITY_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				connectorActivityActionSuccessFully: true,
				settingsPendingChanges: false,
			}

		case 'COMPANY_CHANGE_CONNECTOR_INSTANCE_CONFIG': {
			const instanceList = state.connectorSettings.instanceList;
			const instanceIndex = instanceList.findIndex(instance => instance.id === action.instanceId);
			if (instanceIndex >= 0) {
				const configIndex = instanceList[instanceIndex].config.findIndex(config => config.key === action.key);
				if (configIndex >= 0) {
					instanceList[instanceIndex].config[configIndex].value = action.value;
				} else {
					instanceList[instanceIndex].config.push({key: action.key, value: action.value});
				}
			}
			return {
				...state,
				connectorSettings: {
					...state.connectorSettings,
					instanceList
				},
				connectorTestResult: null,
				settingsPendingChanges: true,
				settingsPendingChangesSavePossible: true
			}
		}

		case 'COMPANY_TEST_CONNECTOR_INSTANCE_CONFIG':
			return {
				...state,
				busy: true,
				serverError: null,
				connectorTestResult: null,
			}

		case 'COMPANY_TEST_CONNECTOR_INSTANCE_CONFIG_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				connectorTestResult: action.data,
			}
		}

		case 'COMPANY_RESTART_CONNECTOR_INVENTORY_ENTRY': {
			return {
				...state,
				busy: true,
				serverError: null,
				connectorItemRestarted: false,
			}
		}

		case 'COMPANY_RESTART_CONNECTOR_INVENTORY_ENTRY_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				connectorItemRestarted: true,
			}
		}

		case 'COMPANY_CREATE_UPDATE_DELEGATE_LIST':
		case 'COMPANY_DELETE_DELEGATE_LIST':
		case 'COMPANY_DOWNLOAD_DELEGATE_FILE':
		case 'COMPANY_DELETE_DELEGATE_FILE':
			return {
				...state,
				busy: true,
				serverError: null,
				delegateActionSuccessFully: false,
			}

		case 'COMPANY_FETCH_DELEGATE_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				delegateList: null
			}

		case 'COMPANY_FETCH_DELEGATE_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				delegateList: action.data,
			}

		case 'COMPANY_DELETE_DELEGATE_LIST_SUCCESS':
		case 'COMPANY_DOWNLOAD_DELEGATE_FILE_SUCCESS':
		case 'COMPANY_DELETE_DELEGATE_FILE_SUCCESS':
			return {
				...state,
				busy: true,
				serverError: null,
				delegateActionSuccessFully: true,
			}

		case 'COMPANY_CLEAR_DELEGATE_LIST':
			return {
				...state,
				busy: false,
				serverError: null,
				delegateActionSuccessFully: true,
				delegateList: null
			}

		case 'COMPANY_CREATE_UPDATE_DELEGATE_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				delegateList: action.data,
				delegateActionSuccessFully: true,
			}

		case 'COMPANY_FETCH_STYLE_SETTINGS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				styleSettings: action.data,
				settingsPendingChanges: false,
			}
		}

		case 'COMPANY_FETCH_SWITCH_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				switchList: action.data,
			}

		case 'COMPANY_CHANGE_GENERAL_SETTINGS_ITEM': {
			const generalSettings = {
				...state.generalSettings
			};
			generalSettings[action.key] = {
				...state.generalSettings[action.key]
			};
			generalSettings[action.key][action.subkey] = action.value;
			return {
				...state,
				generalSettings,
				settingsPendingChanges: true,
				settingsPendingChangesSavePossible: true
			}
		}

		case 'COMPANY_CHANGE_EMAIL_SETTINGS_ITEM': {
			const emailSettings = {
				...state.emailSettings
			};
			if (!!action.subkey) {
				emailSettings[action.key] = {
					...state.emailSettings[action.key]
				};
				emailSettings[action.key][action.subkey] = action.value;
			} else {
				emailSettings[action.key] = action.value;
			}
			return {
				...state,
				emailSettings,
				settingsPendingChanges: true,
			}
		}

		case 'COMPANY_CHANGE_EMAIL_SETTINGS': {
			return {
				...state,
				emailSettings: {
					...state.emailSettings,
					...action.emailSettings
				},
				settingsPendingChanges: true,
				settingsPendingChangesSavePossible: action.savePossible
			}
		}

		case 'COMPANY_CHANGE_NOTIFICATION_SETTINGS_ITEM': {
			const notificationSettings = {
				...state.notificationSettings
			};
			notificationSettings[action.key] = action.value;
			return {
				...state,
				notificationSettings,
				settingsPendingChanges: true,
				settingsPendingChangesSavePossible: true
			}
		}

		case 'COMPANY_CHANGE_STYLE_SETTINGS_ITEM': {
			const styleSettings = {
				...state.styleSettings
			};
			styleSettings[action.key] = action.value;
			return {
				...state,
				styleSettings,
				settingsPendingChanges: true,
				settingsPendingChangesSavePossible: true
			}
		}

		case 'COMPANY_FETCH_OVERVIEW_LIST':
		case 'COMPANY_FETCH_ADMIN_SETTINGS':
			return {
				...state,
				busy: true,
				adminSettings: null,
				serverError: null
			}

		case 'COMPANY_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				eligibleParentList: null
			}

		case 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				eligibleParentList: action.data
			}

		case 'COMPANY_FETCH_ADMIN_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				adminSettings: action.data,
				settingsPendingChanges: false,
			}

		case 'COMPANY_CREATE':
		case 'COMPANY_UPDATE_ADMIN_SETTINGS':
		case 'COMPANY_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				companyActionSuccessfully: false
			}

		case 'COMPANY_CREATE_SUCCESS':
		case 'COMPANY_UPDATE_ADMIN_SETTINGS_SUCCESS':
		case 'COMPANY_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyActionSuccessfully: true,
				settingsPendingChanges: false,
			}

		case 'COMPANY_CHANGE_ADMIN_SETTING': {
			const adminSettings = {
				...state.adminSettings
			};
			if (null === action.value) {
				delete adminSettings[action.key];
			} else {
				adminSettings[action.key] = action.value;
			}

			return {
				...state,
				adminSettings
			}
		}

		case 'COMPANY_FETCH_STATS_APPROVALS': {
			return {
				...state,
				busy: true,
				serverError: null,
				statsApprovals: null,
			}
		}

		case 'COMPANY_FETCH_STATS_APPROVALS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				statsApprovals: action.data,
			}
		}

		case 'COMPANY_FETCH_STATS_SIGNATURES': {
			return {
				...state,
				busy: true,
				serverError: null,
				statsSignatures: null,
			}
		}

		case 'COMPANY_FETCH_STATS_SIGNATURES_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				statsSignatures: action.data,
			}
		}

		case 'COMPANY_FETCH_STATS_SIGNATURES_ITSME_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				statsSignaturesItsme: action.data,
			}
		}

		case 'COMPANY_FETCH_STATS_CONFIG': {
			return {
				...state,
				busy: true,
				serverError: null,
				statsConfig: null,
			}
		}

		case 'COMPANY_FETCH_STATS_CONFIG_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				statsConfig: action.data,
			}
		}

		case 'COMPANY_FETCH_ACTION_LOGS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				actionLogList: action.list,
				actionLogCount: action.count,
			}
		}

		case 'COMPANY_OIDC_INSTANCE_FETCH_LIST': {
			return {
				...state,
				settingsPendingChanges: false
			}
		}

		case 'COMPANY_PREVIEW_EMAIL': {
			const emailSettings = {
				...state.emailSettings,
				busy: true,
				emailPreview: null
			};

			return {
				...state,
				emailSettings
			}
		}

		case 'COMPANY_PREVIEW_EMAIL_SUCCESS': {
			const emailSettings = {
				...state.emailSettings,
				busy: false,
				emailPreview: action.data.content
			};
			return {
				...state,
				emailSettings,
			}
		}

		case 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_FETCH': {
			return {
				...state,
				busy: true,
				serverError: null,
				emailAuthenticatedDomains: null
			}
		}

		case 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_FETCH_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				emailAuthenticatedDomains: action.data
			}
		}

		case 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_VALIDATE': {
			return {
				...state,
				busy: true,
				serverError: null,
				emailAuthenticatedDomainsValidationSummaryList: null
			}
		}

		case 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_VALIDATE_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				emailSettings: {
					...state.emailSettings,
					emailAuthenticatedDomainsValidationResult: action.data.result
				},
				emailAuthenticatedDomainsValidationSummaryList: action.data.validationList
			}
		}

		case 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE': {
			return {
				...state,
				busy: true,
				serverError: null,
				emailServerConnectionResult: null,
			}
		}

		case 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				emailServerConnectionResult: action.data,
			}
		}

		case 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE_CLEAR': {
			return {
				...state,
				emailServerConnectionResult: null,
			}
		}

		case 'COMPANY_FETCH_SIGNING_CERTIFICATE_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				settingsPendingChanges: false,
			}

		case 'COMPANY_FETCH_SIGNING_CERTIFICATE_SETTINGS_SUCCESS': {
			return {
				...state,
				busy: false,
				serverError: null,
				signingCertificateSettings: action.data,
				settingsPendingChanges: false,
			}
		}

		case 'COMPANY_CHANGE_SIGNING_CERTIFICATE_SETTINGS': {
			const signingCertificateSettings = {
				...state.signingCertificateSettings
			};
			signingCertificateSettings[action.key] = action.value;
			return {
				...state,
				signingCertificateSettings,
				settingsPendingChanges: true,
			}
		}

		case 'COMPANY_UPDATE_SIGNING_CERTIFICATE_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyActionSuccessfully: true,
				settingsPendingChanges: false,
			}

		case 'COMPANY_APIKEY_OVERVIEW_FETCH':
			return {
				...state,
				busy: true,
				serverError: null,
				companyApiKeyOverview: null,
			}

		case 'COMPANY_APIKEY_OVERVIEW_FETCH_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeyOverview: action.data
			}
		case 'COMPANY_APIKEY_CREATE':
		case 'COMPANY_APIKEY_UPDATE':
		case 'COMPANY_APIKEY_REVOKE':
			return {
				...state,
				busy: true,
				serverError: null,
				companyApiKeysActionSuccessfully: false,
			}

		case 'COMPANY_APIKEY_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeysActionSuccessfully: true,
				companyApiKey: action.data
			}
		case 'COMPANY_APIKEY_UPDATE_SUCCESS':
		case 'COMPANY_APIKEY_REVOKE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				companyApiKeysActionSuccessfully: true,
			}
		case 'COMPANY_APIKEY_DIALOG_RESET':
			return {
				...state,
				companyApiKey: null
			}

		case 'COMPANY_WEBHOOK_CONFIG_FETCH_OVERVIEW':
		case 'COMPANY_WEBHOOK_CONFIG_CREATE':
		case 'COMPANY_WEBHOOK_CONFIG_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				webhookConfigActionSuccessFully: false,
			}
		case 'COMPANY_WEBHOOK_CONFIG_FETCH_OVERVIEW_SUCCESS':
			return {
				...state,
				busy: false,
				webhookConfigList: action.data
			}
		case 'COMPANY_WEBHOOK_CONFIG_CREATE_SUCCESS':
		case 'COMPANY_WEBHOOK_CONFIG_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				webhookConfigActionSuccessFully: true,
			}

		default:
			return state;
	}
}

export default company;
