const defaultState = {
	// general state
	busy: false,
	serverError: null,
	serverErrorExtra: null,

	// data
	data: null,
	kioskSigners: null,

	// guest access renewal
	guestAccessRenewed: false,

	// locks
	requestsLocked: [],
	requestsLockedState: 'INIT', // 'INIT', 'FAILED', 'QUEUED', 'ACQUIRED'
	requestsLockedPositionInQueue: 0,
	requestsLockedGuestAccessExpired: false,

	// signed/completed
	requestsSigned: [],
	requestsCompleted: [],
	requestsCompletedState: 'INIT', // 'INIT', 'ALL', 'SOME', 'NONE'

	// aborted
	abortedReason: null, // 'ERROR', 'PIN_WRONG', 'PIN_TIMEOUT'

	// itsme
	itsmeState: null,
	itsmeProgress: 0,
};

const signing = (state = defaultState, action) => {
	switch (action.type) {
		case 'SIGNING_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'SIGNING_FETCH_DATA':
			return {
				...state,
				busy: true,
				serverError: null,
				data: null,
				guestAccessRenewed: false,
			}

		case 'SIGNING_CLEAR_DATA':
			return {
				...state,
				busy: true,
				serverError: null,
				serverErrorExtra: null,
				data: null,
				kioskSigners: null,
				guestAccessRenewed: false,
			}

		case 'SIGNING_FETCH_DATA_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				serverErrorExtra: null,
				data: action.data,
				kioskSigners: action.data.kioskSigners
			}

		case 'SIGNING_RENEW_GUEST_ACCESS':
			return {
				...state,
				busy: true,
				serverError: null,
				guestAccessRenewed: false,
			}

		case 'SIGNING_RENEW_GUEST_ACCESS_SUCCESS':
			return {
				...state,
				busy: true,
				guestAccessRenewed: true,
			}

		case 'SIGNING_ACQUIRE_LOCKS':
			return {
				...state,
				busy: true,
				serverError: null,
				requestsLocked: [],
				requestsLockedState: 'INIT',
				requestsLockedPositionInQueue: 0,
			}

		case 'SIGNING_ACQUIRE_LOCKS_FAILED': {
			return {
				...state,
				busy: false,
				serverError: null,
				requestsLockedState: 'FAILED',
				requestsLockedGuestAccessExpired: action.guestAccessExpired
			}
		}
		case 'SIGNING_ACQUIRE_LOCKS_QUEUED': {
			return {
				...state,
				busy: false,
				serverError: null,
				requestsLockedState: 'QUEUED',
				requestsLockedPositionInQueue: action.positionInQueue
			}
		}
		case 'SIGNING_ACQUIRE_LOCKS_ACQUIRED': {
			return {
				...state,
				busy: false,
				serverError: null,
				requestsLockedState: 'ACQUIRED',
				requestsLocked: action.signRequestIds
			}
		}

		case 'SIGNING_SIGN_START':
			return {
				...state,
				busy: true,
				serverError: null,
				requestsSigned: [],
				requestsCompleted: [],
				requestsCompletedState: 'INIT',
				abortedReason: null
			}

		case 'SIGNING_SIGN_DOCUMENT_COMPLETE': {
			const documents = (state.data?.documents || [])
				.map(doc => doc.signRequestId === action.signRequestId ?
					{
						...doc,
						signRequestState: action.signed ? 'SIGNED' : 'DECLINED'
					}
					:
					doc)
			;

			const requestsSigned = [...state.requestsSigned];
			if (action.signed) {
				requestsSigned.push(action.signRequestId);
			}
			const requestsCompleted = [...state.requestsCompleted];
			requestsCompleted.push(action.signRequestId);

			return {
				...state,
				data: {
					...state.data,
					documents,
				},
				requestsSigned,
				requestsCompleted
			}
		}

		case 'SIGNING_SIGN_ABORTED':
			return {
				...state,
				busy: false,
				abortedReason: action.reason,
			}

		case 'SIGNING_SIGN_FINISHED':
			let kioskSigners = state.kioskSigners;
			if (!!state.kioskSigners) {
				kioskSigners = state.kioskSigners
					.map(kioskSigner => {
						if (kioskSigner.signRequestState !== 'WAITING_FOR_SIGNATURE') {
							return kioskSigner;
						}

						let signRequestState = kioskSigner.signRequestIds.every(signRequestId => state.requestsSigned.includes(signRequestId)) ? 'SIGNED' : null;
						if (!signRequestState && kioskSigner.signRequestIds.every(signRequestId => state.requestsCompleted.includes(signRequestId))) {
							signRequestState = 'DECLINED';
						}
						return {...kioskSigner,
							signRequestState: !!signRequestState ? signRequestState : kioskSigner.signRequestState
						}
					})
			}

			return {
				...state,
				busy: false,
				serverError: null,
				requestsCompletedState: state.requestsCompleted.length === state.requestsLocked.length ? 'ALL' : 'SOME',
				kioskSigners
			}

		case 'SIGNING_SIGN_ERROR':
			return {
				...state,
				busy: false,
				abortedReason: 'SERVER_ERROR',
				serverError: action.serverError,
				serverErrorExtra: action.serverErrorExtra,
			}

		case 'SIGNING_SIGN_ERROR_CLEAR':
			return {
				...state,
				abortedReason: null,
				serverError: null,
				serverErrorExtra: null,
			}

		case 'SIGNING_OTP_AUTHENTICATE':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'SIGNING_OTP_AUTHENTICATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
			}

		case 'SIGNING_ITSME_STATUS_LOOP':
			return {
				...state,
				itsmeState: null,
				itsmeProgress: 0
			}

		case 'SIGNING_ITSME_UPDATE_STATE':
			return {
				...state,
				itsmeState: action.state,
				itsmeProgress: action.progress
			}

		case 'SIGNING_FORWARD':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'SIGNING_FORWARD_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'SIGNING_FORWARD_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'SIGNING_MESSAGE_FETCH_COUNT':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'SIGNING_MESSAGE_FETCH_COUNT_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				data: {
					...state.data,
					documents: state.data.documents.map(doc => doc.id === action.documentId ? ({
						...doc,
						messageCount: action.data
					}) : doc)
				}
			}

		default:
			return state;
	}
}

export default signing;
