const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// user group list for overview
	overviewList: [],
	overviewCount: 0,

	// user group user list
	userList: [],
	userCount: 0,

	// user group possible user list
	possibleUserList: [],
	possibleUserCount: 0,

	// action success responses
	userGroupActionSuccessfully: false,
	userGroupUserActionSuccessfully: false,
	createdUserGroupId: null,

	// current usergroup settings
	settings: null,
};

const userGroup = (state = defaultState, action) => {
	switch (action.type) {
		case 'USERGROUP_ERROR':
			return {
				...state,
				serverError: action.serverError,
				busy: false
			}

		case 'USERGROUP_FETCH_OVERVIEW_LIST':
			return {
				...state,
				serverError: null,
				busy: true
			}

		case 'USERGROUP_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'USERGROUP_FETCH_SETTINGS_SUCCESS': {
			const settings = action.data;

			return {
				...state,
				busy: false,
				serverError: null,
				settings,
			}
		}

		case 'USERGROUP_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				userGroupActionSuccessfully: false,
				createdUserGroupId: null,
			}

		case 'USERGROUP_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				userGroupActionSuccessfully: true,
				createdUserGroupId: action.createdUserGroupId,
			}

		case 'USERGROUP_DELETE':
		case 'USERGROUP_UPDATE_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				userGroupActionSuccessfully: false
			}

		case 'USERGROUP_DELETE_SUCCESS':
		case 'USERGROUP_UPDATE_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				userGroupActionSuccessfully: true
			}

		case 'USERGROUP_CHANGE_SETTING':
			const settings = {
				...state.settings
			};

			settings[action.key] = action.value;

			return {
				...state,
				settings
			}

		case 'USERGROUP_FETCH_USER_LIST':
			return {
				...state,
				serverError: null,
				userListBusy: true
			}

		case 'USERGROUP_FETCH_USER_LIST_SUCCESS':
			return {
				...state,
				serverError: null,
				userListBusy: false,
				userList: action.list,
				userCount: action.count
			}

		case 'USERGROUP_ADD_USERS':
		case 'USERGROUP_REMOVE_USER':
		case 'USERGROUP_USER_UPDATE_SETTINGS':
			return {
				...state,
				serverError: null,
				userListBusy: true,
				userGroupUserActionSuccessfully: false,
			}

		case 'USERGROUP_ADD_USERS_SUCCESS':
		case 'USERGROUP_REMOVE_USER_SUCCESS':
		case 'USERGROUP_USER_UPDATE_SETTINGS_SUCCESS':
			return {
				...state,
				serverError: null,
				userListBusy: false,
				userGroupUserActionSuccessfully: true,
			}

		case 'USERGROUP_FETCH_POSSIBLE_USER_LIST':
			return {
				...state,
				serverError: null,
				possibleUserListBusy: true
			}

		case 'USERGROUP_FETCH_POSSIBLE_USER_LIST_SUCCESS':
			return {
				...state,
				serverError: null,
				possibleUserListBusy: false,
				possibleUserList: action.list,
				possibleUserCount: action.count
			}

		default:
			return state;
	}
}

export default userGroup;