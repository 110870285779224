
const CONFIG_KEY = 'column-config';

export const COLUMN_WIDTH = (configuration, column, defaultStyle) => {
	const columnConfiguration = configuration?.find(config => config.name === column);

	if (!!columnConfiguration && !!columnConfiguration.width) {
		return {width: columnConfiguration['width']};
	} else {
		return defaultStyle;
	}
}
export const COLUMN_WIDTH_UPDATE = (configuration, column, width) => {
	return configuration.map(config => config.name === column ? {
		...config, width
	} : config);
}

export const COLUMN_WIDTH_RESET = (configuration) => {
	return configuration.map(config => {
		const {width: _, ...cfg} = config;
		return cfg;
	});
}

export const COLUMN_EXTRA_CHANGE = (configuration, extraColumns) => {
	// filter unwanted columns
	const updatedConfiguration = configuration.filter(config => !!extraColumns.find(extraColumn => extraColumn === config.name));
	// add extra columns
	for (let extraColumn of extraColumns) {
		if (!updatedConfiguration.find(config => config.name === extraColumn)) {
			updatedConfiguration.push({
				name: extraColumn
			})
		}
	}

	return updatedConfiguration;
}

export const COLUMN_CHANGE_ORDER = (configuration, column, oldIndex, newIndex) => {
	const moveToLeft = newIndex < oldIndex;
	const configOldIndex = configuration.find(config => config.name === column)?.seq;

	// taken in account an offset between the configuration and datagrid column index (e.g. if there is a checkbox column on the datagrid which is not in the configuration)
	const configTargetIndex = newIndex - (oldIndex - configOldIndex);

	// assign the moved column the new (offsetted) target index, and increment/decrement all other columns which are impacted by the move
	return configuration.map(config => {
		const impacted = moveToLeft ? (config.seq >= configTargetIndex && config.seq <= configOldIndex) : (config.seq >= configOldIndex && config.seq <= configTargetIndex);
		if (!impacted) {
			return config;
		}

		if (config.name === column) {
			return {
				...config,
				seq: configTargetIndex
			}
		} else {
			return {
				...config,
				seq: moveToLeft ? config.seq +1 : config.seq -1
			}
		}
	});
}

export const COLUMN_CONFIG_RESEQUENCE = (configuration) => {
	// sort by sequence (and keep actions always as last column)
	configuration.sort(function(column, otherColumn) {
		if (column.name === 'actions') {
			return 1;
		} else if (otherColumn.name === 'actions') {
			return -1;
		}
		return column.seq - otherColumn.seq
	});

	// resequence (in case columns were removed)
	return configuration.map((config, index) => ({...config, seq: index}))
}

export const FILTER_SORT_COLUMNS = (allDataGridColumns, columnConfiguration) => {
	const filteredColumns = allDataGridColumns.filter(column => !column.optional || !!columnConfiguration?.find(config => config.name === column.field));
	filteredColumns.sort(function(column, otherColumn){
		// actions is always the last column
		if (column.field === 'actions') {
			return 1;
		} else if (otherColumn.field === 'actions') {
			return -1;
		}

		const columnConfig = columnConfiguration.find(config => config.name === column.field);
		const otherColumnConfig = columnConfiguration.find(config => config.name === otherColumn.field);

		return columnConfig?.seq - otherColumnConfig?.seq;
	});

	return filteredColumns;
}

export const COLUMN_CONFIG_READ_LOCAL_STORAGE = (columnConfigKey, defaults) => {
	try {
		const fullConfig = JSON.parse(localStorage.getItem(CONFIG_KEY));
		if (!!fullConfig && !!fullConfig[columnConfigKey]) {
			return fullConfig[columnConfigKey];
		}
	} catch (e) {
	}

	return defaults.map((value, index) => ({name: value, seq: index}));
}

export const COLUMN_CONFIG_SAVE_LOCAL_STORAGE = (columnConfiguration, columnConfigKey) => {
	const fullConfig = JSON.parse(localStorage.getItem(CONFIG_KEY)) || {};
	fullConfig[columnConfigKey] = columnConfiguration;
	localStorage.setItem(CONFIG_KEY, JSON.stringify(fullConfig));
	return columnConfiguration;
}

