import React, {Component} from "react";
import {connect} from "react-redux";
import {formatInTimeZone} from "date-fns-tz";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {withTranslation} from "react-i18next";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS
} from "../common/DataGridColumUtil";

const COLUMN_CONFIG_KEY = 'company-action-log';

class CompanyActionLogsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'performedAt', sort: 'desc'}],
			// columns
			columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['performedAt', 'actorFullName', 'type']),
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo?.defaultRowCount)
		}, () => this.onCompanyFetchActionLogs());
	}

	render() {
		const columnConfiguration = this.state.columnConfiguration;

		const allPossibleDataGridColumns = [
			{
				field: 'performedAt',
				headerName: this.props.t('company.actionLogPerformedAt'),
				editable: false,
				optional: false,
				...COLUMN_WIDTH(columnConfiguration, 'performedAt', {flex: 1}),
				valueGetter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'actorFullName',
				headerName: this.props.t('company.actionLogActorFullName'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'actorFullName', {flex: 1}),
			},
			{
				field: 'type',
				headerName: this.props.t('company.actionLogEvent'),
				editable: false,
				optional: false,
				...COLUMN_WIDTH(columnConfiguration, 'type', {flex: 3}),
				renderCell: (cellValues) => this.props.t('company.actionLog_' + cellValues.row.type).replace(/\{[0-9]+\}/g, (p) => {
					const index = parseInt(p.substring(1, p.length - 1));
					return cellValues.row.data[index] || '?';
				})
			},
		];

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		return <DataGrid
			autoHeight
			disableColumnSelector
			disableColumnFilter
			disableColumnPinning
			columns={filteredColumns}
			onColumnResize={this.onResizeColumn}
			onColumnOrderChange={this.onColumnOrderChange}
			slots={{
				toolbar: FlexibleToolbar,
			}}
			slotProps={{
				toolbar: {
					filterId: 'input-company-action-logs-search-text',
					filterValue: this.state.filterValue,
					onChangeFilterValue: this.onFilterValueChange,
					possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
					columns: filteredColumns.map(column => column.field),
					onChangeColumns: this.onChangeExtraColumns,
					onResetColumnsWidth: this.onResetColumnsWidth,
				}
			}}

			loading={this.props.documentBusy}

			pagination
			paginationMode="server"
			paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
			onPaginationModelChange={this.onPaginationModelChange}
			pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

			sortingMode="server"
			sortModel={this.state.sortModel}
			onSortModelChange={this.onSortModelChange}

			disableRowSelectionOnClick

			rows={this.props.companyActionLogList}
			rowCount={this.props.companyActionLogCount}
			density="compact"/>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanyFetchActionLogs)
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchActionLogs)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchActionLogs);
	}

	onCompanyFetchActionLogs = () => {
		this.props.onCompanyFetchActionLogs({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		});
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyActionLogList: state.company.actionLogList,
			companyActionLogCount: state.company.actionLogCount,
		}
	},
	dispatch => {
		return {
			onCompanyFetchActionLogs: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_ACTION_LOGS',
					request
				})
			}
		}
	}
)(CompanyActionLogsComponent));
