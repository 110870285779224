import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import {Alert, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, LinearProgress, MenuItem, Paper, Select, TextField, Typography} from "@mui/material";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import AppContainer from "../common/AppContainer";
import ServerErrorComponent from "../common/ServerErrorComponent";
import UploadBox from "../common/UploadBox";
import {withRouter} from "../common/RouterHelper";

const NameCellEditor = (props) => {
	const [name, setName] = useState(props.name);
	const [editing, setEditing] = useState(false);
	const id = props.id;
	const originalName = props.name;

	const onEdit = () => setEditing(true);
	const onChangeName = (e) => setName(e.target.value);
	const onSave = () => {
		props.onChangeNameHandler(id, name);
		setEditing(false);
	}
	const onRevert = () => {
		setName(originalName);
		setEditing(false);
	}

	return !editing ?
		<Box sx={{display: 'flex', alignItems: 'center'}}>
			<Box>{name}</Box>
			<IconButton
				variant="contained"
				color="primary"
				onClick={onEdit}
				sx={{ml: '1px'}}
			>
				<EditIcon fontSize="small"/>
			</IconButton>
		</Box>
		:
		<TextField
			variant="standard"
			fullWidth
			value={name}
			onChange={onChangeName}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSave();
					e.target.blur();
				} else if (e.key === 'Escape') {
					onRevert();
					e.target.blur();
				} else {
					e.stopPropagation()
				}
			}}
			onFocus={(e) => e.target.select()}
			sx={{border: 0}}
			inputProps={{maxLength: 251}}
			InputProps={{
				disableUnderline: true,
				endAdornment: <InputAdornment position="end">
					<IconButton
						variant="contained"
						color="primary"
						onClick={onSave}>
						<SaveIcon fontSize="small"/>
					</IconButton>
				</InputAdornment>
			}}
		/>
}

const SUPPORTED_ATTACHMENT_EXTENSIONS = ["xls", "xlsx", "xlsm", "xlsb"];

class DocumentUploadComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			items: [],
			collection: false,
			collectionName: '',
			collectionUploadDone: false,
			applyTemplate: false,

			selectedFolderId: this.determineInitialFolderId(),
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.selectedFolderId && !!this.props.documentOverviewInfo) {
			this.setState({
				selectedFolderId: this.determineInitialFolderId()
			})
		}
	}

	determineInitialFolderId = () => {
		return (this.props.documentOverviewInfo?.folders || [])
			.filter(folder => folder.userCanCreateAndEditDocuments)
			.map(folder => folder.id)
			.at(0);
	}

	render() {
		const newItemsCount = this.state.items.reduce((prev, item) => item.state === 'NEW' ? ++prev : prev, 0);
		const uploadingItemsCount = this.state.items.reduce((prev, item) => item.state === 'UPLOADING' ? ++prev : prev, 0);
		const uploadedItemsCount = this.state.items.reduce((prev, item) => item.state === 'UPLOADED' ? ++prev : prev, 0);
		const attachmentsCount = this.state.items.reduce((prev, item) => !!item.attachmentUsage ? ++prev : prev, 0);
		const pdfConvertableFileFormat = this.state.items.some(item => (item.name || '').endsWith('.doc') || (item.name || '').endsWith('.docx') || (item.name || '').endsWith('.ppt') || (item.name || '').endsWith('.pptx'));

		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('document.uploadItemName'),
				editable: false,
				width: 400,
				renderCell: (params) => (
					params.row.state === 'NEW' ? <NameCellEditor name={params.value} id={params.row.id}
																 onChangeNameHandler={this.onChangeDocumentName}/> :
						<span>{params.value}</span>
				),
			},
			{
				field: 'size',
				headerName: this.props.t('document.uploadItemSize'),
				editable: false,
				width: 200,
				valueGetter: (value) => {
					const b = value;
					if (b === 0) {
						return '0 bytes';
					} else {
						const p = Math.min(2, Math.floor(Math.log(b) / Math.log(1024)));
						return (b / Math.pow(1024, p)).toFixed(2) + ' ' + ['bytes', 'KB', 'MB'][p];
					}
				}
			},
			{
				field: 'state',
				headerName: this.props.t('document.uploadItemState'),
				editable: false,
				width: 200,
				valueGetter: (value) => {
					return this.props.t('document.uploadItemState_' + value).replace('{0}', (104857600 / 1024 / 1024) + 'MB');
				}
			},
			{
				field: 'details',
				headerName: this.props.t('document.uploadItemDetails'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				flex: 1,
				display: 'flex',
				renderCell: (cellValues) => {
					switch (cellValues.row.state) {
						case 'NEW':
							return '';
						case 'UPLOADING':
						case 'UPLOADED':
							return <LinearProgress variant="determinate" value={cellValues.row.progress}
												   sx={{width: '100%'}}/>
						case 'ERROR':
							return <span>{this.props.t('serverError.' + cellValues.row.error)}</span>
					}
				}
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: '',
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 50,
				renderCell: (cellValues) => {
					switch (cellValues.row.state) {
						case 'NEW':
							return <IconButton
								size="small"
								variant="contained"
								color="primary"
								title={this.props.t('document.uploadItemDelete')}
								onClick={() => this.onRemoveItem(cellValues.row)}>
								<DeleteIcon/>
							</IconButton>
						case 'UPLOADED':
							return cellValues.row.hasOwnProperty('id') ? <IconButton
								size="small"
								variant="contained"
								color="primary"
								title={this.props.t('document.uploadItemEdit')}
								onClick={() => this.onDocumentNavigateToEditor([cellValues.row.docId])}
								disabled={this.state.collection || uploadingItemsCount > 0}
							>
								<EditIcon/>
							</IconButton> : null
					}
				}
			},
		]

		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('document.uploadHeader')}</Typography>
				<ServerErrorComponent serverError={this.props.documentServerError}/>

				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('document.uploadFolder')}</Typography>
				<Box sx={{mt: 2}}>
					<FormControl size="small">
						<Select
							value={this.state.selectedFolderId || ''}
							onChange={this.onChangeSelectedFolderId}
							disabled={this.props.documentBusy}
							inputProps={{id: 'input-upload-folder-select'}}
						>
							{(this.props.documentOverviewInfo?.folders || [])
								.filter(folder => folder.userCanCreateAndEditDocuments)
								.map(folder =>
									<MenuItem key={folder.id} value={folder.id}>
										{folder.name}
									</MenuItem>
								)}
						</Select>
					</FormControl>
				</Box>

				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('document.uploadTemplate')}</Typography>
				<Box sx={{mt: 2}}>
					<FormControlLabel
						control={<Checkbox checked={this.state.applyTemplate}
										   onChange={this.onChangeApplyTemplate}
										   sx={{p: 0.5}}/>}
						label={this.props.t('document.uploadTemplateApply')}
						sx={{m: 0}}/>
				</Box>

				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('document.uploadFiles')}</Typography>
				<Box sx={{mt: 2}}>
					<Box sx={{display: 'flex'}}>
						<Button
							variant="contained"
							startIcon={<AddIcon/>}
							onClick={this.onSelectFiles}
							id="btn-upload-select"
						>
							{this.props.t('document.uploadSelectFiles')}
						</Button>
					</Box>

					<UploadBox
						onDragEnter={this.onStopDrag}
						onDragOver={this.onStopDrag}
						onDrop={this.onDrop}
						sx={{mt: 2}}>
						<CloudUploadIcon fontSize="large"/>
						<Typography>{this.props.t('document.uploadDragFilesHere')}</Typography>
					</UploadBox>

					<DataGrid
						autoHeight
						disableColumnSelector
						disableColumnFilter
						disableRowSelectionOnClick
						pagination

						initialState={{
							pagination: {
								paginationModel: {pageSize: 10, page: 0},
							},
						}}

						columns={dataGridColumns}
						rows={this.state.items}
						pageSizeOptions={[10]}
						density="compact"

						sx={{mt: 2}}
						localeText={{
							noRowsLabel: this.props.t('document.uploadNoFilesYet')
						}}
					/>

					{(!this.state.collection && !this.state.collectionUploadDone && uploadedItemsCount > 1) && <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
						<Button
							variant="contained"
							startIcon={<EditIcon/>}
							onClick={() => this.onDocumentNavigateToEditor(this.state.items.filter(item => item.state === 'UPLOADED').map(item => item.docId))}
							id="btn-document-edit"
							disabled={uploadingItemsCount > 0}
						>
							{this.props.t('document.editAll')}
						</Button>
					</Box>}

					{(this.state.collection && uploadedItemsCount > 1) && <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
						<Button
							variant="contained"
							startIcon={<EditIcon/>}
							onClick={this.onMakeCollection}
							id="btn-document-make-collection"
							disabled={uploadingItemsCount > 0}
						>
							{this.props.t('document.uploadGoToCollection')}
						</Button>
					</Box>}
				</Box>

				{pdfConvertableFileFormat && <Alert severity="info" sx={{mt: 1}}>
					{this.props.t('document.uploadFileConversionWarning')}
				</Alert>}

				<Box sx={{mt: 2, display: 'flex', alignItems: 'center', gap: 2}}>
					<Button
						variant="contained"
						startIcon={<FileUploadIcon/>}
						disabled={0 === newItemsCount || attachmentsCount > 0}
						onClick={this.onUploadIndividually}
						id="btn-upload"
					>
						{this.props.t('document.uploadNormal')}
					</Button>
					<Button
						variant="contained"
						startIcon={<FileUploadIcon/>}
						disabled={newItemsCount <= 1 || (attachmentsCount >= this.state.items.length)}
						onClick={this.onUploadCollection}
						id="btn-upload-collection"
					>
						{this.props.t('document.uploadAsCollection')}
					</Button>
					<TextField
						variant="outlined"
						label={this.props.t('document.uploadOptionalCollectionName')}
						value={this.state.collectionName}
						onChange={this.onChangeCollectionName}
						autoComplete="off"
						disabled={newItemsCount <= 1}
						size="small"
						sx={{flexGrow: 1}}
						id="input-upload-collection-name-text"
						inputProps={{maxLength: 255}}
					/>
				</Box>
			</Paper>

			<input
				type="file"
				id="file"
				multiple
				accept=" application/pdf,.doc,.docx,.ppt,.pptx,image/png,image/jpeg,image/tiff,image/bmp,image/gif,.xls*"
				style={{display: 'none'}}
				onChange={this.onChangeFiles}/>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		// chances are we already fetched it the document overview, no need to fetch again then
		if (!this.props.documentOverviewInfo) {
			this.props.onDocumentFetchOverviewInfo();
		}
	}

	onChangeSelectedFolderId = (e) => {
		this.setState({
			selectedFolderId: e.target.value
		});
	}

	onSelectFiles = (e) => {
		document.getElementById('file').click();
	}

	onStopDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
	}

	onDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();

		this.onAddFiles(Array.prototype.slice.call(e?.dataTransfer?.files || []));
	}

	onChangeFiles = (e) => {
		const el = document.getElementById('file');
		const files = Array.prototype.slice.call(el.files);
		// make the files on the element empty, so we can add the same file again
		el.value = '';
		this.onAddFiles(files);
	}

	hasAttachmentExtension = (fileName) => {
		const extension = fileName?.split('.').pop();
		return !!fileName && SUPPORTED_ATTACHMENT_EXTENSIONS.includes(extension);
	}

	onAddFiles = (files) => {
		const newItems = files.map(file => ({
			id: uuidv4(),
			file,
			name: file.name,
			size: file.size,
			attachmentUsage: this.hasAttachmentExtension(file.name) ? "COLLECTION" : null,
			state: file.size >= 104857600 ? 'SIZE_INVALID' : 'NEW',
			progress: 0
		}));

		const items = this.state.items.concat(newItems);
		this.setState({items});
	}

	onRemoveItem = (item) => {
		const items = this.state.items
			.filter(search => search.id !== item.id);
		this.setState({items});
	}

	onUploadIndividually = () => {
		this.setState({collection: false}, this.onUpload);
	}

	onUploadCollection = () => {
		this.setState({collection: true}, this.onUpload);
	}

	onUpload = () => {
		this.state.items
			.filter(item => item.state === 'NEW')
			.forEach(item => this.onUploadItem(item));
	}

	onUploadItem = (item) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				this.onChangeItem(item, {progress: Math.round((event.loaded * 100) / event.total)});
			}
		});

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (!xhr.responseText) {
						this.props.onSessionDestroy();
						return;
					}

					const preparedDocument = JSON.parse(xhr.responseText);
					if (!!preparedDocument.id && preparedDocument.id !== 0) {
						this.onChangeItem(item, {file: null, state: 'UPLOADED', docId: preparedDocument.id}, () => {
							if (this.state.collection) {
								if (this.state.items.every(item => item.state === 'UPLOADED')) {
									this.onMakeCollection();
								}
							} else if (this.state.items.length === 1) {
								this.onDocumentNavigateToEditor([preparedDocument.id]);
							}
						});
					} else {
						this.onChangeItem(item, {file: null, state: 'ERROR', error: preparedDocument.error});
					}
				}
			}
		};

		xhr.open('POST', '/api/internal/document/upload', true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		const formData = new FormData();
		formData.append('folderId', this.state.selectedFolderId);
		formData.append('files', item.file, encodeURIComponent(item.name));
		if (!!item.attachmentUsage) {
			formData.append('attachmentUsage', item.attachmentUsage);
		}

		this.onChangeItem(item, {file: null, state: 'UPLOADING'});

		xhr.send(formData);
	}

	onChangeItem = (item, updates, callback) => {
		const id = item.id;
		this.setState(prevState => {
			const items = prevState.items
				.map(prevItem => prevItem.id === id ? ({
					...prevItem,
					...updates
				}) : prevItem);
			return {items};
		}, callback);
	}

	onChangeDocumentName = (id, value) => {
		this.onChangeItem({id}, {name: value});
	}

	onChangeCollectionName = (e) => {
		this.setState({
			collectionName: e.target.value
		});
	}

	onChangeApplyTemplate = (e) => {
		this.setState({applyTemplate: e.target.checked})
	}

	onMakeCollection = () => {
		this.setState((prevState) => {
			if (prevState.collection) {
				const ids = prevState.items.filter(item => item.state === 'UPLOADED').map(item => item.docId);
				if (ids.length > 0) {
					this.props.onDocumentMakeCollection({
						name: this.state.collectionName,
						childIds: ids,
					}, this.state.applyTemplate);
				}
			}
			return {collection: false, collectionUploadDone: true};
		});
	}

	onDocumentNavigateToEditor = (ids) => {
		const location =
			(ids.length === 1 ? ('id=' + ids[0]) : ('ids=' + ids.join('-'))) +
			(this.state.applyTemplate ? ';template' : '');
		this.props.onEditorNavigate(location);
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			documentBusy: state.document.busy,
			documentServerError: state.document.serverError,
			documentOverviewInfo: state.document.overviewInfo,
		}
	},
	dispatch => {
		return {
			onDocumentFetchOverviewInfo: () => {
				dispatch({
					type: 'DOCUMENT_FETCH_OVERVIEW_INFO',
				});
			},
			onDocumentMakeCollection: (request, applyTemplate) => {
				dispatch({
					type: 'DOCUMENT_MAKE_COLLECTION',
					request,
					applyTemplate
				});
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			},
			onEditorNavigate: (location) => {
				dispatch({
					type: 'EDITOR_NAVIGATE',
					location,
					template: false
				});
			},
		}
	}
)(DocumentUploadComponent)));
