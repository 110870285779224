import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import AppContainer from "../common/AppContainer";
import ConfirmationDialog from "../common/ConfirmationDialog";
import UserGroupCreationDialog from "./UserGroupCreationDialog";
import UserGroupSettingsDialog from "./UserGroupSettingsDialog";
import UserGroupUsersDialog from "./UserGroupUsersDialog";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import UserImportLookupDialog from "../user/UserImportLookupDialog";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [{field: 'name', sort: 'asc'}],

	// dialogs
	creationDialogOpen: false,
	updateDialogOpen: false,
	usersDialogOpen: false,
	deleteDialogOpen: false,
	importLookupDialogOpen: false,

	// active row element
	activeUserGroupId: null
};

class UserGroupOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.userGroupActionSuccessfully && !prevProps.userGroupActionSuccessfully) {
			// if a new user group has just been created, automatically show the users dialog
			if (this.props.createdUserGroupId && !prevProps.createdUserGroupId) {
				this.onUserGroupFetchOverviewList();
				this.onUsersDialogOpen(this.props.createdUserGroupId);
			}
			else {
				this.onUserGroupFetchOverviewList();
			}
		}
	}

	render() {
		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('userGroup.name'),
				editable: false,
				flex: 1
			},
			{
				field: 'description',
				headerName: this.props.t('userGroup.description'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('userGroup.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 160,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('userGroup.settings')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.onUpdateDialogOpen(cellValues.row.id);
						}}>
						<SettingsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('userGroup.users')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.onUsersDialogOpen(cellValues.row.id, cellValues.row.name);
						}}>
						<ManageAccountsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('userGroup.import')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.onImportLookupDialogOpen(cellValues.row.id);
						}}>
						<GroupAddIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('userGroup.delete')}
						disabled={cellValues.row.defaultGroup}
						onClick={() => {
							this.onDeleteDialogOpen(cellValues.row.id);
						}}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</Fragment>)
			},
		];


		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('userGroup.header')}</Typography>
				{<ServerErrorComponent serverError={this.props.userGroupServerError} />}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.userGroupBusy}
							id="btn-usergroup-create"
					>
						{this.props.t('userGroup.create')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-user-group-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={this.props.userGroupBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableColumnFilter
					disableRowSelectionOnClick

					rows={this.props.userGroupOverviewList}
					rowCount={this.props.userGroupOverviewCount}
					density="compact"/>
			</Paper>

			<UserGroupCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onUserGroupCreate}/>

			<UserGroupSettingsDialog
				userGroupId={this.state.activeUserGroupId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onUserGroupUpdateSettings={this.onUserGroupUpdateSettings}/>

			<UserGroupUsersDialog
				userGroupId={this.state.activeUserGroupId}
				userGroupName={this.state.activeUserGroupName}
				open={this.state.usersDialogOpen}
				onClose={this.onUsersDialogClose}/>

			<ConfirmationDialog
				title={this.props.t('userGroup.delete')}
				confirm={this.props.t('userGroup.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onDeleteUserGroup}/>

			<UserImportLookupDialog
				title={this.props.t('userGroup.import')}
				open={this.state.importLookupDialogOpen}
				onClose={this.onImportLookupDialogClose}
				onSelect={this.onImportLookupSelect}
				canInviteUsers
			/>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, () => this.onUserGroupFetchOverviewList());
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onUserGroupCreate = (request) => {
		this.setState({creationDialogOpen: false}, () => this.props.onUserGroupCreate(request));
	}

	onUpdateDialogOpen = (activeUserGroupId) => {
		this.setState({updateDialogOpen: true, activeUserGroupId});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onUsersDialogOpen = (activeUserGroupId, activeUserGroupName) => {
		this.setState({usersDialogOpen: true, activeUserGroupId: activeUserGroupId, activeUserGroupName: activeUserGroupName});
	}

	onUsersDialogClose = () => {
		this.setState({usersDialogOpen: false});
	}

	onImportLookupDialogOpen = (activeUserGroupId) => {
		this.setState({importLookupDialogOpen: true, activeUserGroupId});
	}

	onImportLookupDialogClose = () => {
		this.setState({importLookupDialogOpen: false});
	}

	onImportLookupSelect = (users) => {
		// Keep dialog open to give stats
		this.props.onUserGroupAddUsers(this.state.activeUserGroupId, users.map(user => user.id));
	}

	onUserGroupUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onUserGroupUpdateSettings(this.state.activeUserGroupId, settings));
	}

	onDeleteDialogOpen = (activeUserGroupId) => {
		this.setState({deleteDialogOpen: true, activeUserGroupId});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onDeleteUserGroup = () => {
		const userGroupId = this.state.activeUserGroupId;
		this.setState({activeUserGroupId: null, deleteDialogOpen: false}, () => this.props.onUserGroupDelete(userGroupId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onUserGroupFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onUserGroupFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onUserGroupFetchOverviewList);
	}

	onUserGroupFetchOverviewList = () => {
		this.props.onUserGroupFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			userGroupBusy: state.userGroup.busy,
			userGroupServerError: state.userGroup.serverError,
			userGroupOverviewList: state.userGroup.overviewList,
			userGroupOverviewCount: state.userGroup.overviewCount,
			userGroupActionSuccessfully: state.userGroup.userGroupActionSuccessfully,
			createdUserGroupId: state.userGroup.createdUserGroupId,
		}
	},
	dispatch => {
		return {
			onUserGroupFetchOverviewList: (request) => {
				dispatch({
					type: 'USERGROUP_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onUserGroupCreate: (request) => {
				dispatch ({
					type: 'USERGROUP_CREATE',
					request
				})
			},
			onUserGroupDelete: (userGroupId) => {
				dispatch({
					type: 'USERGROUP_DELETE',
					userGroupId
				})
			},
			onUserGroupUpdateSettings: (userGroupId, settings) => {
				dispatch({
					type: 'USERGROUP_UPDATE_SETTINGS',
					userGroupId,
					settings
				})
			},
			onUserGroupAddUsers: (userGroupId, userIds) => {
				dispatch({
					type: 'USERGROUP_ADD_USERS',
					userGroupId,
					userIds
				});
			},
		}
	}
)(UserGroupOverviewComponent));