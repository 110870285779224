import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Avatar, Box, Checkbox, Chip, Fab, FormControlLabel, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditorAddUserDialog from "./EditorAddUserDialog";
import {DEFAULT_APPROVE_EMAIL_HEADERS} from "../common/EmailConstants";
import {APPROVAL_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS_ERROR, APPROVAL_MINIMAL_NUMBER_OF_APPROVALS_ERROR, HAS_BEEN_SIGNED} from "./EditorUtils";
import EditorAddSignerGroupDialog from "./EditorAddSignerGroupDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import SplitButton from "../common/SplitButton";
import EmailIcon from "@mui/icons-material/Email";
import {EditorEmailMessageDialog} from "./EditorEmailMessageDialog";
import UserImportLookupDialog from "../user/UserImportLookupDialog";
import {EditorMailMessageForUserDialog} from "./EditorEmailMessageForUserDialog";
import {withRouter} from "../common/RouterHelper";

class EditorApproversComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			approverAddDialogOpen: false,
			signerGroupAddDialogOpen: false,
			approverImportDialogOpen: false,
			emailMessageDialogOpen: false,
			selectedPersonForEmailMessage: null,
		}
	}

	render() {
		const document = this.props.document;
		const approvers = document?.approvers || [];
		const signed = HAS_BEEN_SIGNED(document) || document?.children?.some((child) => HAS_BEEN_SIGNED(child));
		const readOnly = document.readOnly || signed;
		const isTemplate = document.state === 'TEMPLATE';

		const defaultEmailHeader = (DEFAULT_APPROVE_EMAIL_HEADERS[this.state.emailLanguage] || '')
			.replace('{0}', this.props.sessionInfo?.userFullName)
			.replace('{1}', this.props.sessionInfo?.companyName)
		;
		const emailHeader = document.approversCustomEmailHeaders[this.state.emailLanguage] || defaultEmailHeader;

		const effectiveMinimalNumberOfApprovalDecisions =
			document.hasOwnProperty('minimalNumberOfApprovalDecisions') ? document.minimalNumberOfApprovalDecisions : approvers.length;
		const effectiveMinimalNumberOfApprovals =
			document.hasOwnProperty('minimalNumberOfApprovals') ? document.minimalNumberOfApprovals :
				(!!document.minimalNumberOfApprovalDecisions ? document.minimalNumberOfApprovalDecisions : approvers.length);

		return <Box sx={{mt: 2}}>
			<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Box sx={{flexGrow: 1}}>
					<Typography sx={{fontWeight: 700}}>{this.props.t('editor.approvalList')}</Typography>
				</Box>
				<ToggleButtonGroup
					color="primary"
					value={document.approveOrderType || ''}
					exclusive
					size="small"
					onChange={this.onChangeApproveOrderType}
					disabled={readOnly}
				>
					{(['PARALLEL', 'SERIAL']).map(order =>
						<ToggleButton key={order} value={order}>
							{this.props.t('editor.approveOrder_' + order)}
						</ToggleButton>
					)}
				</ToggleButtonGroup>
				<SplitButton color="secondary" actions={[
					{
						title: this.props.t('editor.approvalAddApprover'),
						action: this.onOpenApproverDialog,
						disabled: readOnly,
						icon: <AddIcon/>,
						id: "btn-editor-approvers-add"
					},
					{
						title: this.props.t('editor.approvalAddSignerGroup'),
						action: this.onOpenSignerGroupDialog,
						disabled: readOnly,
						icon: <AddIcon/>,
						id: "btn-editor-approversgroups-add"
					},
					...(this.props.editorEditInfo.canImportUsers ? [{
						title: this.props.t('editor.approvalImportApprovers'),
						action: this.onOpenImportDialog,
						disabled: readOnly,
						icon: <FontAwesomeIcon icon={faFileCsv}/>,
						id: "btn-editor-approvers-imports"
					}] : [])
					]}/>
				<Fab size="small"
					 color="secondary"
					 title={this.props.t('editor.emailMessageHeaderApprovers')}
					 onClick={this.onOpenEmailMessageDialog}
					 disabled={readOnly}
				>
					<EmailIcon fontSize="small"/>
				</Fab>
			</Box>

			{approvers.length === 0 && <Alert severity="info" sx={{mt: 1}}>{this.props.t('editor.approvalListEmpty')}</Alert>}

			<Box sx={{mt: 1, display: 'flex', flexDirection: 'column', flexShrink: 0}}>
				{approvers.map((approver, index) =>
					<Box key={approver.id} sx={{display: 'flex', alignItems: 'center', p: 1}}>
						<Avatar sx={{bgcolor: "lightgrey"}}>
							{document.approveOrderType === 'SERIAL' && (index + 1)}
							{document.approveOrderType === 'PARALLEL' && <PersonIcon fontSize="large"/>}
						</Avatar>

						<Box sx={{flexGrow: 1, ml: 1, minWidth: 0}}>
							<Box sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1}}
								title={approver.person.firstName + ' ' + approver.person.lastName}>{approver.person.firstName + ' ' + approver.person.lastName}</Box>
							<Typography variant="body2" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{approver.person.email}</Typography>
						</Box>

						{!isTemplate && <Box
							sx={{
								pl: 2,
								pr: 2,
								display: 'flex',
								alignItems: 'end',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Chip
								label={<Typography variant="body2">
									{this.props.t('approval.approvalRequestState_' + (approver.approvalRequestState || 'NEW'))}
								</Typography>}
								size="small"/>
							{readOnly && (approver.approvalRequestState === 'DECLINED') && <Typography variant="body2">
								{this.props.t('editor.approvalDeclineReason') + ': ' + approver.declineReason}
							</Typography>}
						</Box>}

						<Box
							sx={{
								pl: 2,
								pr: 2,
								borderLeft: '1px solid lightgrey',
								borderRight: '1px solid lightgrey',
								ml: 1,
								mr: 1,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center'
							}}
						>
							<Typography variant="body2">{this.props.t('editor.approvalSeal')}</Typography>
							{document.approveOrderType === 'PARALLEL' && <FormControlLabel
								sx={{marginLeft: '-4px'}}
								control={<Checkbox
									size="small"
									checked={approver.required}
									disabled={readOnly || approver.approved}
									onChange={(e) => this.onChangeApproverRequired(approver.id, e.target.checked)}
									sx={{padding: '1px'}}
								/>}
								label={<Typography variant="body2">
									{this.props.t('editor.approvalRequired')}
								</Typography>}
							/>}
						</Box>

						{document.approveOrderType === 'SERIAL' && <>
							<IconButton
								variant="contained"
								color="primary"
								title={this.props.t('editor.orderUp')}
								onClick={() => this.onApproverOrderUp(approver.id)}
								disabled={index <= 0 || readOnly || approver.approved}
							>
								<ArrowUpwardIcon/>
							</IconButton>
							<IconButton
								variant="contained"
								color="primary"
								title={this.props.t('editor.orderUp')}
								onClick={() => this.onApproverOrderDown(approver.id)}
								disabled={index >= approvers.length - 1 || readOnly || approver.approved}
							>
								<ArrowDownwardIcon/>
							</IconButton>
						</>}
						<IconButton size="small"
									color="secondary"
									title={this.props.t('editor.emailMessageForUserHeaderApprovers')}
									onClick={() => this.onOpenEmailMessageForUserDialog(approver.person)}
									disabled={readOnly}
						>
							<EmailIcon/>
						</IconButton>
						<IconButton color="primary"
									title={this.props.t('editor.approvalDelete')}
									onClick={() => this.onApproverDelete(approver.id)}
									disabled={readOnly || approver.approved}
						>
							<DeleteIcon/>
						</IconButton>
					</Box>
				)}



				{approvers.length > 0 && <Fragment>
					<Typography sx={{
						fontWeight: 700,
						mt: 2,
						mb: 1
					}}>{this.props.t('editor.approvalNumberApproversToDecide')}</Typography>
					{document.approveOrderType === 'PARALLEL' && <Fragment>
						<TextField
							value={effectiveMinimalNumberOfApprovalDecisions}
							onChange={this.onChangeMinimalNumberOfApprovalDecisions}
							autoComplete="off"
							size="small"
							fullWidth
							type="number"
							disabled={readOnly}
						/>
						{APPROVAL_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS_ERROR(document) && <Alert severity="warning"
																								 sx={{mt: 1}}>{this.props.t('editor.approvalNumberApproversToDecideError')}</Alert>}
					</Fragment>}
					{document.approveOrderType === 'SERIAL' &&
						<Alert severity="info">{this.props.t('editor.approvalNumberSerial')}</Alert>}

					<Typography sx={{
						fontWeight: 700,
						mt: 2,
						mb: 1
					}}>{this.props.t('editor.approvalNumberApproversToApprove')}</Typography>
					{document.approveOrderType === 'PARALLEL' && <Fragment>
						<TextField
							value={effectiveMinimalNumberOfApprovals}
							onChange={this.onChangeMinimalNumberOfApprovals}
							autoComplete="off"
							size="small"
							fullWidth
							type="number"
							disabled={readOnly}
						/>
						{APPROVAL_MINIMAL_NUMBER_OF_APPROVALS_ERROR(document) && <Alert severity="warning"
																						sx={{mt: 1}}>{this.props.t('editor.approvalNumberApproversToApproveError')}</Alert>}
					</Fragment>}
					{document.approveOrderType === 'SERIAL' &&
						<Alert severity="info">{this.props.t('editor.approvalNumberSerial')}</Alert>}
				</Fragment>}
			</Box>

			<EditorAddUserDialog
				title={this.props.t('editor.approvalAddApprover')}
				documentId={document.id}
				folderId={document.folderId}
				open={this.state.approverAddDialogOpen}
				onClose={this.onCloseApproverDialog}
				onSelect={this.onSelectUsers}
			/>

			<EditorAddSignerGroupDialog
				title={this.props.t('editor.approvalAddSignerGroup')}
				folderId={document.folderId}
				open={this.state.signerGroupAddDialogOpen}
				onClose={this.onCloseSignerGroupDialog}
				onSelect={this.onSelectUsers}
			/>

			<UserImportLookupDialog
				title={this.props.t('editor.approvalImportApprovers')}
				open={this.state.approverImportDialogOpen}
				onClose={this.onCloseImportDialog}
				onSelect={this.onSelectUsers}
				canInviteUsers={this.props.editorEditInfo?.canInviteGuests || this.props.editorEditInfo?.canInviteRegisteredUsers}
				allowContacts
				folderId={document.folderId}
			/>

			<EditorEmailMessageDialog
				open={!!this.state.emailMessageDialogOpen}
				onClose={this.onCloseEmailMessageDialog}
				onUpdate={this.onUpdateEmailMessageDialog}
				forSigning={false}
				sessionInfo={this.props.sessionInfo}
				customEmailHeaders={document.approversCustomEmailHeaders}
				collection={document.documentCollection || document.childType === 'COLLECTION'}
			/>

			<EditorMailMessageForUserDialog
				open={!!this.state.selectedPersonForEmailMessage}
				onClose={this.onCloseEmailMessageForUserDialog}
				onUpdate={this.onUpdateEmailMessageForUser}
				sessionInfo={this.props.sessionInfo}
				customEmailHeaders={document.approversCustomEmailHeaders}
				customEmailHeaderForUser={document.approverCustomEmailHeadersForUser[this.state.selectedPersonForEmailMessage?.id]}
				user={this.state.selectedPersonForEmailMessage}
				collection={document.documentCollection || document.childType === 'COLLECTION'}
			/>
		</Box>
	}

	onChangeApproveOrderType = (e) => {
		this.props.onChangeApproveOrderType(e.target.value);
	}

	onChangeApproverRequired = (id, required) => {
		const approvers = this.props.document.approvers
			.map(approver => approver.id === id ? {...approver, required} : approver);
		this.props.onChangeApprovers(approvers);
	}

	onApproverOrderUp = (id) => {
		const approvers = [...this.props.document.approvers];
		for (let i = 0; i < approvers.length; i++) {
			if (approvers[i]?.id === id) {
				if (i >= 1) {
					const tmp = approvers[i];
					approvers[i] = approvers[i - 1];
					approvers[i - 1] = tmp;
				}
				break;
			}
		}
		this.props.onChangeApprovers(approvers);
	}

	onApproverOrderDown = (id) => {
		const approvers = [...this.props.document.approvers];
		for (let i = 0; i < approvers.length - 1; i++) {
			if (approvers[i]?.id === id) {
				const tmp = approvers[i];
				approvers[i] = approvers[i + 1];
				approvers[i + 1] = tmp;
				break;
			}
		}
		this.props.onChangeApprovers(approvers);
	}

	onApproverDelete = (id) => {
		const approvers = this.props.document.approvers
			.filter(approver => approver.id !== id);

		this.props.onChangeApprovers(approvers);
	}

	onOpenApproverDialog = () => {
		this.setState({approverAddDialogOpen: true});
	}

	onCloseApproverDialog = () => {
		this.setState({approverAddDialogOpen: false});
	}

	onOpenSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: true});
	}

	onCloseSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: false});
	}

	onOpenImportDialog = () => {
		this.setState({approverImportDialogOpen: true});
	}

	onCloseImportDialog = () => {
		this.setState({approverImportDialogOpen: false});
	}

	onSelectUsers = (usersToAdd, shouldClose) => {
		const approvers = this.props.document.approvers;

		// Only add approvers that are not already assigned to current document
		usersToAdd.filter(userToAdd => approvers.findIndex(approver => userToAdd.id === approver.id) === -1)
			.forEach(userToAdd => {
				approvers.push({
					id: userToAdd.id,
					person: {
						id: userToAdd.id,
						firstName: userToAdd.firstName,
						lastName: userToAdd.lastName,
						email: userToAdd.email,
						languageIsoCode: userToAdd.languageIsoCode,
					},
					required: true
				});
		});

		if (shouldClose) {
			this.setState({
					approverAddDialogOpen: false,
					signerGroupAddDialogOpen: false,
					approverImportDialogOpen: false
				},
				() => this.props.onChangeApprovers(approvers));
		} else {
			this.props.onChangeApprovers(approvers);
		}
	}

	onChangeMinimalNumberOfApprovalDecisions = (e) => {
		const value = e.target.value;
		const numberValue = parseInt(value);
		this.props.onChangeMinimalNumberOfApprovalDecisions((typeof numberValue === 'number' && !isNaN(numberValue)) ? numberValue : value);
	}

	onChangeMinimalNumberOfApprovals = (e) => {
		const value = e.target.value;
		const numberValue = parseInt(value);
		this.props.onChangeMinimalNumberOfApprovals((typeof numberValue === 'number' && !isNaN(numberValue)) ? numberValue : value);
	}

	onOpenEmailMessageDialog = () => {
		this.setState({ emailMessageDialogOpen: true });
	}

	onCloseEmailMessageDialog = () => {
		this.setState({ emailMessageDialogOpen: false });
	}

	onUpdateEmailMessageDialog = (headers) => {
		this.props.onChangeApproversCustomEmailHeaders(headers);
		this.setState({ emailMessageDialogOpen: false });
	}

	onOpenEmailMessageForUserDialog = (person) => {
		this.setState({selectedPersonForEmailMessage: person});
	}

	onCloseEmailMessageForUserDialog = () => {
		this.setState({selectedPersonForEmailMessage: null});
	}

	onUpdateEmailMessageForUser = (header) => {
		const approverCustomEmailHeadersForUser = {...this.props.document.approverCustomEmailHeadersForUser};
		const userId = this.state.selectedPersonForEmailMessage.id;
		if (!!header) {
			approverCustomEmailHeadersForUser[userId] = header;
		} else {
			delete approverCustomEmailHeadersForUser[userId];
		}

		this.setState({selectedPersonForEmailMessage: null}, () => this.props.onChangeApproverCustomEmailHeadersForUser(approverCustomEmailHeadersForUser));
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {}
	}
)(EditorApproversComponent)));
