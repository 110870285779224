import React, {Component, Fragment} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AppContainer from "../common/AppContainer";
import {Box, Button, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography} from "@mui/material";
import {DataGridPro as DataGrid, GridFooter, GridFooterContainer} from "@mui/x-data-grid-pro";
import {formatInTimeZone} from "date-fns-tz";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LockIcon from '@mui/icons-material/Lock';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import FlexibleToolbar from "../common/FlexibleToolbar";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {format, isValid} from "date-fns";
import DocumentCreateFromTemplateDialog from "./DocumentCreateFromTemplateDialog";
import DocumentReminderFeedbackComponent from "./DocumentReminderComponent";
import {
	COLUMN_CHANGE_ORDER,
	COLUMN_CONFIG_READ_LOCAL_STORAGE,
	COLUMN_CONFIG_RESEQUENCE,
	COLUMN_CONFIG_SAVE_LOCAL_STORAGE,
	COLUMN_EXTRA_CHANGE,
	COLUMN_WIDTH,
	COLUMN_WIDTH_RESET,
	COLUMN_WIDTH_UPDATE,
	FILTER_SORT_COLUMNS
} from "../common/DataGridColumUtil";
import {DEBOUNCE} from "../common/DebounceUtil";
import {withRouter} from "../common/RouterHelper";

const COLUMN_CONFIG_KEY = 'doc-general-overview';

const MAP_FILTERS = (fieldFilters) => {
	const formatDate = (date) => {
		if (isValid(date)) {
			return format(date, 'yyyy-LL-dd');
		} else {
			return undefined;
		}
	};
	return {
		nameFilter: fieldFilters.name,
		stateFilter: fieldFilters.state,
		creationDateFilter: formatDate(fieldFilters.createdAt),
		latestChangeDateFilter: formatDate(fieldFilters.changedAt),
		descriptionFilter: fieldFilters.description,
		folderIdFilter: fieldFilters.folderId,
		creatorNameFilter: fieldFilters.creatorName
	};
}

const FooterWithSelectAll = (props) => {
	const {t} = useTranslation();

	const replaceLink = (str, linkAction) => {
		return str.split('$').map((v, index) => {
			if (v.indexOf('link') === 0) {
				const elements = v.split(';');

				return <Link key={index} onClick={() => linkAction()}
							 sx={{
								 cursor: 'pointer',
								 textDecoration: 'none',
							 }}>
					{elements[1]}
				</Link>
			} else {
				return v;
			}
		})
	};

	return <GridFooterContainer>
			<Box sx={{mt: 1, ml: 1, pb: 1, textAlign: 'center'}}>
				{props.selectionCount > 0 && !props.showSelectAllNotice &&
					<Box>
						{props.selectionCount === 1 && <Typography sx={{fontSize: '0.875rem'}}>{t('document.selectionCountSingle')}</Typography>}
						{props.selectionCount > 1 && <Typography sx={{fontSize: '0.875rem'}}>{t('document.selectionCountMultiple').replace('{0}', props.selectionCount)}</Typography>}
					</Box>
				}
				{props.selectionCount > 0 && props.showSelectAllNotice && props.selectAllDocuments &&
					<Typography sx={{fontSize: '0.875rem'}}>
						{replaceLink(
							t('document.allSelected').replace('{0}', props.total),
							props.onClearSelection)}
					</Typography>
				}
				{props.selectionCount > 0 && props.showSelectAllNotice && !props.selectAllDocuments &&
					<Typography sx={{fontSize: '0.875rem'}}>{replaceLink(
						t('document.selectAll').replace('{0}', props.selectionCount).replace('{1}', props.total),
						props.onSelectAll)}
					</Typography>}
			</Box>

		<GridFooter sx={{
			border: 'none', // To delete double border.
		}}/>
	</GridFooterContainer>
}


class DocumentGeneralOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [],
			// selection
			selectedIds: [],
			// download menu
			downloadMenuAnchorEl: null,
			// delete dialog
			deleteDialogOpen: false,
			// columns
			columnConfiguration: COLUMN_CONFIG_READ_LOCAL_STORAGE(COLUMN_CONFIG_KEY, ['name', 'description', 'documentFolderName', 'state', 'creatorFullName', 'approvers', 'signers']),
			// field filters
			fieldFilters: {},
			showSelectAllNotice: false,
			selectAllDocuments: false,
			// create from template
			createFromTemplateDialogOpen: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.documentActionSuccessfully && !prevProps.documentActionSuccessfully) {
			this.onDocumentFetchGeneralOverviewList();
		} else if (this.state.selectAllDocuments && this.props.documentGeneralOverviewList.length !== this.state.selectedIds.length) {
			// ensure that when selectAllDocuments is set, the selectedIds resemble the current page's length (mismatch can occur when changing pageSizes)
			this.setState({selectedIds: this.props.documentGeneralOverviewList.map(doc => doc.id)});
		}
	}

	render() {
		const columnConfiguration = this.state.columnConfiguration;

		const allPossibleDataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('document.name'),
				editable: false,
				optional: false,
				...COLUMN_WIDTH(columnConfiguration, 'name', {flex: 1}),
				renderCell: (cellValues) => <Fragment>
					{cellValues.row.documentCollection && <FileCopyIcon fontSize="small" sx={{mr: 0.5}}/>}
					{cellValues.row.name}
					{cellValues.row.confidential && <LockIcon fontSize="small" sx={{ml: 0.5}}/>}
				</Fragment>
			},
			{
				field: 'createdAt',
				headerName: this.props.t('document.createdAt'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'createdAt', {flex: 1}),
				valueGetter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'changedAt',
				headerName: this.props.t('document.changedAt'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'changedAt', {flex: 1}),
				valueGetter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'description',
				headerName: this.props.t('document.description'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'description', {flex: 1}),
			},
			{
				field: 'documentFolderName',
				headerName: this.props.t('document.folderName'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'documentFolderName', {flex: 1}),
			},
			{
				field: 'state',
				headerName: this.props.t('document.status'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'state', {flex: 1}),
				valueGetter: (value) => this.props.t('document.status_' + value)
			},
			{
				field: 'creatorFullName',
				headerName: this.props.t('document.creator'),
				editable: false,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'creatorFullName', {flex: 1}),
			},
			{
				field: 'approvers',
				headerName: this.props.t('document.approvers'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'approvers', {flex: 1}),
				valueGetter: (value) => (value || []).join(', ')
			},
			{
				field: 'signers',
				headerName: this.props.t('document.signers'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				optional: true,
				...COLUMN_WIDTH(columnConfiguration, 'signers', {flex: 1}),
				valueGetter: (value) => (value || []).join(', ')
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('document.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				disableReorder: true,
				optional: false,
				resizable: false,
				width: 130,
				align: "right",
				renderCell: (cellValues) => {
					const state = cellValues.row.state;
					const canEdit = state === 'PREPARING' && cellValues.row.userCanEdit;
					const canView = cellValues.row.userCanView;
					const canDownload = cellValues.row.userCanDownload || cellValues.row.userCanDownloadEvidenceReport;
					const canSendReminders = cellValues.row.userCanEdit && (
						state === 'WAITING_FOR_SIGNATURES' ||
						state === 'WAITING_FOR_APPROVAL' ||
						state === 'WAITING_FOR_CHILD_DOCUMENTS');

					return <Fragment>
						{canDownload &&
							<IconButton
								variant="contained"
								color="primary"
								title={this.props.t('document.download')}
								onClick={() => this.props.onDocumentSingleDownloadArtifacts(cellValues.row.id, ['DOCUMENT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS'])}
							>
								<DownloadIcon fontSize="small"/>
							</IconButton>
						}
						{(canEdit || canView) &&
							<IconButton
								variant="contained"
								color="primary"
								title={canEdit ? this.props.t('document.edit') : this.props.t('document.view')}
								onClick={() => this.onDocumentNavigateToEditor([cellValues.row.id])}
							>
								{canEdit ? <EditIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
							</IconButton>
						}
						{canSendReminders && <IconButton
							variant="contained"
							color="primary"
							title={this.props.t('document.reminder')}
							onClick={() => this.props.onDocumentSendReminders(cellValues.row.id)}
						>
							<NotificationsIcon fontSize="small"/>
						</IconButton>}
					</Fragment>
				}
			},
		];

		const filteredColumns = FILTER_SORT_COLUMNS(allPossibleDataGridColumns, columnConfiguration);

		const canCreate = this.props.documentBusy || !this.props.documentOverviewInfo || !this.props.documentOverviewInfo.createPossible;
		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('document.generalOverviewHeader') + ' ' +
					this.props?.sessionInfo?.companyName}</Typography>
				<ServerErrorComponent serverError={this.props.documentServerError}/>
				<DocumentReminderFeedbackComponent reminderResult={this.props.documentReminderSentResult}/>
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onUploadDocument}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							id="btn-upload"
							disabled={canCreate}
					>
						{this.props.t('document.upload')}
					</Button>

					<Button variant="contained"
							onClick={this.onCreateFromTemplateDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							id="btn-upload-library"
							disabled={canCreate}
					>
						{this.props.t('document.createFromTemplate')}
					</Button>
				</Box>
				<Typography variant="body2"
							sx={{mt: 2, mb: 2}}>{this.props.t('document.downloadDisclaimer')}</Typography>
				<DataGrid
					autoHeight
					disableColumnSelector
					disableColumnFilter
					disableColumnPinning
					keepNonExistentRowsSelected
					checkboxSelectionVisibleOnly

					columns={filteredColumns}
					onColumnHeaderClick={this.onColumnHeaderClick}
					onColumnResize={this.onResizeColumn}
					onColumnOrderChange={this.onColumnOrderChange}
					slots={{
						toolbar: FlexibleToolbar,
						footer: FooterWithSelectAll
					}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-document-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,

							possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
							columns: filteredColumns.map(column => column.field),
							onChangeColumns: this.onChangeExtraColumns,
							onResetColumnsWidth: this.onResetColumnsWidth,
							fieldFilters: [{
								field: 'name',
								name: this.props.t('document.name'),
								mode: 'text',
							}, {
								field: 'createdAt',
								name: this.props.t('document.createdAt'),
								mode: 'date',
							}, {
								field: 'changedAt',
								name: this.props.t('document.changedAt'),
								mode: 'date',
							}, {
								field: 'description',
								name: this.props.t('document.description'),
								mode: 'text',
							}, {
								field: 'folderId',
								name: this.props.t('document.folderName'),
								mode: 'select',
								options: this.props.documentOverviewInfo?.folders?.map(folder => ({
									value: folder.id,
									name: folder.name
								})) || [],
							}, {
								field: 'state',
								name: this.props.t('document.status'),
								mode: 'select',
								options: this.props.documentOverviewInfo?.states?.map(s => ({
									value: s,
									name: this.props.t('document.status_' + s)
								})) || [],
							}, {
								field: 'creatorName',
								name: this.props.t('document.creator'),
								mode: 'text',
							}],
							fieldFiltersValues: this.state.fieldFilters,
							onChangeFieldFilter: this.onChangeFieldFilter
						},
						footer: {
							total: this.props.documentGeneralOverviewCount,
							selectionCount: this.state.selectedIds.length,
							showSelectAllNotice: this.state.showSelectAllNotice,
							selectAllDocuments: this.state.selectAllDocuments,
							onSelectAll: this.onSelectAll,
							onClearSelection: this.onClearSelection,
						}
					}}

					loading={this.props.documentBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableRowSelectionOnClick
					checkboxSelection
					onRowSelectionModelChange={this.onRowSelectionModelChange}
					rowSelectionModel={this.state.selectedIds}
					hideFooterSelectedRowCount

					rows={this.props.documentGeneralOverviewList}
					rowCount={this.props.documentGeneralOverviewCount}
					density="compact"/>

				<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1}}>
					<Button
						variant="contained"
						startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
						disabled={this.props.documentBusy}
						onClick={this.props.onDocumentExportGeneralOverview}
						id="btn-document-export"
					>
						{this.props.t('export')}
					</Button>
					<Button
						variant="contained"
						startIcon={<DeleteIcon/>}
						disabled={this.props.documentBusy || 0 === this.state.selectedIds.length}
						onClick={this.onDeleteDialogOpen}
						id="btn-document-delete"
					>
						{this.props.t('document.deleteDocuments') + ' (' + (this.state.selectAllDocuments ? this.props.documentGeneralOverviewCount : this.state.selectedIds.length) + ')'}
					</Button>
					<Button
						variant="contained"
						disabled={this.props.documentBusy || 0 === this.state.selectedIds.length}
						startIcon={<DownloadIcon/>}
						onClick={this.onDownloadMenuOpen}
						id="btn-document-download"
					>
						{this.props.t('document.download') + ' (' + (this.state.selectAllDocuments ? this.props.documentGeneralOverviewCount : this.state.selectedIds.length) + ')'}
					</Button>
					<Menu
						anchorEl={this.state.downloadMenuAnchorEl}
						open={!!this.state.downloadMenuAnchorEl}
						id="download-menu"
						onClose={this.onDownloadMenuClose}
						onClick={this.onDownloadMenuClose}
					>
						{[
							{
								label: this.props.t('document.downloadDocuments'),
								icon: <InsertDriveFileIcon fontSize="small"/>,
								types: ['DOCUMENT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS']
							},
							{
								label: this.props.t('document.downloadEvidenceReports'),
								icon: <AssignmentIcon fontSize="small"/>,
								types: ['EVIDENCE_REPORT']
							},
							{
								label: this.props.t('document.downloadAll'),
								icon: <FolderZipIcon fontSize="small"/>,
								types: ['DOCUMENT', 'EVIDENCE_REPORT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS']
							}
						].map((item, index) =>
							<MenuItem key={index}
									  onClick={() => this.onDocumentDownloadArtifacts(item.types)}
							>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText>{item.label}</ListItemText>
							</MenuItem>)
						}
					</Menu>
				</Box>
			</Paper>

			<ConfirmationDialog
				title={this.props.t('attention')}
				confirm={this.props.t('document.deleteDocumentsConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onDocumentDeleteDocuments}/>

			<DocumentCreateFromTemplateDialog
				open={this.state.createFromTemplateDialogOpen}
				onCreate={this.onCreateFromTemplate}
				onClose={this.onCreateFromTemplateDialogClosed}
			/>

		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.props.onDocumentFetchOverviewInfo();
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, this.onDocumentFetchGeneralOverviewList);
	}

	onUploadDocument = () => {
		this.props.router.navigate('/document/upload');
	}

	onCreateFromTemplateDialogOpen = () => {
		this.setState({createFromTemplateDialogOpen: true});
	}

	onCreateFromTemplateDialogClosed = () => {
		this.setState({createFromTemplateDialogOpen: false});
	}

	onCreateFromTemplate = (request) => {
		this.setState({createFromTemplateDialogOpen: false}, () => this.props.onDocumentCreateFromTemplate(request));
	}

	onFilterValueChange = (e) => {
		this.setState({
			filterValue: e.target.value,
			selectedIds: [],
			showSelectAllNotice: false,
			selectAllDocuments: false
		}, DEBOUNCE(this.onDocumentFetchGeneralOverviewList, 300))
	}

	onSelectAll = () => {
		this.setState({selectAllDocuments: true});
	}

	onClearSelection = () => {
		this.setState({selectAllDocuments: false}, () => this.onRowSelectionModelChange([]));
	}

	onColumnHeaderClick = (params, event) => {
		// capture datagrid header 'select all' event
		if (params.field === '__check__') {
			this.setState({showSelectAllNotice: event.target.checked})
		}
	}

	onChangeExtraColumns = (extraColumns) => {
		this.persistColumnConfiguration(COLUMN_EXTRA_CHANGE(this.state.columnConfiguration, extraColumns), true)
	}

	onResizeColumn = (params, e, details) => {
		this.persistColumnConfiguration(COLUMN_WIDTH_UPDATE(this.state.columnConfiguration, params.colDef.field, params.width), false)
	}

	onResetColumnsWidth = () => {
		this.persistColumnConfiguration(COLUMN_WIDTH_RESET(this.state.columnConfiguration), false);
	}

	onColumnOrderChange = (params, evt, details) => {
		this.persistColumnConfiguration(COLUMN_CHANGE_ORDER(this.state.columnConfiguration, params.column.field, params.oldIndex, params.targetIndex), false)
	}

	persistColumnConfiguration = (columnConfiguration, resequence) => {
		if (resequence) {
			columnConfiguration = COLUMN_CONFIG_RESEQUENCE(columnConfiguration)
		}

		this.setState({
			columnConfiguration: COLUMN_CONFIG_SAVE_LOCAL_STORAGE(columnConfiguration, COLUMN_CONFIG_KEY)
		});
	}

	onChangeFieldFilter = (field, value) => {
		const fieldFilters = {...this.state.fieldFilters};
		if (!value) {
			delete fieldFilters[field];
		} else {
			fieldFilters[field] = value;
		}
		this.setState({
			fieldFilters,
			selectedIds: [],
			showSelectAllNotice: false,
			selectAllDocuments: false
		}, this.onDocumentFetchGeneralOverviewList);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		const showNotice = this.state.showSelectAllNotice && this.state.selectAllDocuments;
		this.setState({page, pageSize, showSelectAllNotice: showNotice}, this.onDocumentFetchGeneralOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onDocumentFetchGeneralOverviewList);
	}

	onRowSelectionModelChange = (selectedIds) => {
		this.setState({selectedIds});

		if (this.state.showSelectAllNotice) {
			// if the 'select all' notice was shown and a document was unchecked -> hide the notice and no longer select all documents
			this.setState({showSelectAllNotice: false, selectAllDocuments: false})
		}
	}

	onDocumentFetchGeneralOverviewList = () => {
		this.props.onDocumentFetchGeneralOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			...MAP_FILTERS(this.state.fieldFilters),
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onDocumentDeleteDocuments = () => {
		if (this.state.selectAllDocuments) {
			const request = {
				filterValue: this.state.filterValue,
				...MAP_FILTERS(this.state.fieldFilters),
			};

			this.setState({
					deleteDialogOpen: false,
					selectedIds: [],
					showSelectAllNotice: false,
					selectAllDocuments: false
				},
				() => this.props.onDocumentDeleteAllDocuments(request));
		} else {
			const selectedIds = this.state.selectedIds;
			this.setState({
					deleteDialogOpen: false,
					selectedIds: [],
					showSelectAllNotice: false
				},
				() => this.props.onDocumentDeleteDocuments(selectedIds));
		}
	}

	onDownloadMenuOpen = (e) => {
		this.setState({downloadMenuAnchorEl: e.currentTarget});
	}

	onDownloadMenuClose = (e) => {
		this.setState({downloadMenuAnchorEl: null});
	}

	onDocumentNavigateToEditor = (ids) => {
		const location =
			'/editor/' +
			(ids.length === 1 ? ('id=' + ids[0]) : ('ids=' + ids.join('-')));
		this.props.router.navigate(location);
	}

	onDocumentDownloadArtifacts = (types) => {
		if (this.state.selectAllDocuments) {
			const request = {
				filterValue: this.state.filterValue,
				...MAP_FILTERS(this.state.fieldFilters),
			};
			this.props.onDocumentDownloadAllDocumentArtifacts(request, types);
		} else {
			this.props.onDocumentDownloadArtifacts(this.state.selectedIds, types);
		}
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			documentBusy: state.document.busy,
			documentServerError: state.document.serverError,
			documentOverviewInfo: state.document.overviewInfo,
			documentGeneralOverviewList: state.document.generalOverviewList,
			documentGeneralOverviewCount: state.document.generalOverviewCount,
			documentActionSuccessfully: state.document.documentActionSuccessfully,
			documentReminderSentResult: state.document.reminderSentResult,
		}
	},
	dispatch => {
		return {
			onDocumentFetchOverviewInfo: () => {
				dispatch({
					type: 'DOCUMENT_FETCH_OVERVIEW_INFO',
				});
			},
			onDocumentFetchGeneralOverviewList: (request) => {
				dispatch({
					type: 'DOCUMENT_FETCH_GENERAL_OVERVIEW_LIST',
					request
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
			onDocumentDownloadArtifacts: (ids, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ARTIFACTS',
					ids,
					types
				});
			},
			onDocumentDownloadAllDocumentArtifacts: (request, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ALL_DOCUMENT_ARTIFACTS',
					request,
					types
				});
			},
			onDocumentSendReminders: (id) => {
				dispatch({
					type: 'DOCUMENT_SEND_REMINDERS',
					id
				});
			},
			onDocumentDeleteDocuments: (ids) => {
				dispatch({
					type: 'DOCUMENT_DELETE_DOCUMENTS',
					ids
				});
			},
			onDocumentDeleteAllDocuments: (request) => {
				dispatch({
					type: 'DOCUMENT_DELETE_ALL_DOCUMENTS',
					request
				});
			},
			onDocumentExportGeneralOverview: () => {
				dispatch({
					type: 'DOCUMENT_EXPORT_GENERAL_OVERVIEW'
				});
			},
			onDocumentCreateFromTemplate: (request) => {
				dispatch({
					type: 'DOCUMENT_CREATE_FROM_TEMPLATE',
					request
				});
			}
		}
	}
)(DocumentGeneralOverviewComponent)));
