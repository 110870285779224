import React, {Component} from "react";
import {Badge, Box} from "@mui/material";
import {withRouter} from "./RouterHelper";

class DrawerMenuItem extends Component {

	render() {
		const route = this.props?.route;
		const selectionRoutes = this.props?.selectionRoutes;
		const pathname = this.props.router?.location?.pathname || '';
		const selected = (!!route && route === pathname) || (!!selectionRoutes && selectionRoutes.some(selectionRoute => pathname.startsWith(selectionRoute)));

		return <Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: 62,
				pt: 1.8,
				pb: 1.8,
				userSelect: 'none',
				cursor: 'pointer',
				...(selected && {
					backgroundColor: '#FFFFFF',
					color: '#EC571A'
				})
			}}
			id={this.props.id}
			onClick={this.props.onClick}
		>
			<Badge badgeContent={this.props.badge || 0} color="error">
				{this.props.icon}
			</Badge>
			<Box sx={{ fontSize: 11, textAlign: 'center' }}>{this.props.text}</Box>
		</Box>
	}

}

export default withRouter(DrawerMenuItem);
