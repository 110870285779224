import React, {Component, Fragment, useCallback, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import AppContainer from "../common/AppContainer";
import ConfirmationDialog from "../common/ConfirmationDialog";
import UserCreationDialog from "./UserCreationDialog";
import UserSettingsDialog from "./UserSettingsDialog";
import {Mail, PersonAdd} from "@mui/icons-material";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import UserImportDialog from "./UserImportDialog";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [],

	// dialogs
	creationDialogOpen: false,
	updateDialogOpen: false,
	updateDialogEnableWizard: false,
	removeMfaDialogOpen: false,
	deleteDialogOpen: false,
	guestUserRegisterDialogOpen: false,
	importDialogOpen: false,

	// active row element
	activeUserId: null
};

class UserOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.userActionSuccessfully && !prevProps.userActionSuccessfully) {
			if (this.props.createdUser && !prevProps.createdUser) {
				this.onUserFetchOverviewList();
				this.onUpdateDialogOpen(this.props.createdUser.id, true);
			}
			else {
				this.onUserFetchOverviewList();
			}
		}
	}

    render() {
		const canRegisterGuests = this.props.sessionInfo?.companyAdmin ||
								  this.props.sessionInfo?.companyUserManager ||
								  this.props.sessionInfo?.applicationAdmin;

		const dataGridColumns = [
			{
				field: 'firstName',
				headerName: this.props.t('user.firstName'),
				editable: false,
				flex: 1
			},
			{
				field: 'lastName',
				headerName: this.props.t('user.lastName'),
				editable: false,
				flex: 1
			},
			{
				field: 'memo',
				headerName: this.props.t('user.memo'),
				editable: false,
				flex: 1
			},
			{
				field: 'email',
				headerName: this.props.t('user.email'),
				editable: false,
				flex: 1
			},
			{
				field: 'state',
				headerName: this.props.t('user.state'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => this.props.t('user.state_' + cellValues.row.state, {folder: cellValues.row.contactFolderName})
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('user.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 130,
				align: 'right',
				renderCell: (cellValues) => (<>
					{canRegisterGuests && cellValues.row.state === 'GUEST' &&
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('user.registerGuest')}
							disabled={cellValues.row.state !== 'GUEST'}
							id={"btn-user-register-guest-" + cellValues.id}
							onClick={() => {
								this.setState({activeUserId: cellValues.row.id});
								this.onRegisterGuestUserDialogOpen();
							}}>
							<PersonAdd fontSize="small"/>
						</IconButton>
					}

					{canRegisterGuests && cellValues.row.state === 'INVITED' &&
						<IconButton
							variant="contained"
							color="primary"
							title={this.props.t('user.resendInvitation')}
							disabled={this.props.userBusy}
							onClick={() => {
								this.props.onResendInvitation(cellValues.row.id);
							}}>
							<Mail fontSize="small"/>
						</IconButton>
					}

					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('user.settings')}
						onClick={() => {
							this.onUpdateDialogOpen(cellValues.row.id);
						}}>
						<SettingsIcon fontSize="small"/>
					</IconButton>

					{cellValues.row.hasMfaEnabled && <IconButton
						variant="contained"
						color="primary"
						title={this.props.t('user.authenticatorRemoveTitle')}
						onClick={() => {
							this.onRemoveMfaDialogOpen(cellValues.row.id);
						}}>
						<PhonelinkOffIcon fontSize="small"/>
					</IconButton>}

					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('user.delete')}
						disabled={cellValues.row.defaultGroup}
						id={"btn-user-delete-" + cellValues.id}
						onClick={() => {
							this.setState({activeUserId: cellValues.row.id});
							this.onDeleteDialogOpen();
						}}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</>)
			},
		];


		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('user.header')}</Typography>
				{<ServerErrorComponent serverError={this.props.userServerError} />}
				<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
					<Button variant="contained"
							onClick={this.onImportDialogOpen}
							startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
							sx={{mr: 1}}
							disabled={this.props.userBusy}
							id="btn-user-import"
					>
						{this.props.t('user.import')}
					</Button>
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={this.props.userBusy}
							id="btn-user-create"
					>
						{this.props.t('user.create')}
					</Button>
				</Box>
				<DataGrid
					autoHeight
					disableColumnSelector
					columns={dataGridColumns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						toolbar: {
							filterId: 'input-user-overview-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					loading={this.props.userBusy}

					pagination
					paginationMode="server"
					paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					onPaginationModelChange={this.onPaginationModelChange}
					pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					sortingMode="server"
					sortModel={this.state.sortModel}
					onSortModelChange={this.onSortModelChange}

					disableColumnFilter
					disableRowSelectionOnClick

					rows={this.props.userOverviewList}
					rowCount={this.props.userOverviewCount}
					density="compact"/>

				<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
					<Button
						variant="contained"
						startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
						onClick={this.props.onUserExportOverview}
						id="btn-user-export"
					>
						{this.props.t('export')}
					</Button>
				</Box>
			</Paper>

			<UserCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onUserCreate}
				allowRegisteredUserCreation
				allowGuestCreation
			/>

			<UserImportDialog
				open={this.state.importDialogOpen}
				onClose={this.onImportDialogClose}
			/>

			<UserSettingsDialog
				userId={this.state.activeUserId}
				enableWizard={this.state.updateDialogEnableWizard}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onUserUpdateSettings={this.onUserUpdateSettings}
			/>

			<ConfirmationDialog
				title={this.props.t('user.authenticatorRemoveTitle')}
				confirm={this.props.t('user.authenticatorRemoveText')}
				open={this.state.removeMfaDialogOpen}
				onClose={this.onRemoveMfaDialogClose}
				onConfirm={this.onUserRemoveAuthenticatorMfa} />

			<ConfirmationDialog
				title={this.props.t('user.delete')}
				confirm={this.props.t('user.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onDeleteUser}
			/>

			<ConfirmationDialog
				title={this.props.t('user.registerGuest')}
				confirm={this.props.t('user.registerGuestConfirm')}
				open={this.state.guestUserRegisterDialogOpen}
				onClose={this.onGuestUserRegisterClose}
				onConfirm={this.onRegisterGuestUser}
			/>
		</AppContainer>
	}

	onSessionCreated = (sessionInfo) => {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(sessionInfo.defaultRowCount)
		}, () => this.onUserFetchOverviewList());
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onUserCreate = (request) => {
		this.setState({creationDialogOpen: false}, () => this.props.onUserCreate(request));
	}

	onUpdateDialogOpen = (userId, enableWizard) => {
		this.setState({updateDialogOpen: true, activeUserId: userId, updateDialogEnableWizard: !!enableWizard});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onUserUpdateSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onUserUpdateSettings(this.state.activeUserId, settings));
	}

	onRemoveMfaDialogOpen = (userId, enableWizard) => {
		this.setState({removeMfaDialogOpen: true, activeUserId: userId});
	}

	onRemoveMfaDialogClose = () => {
		this.setState({removeMfaDialogOpen: false});
	}

	onUserRemoveAuthenticatorMfa = () => {
		const userId = this.state.activeUserId;
		this.setState({removeMfaDialogOpen: false, activeUserId: null}, () => this.props.onUserRemoveMfa(userId, {method: 'AUTHENTICATOR'}));
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onDeleteUser = () => {
		const userId = this.state.activeUserId;
		this.setState({activeUserId: null, deleteDialogOpen: false}, () => this.props.onUserDelete(userId));
	}

	onRegisterGuestUserDialogOpen = () => {
		this.setState({guestUserRegisterDialogOpen: true});
	}

	onGuestUserRegisterClose = () => {
		this.setState({guestUserRegisterDialogOpen: false});
	}

	onRegisterGuestUser = () => {
		const userId = this.state.activeUserId;
		this.setState({activeUserId: null, guestUserRegisterDialogOpen: false}, () => this.props.onGuestUserRegister(userId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onUserFetchOverviewList)
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onUserFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onUserFetchOverviewList);
	}

	onUserFetchOverviewList = () => {
		this.props.onUserFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

	onImportDialogOpen = () => {
		this.setState({importDialogOpen: true});
	}

	onImportDialogClose = () => {
		this.setState({importDialogOpen: false});
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			userBusy: state.user.busy,
			userServerError: state.user.serverError,
			userOverviewList: state.user.overviewList,
			userOverviewCount: state.user.overviewCount,
			userActionSuccessfully: state.user.userActionSuccessfully,
			createdUser: state.user.createdUser,
		}
	},
	dispatch => {
		return {
			onUserFetchOverviewList: (request) => {
				dispatch({
					type: 'USER_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onUserCreate: (request) => {
				dispatch ({
					type: 'USER_CREATE',
					request
				})
			},
			onUserRemoveMfa: (userId, request) => {
				dispatch({
					type: 'USER_REMOVE_MFA',
					userId,
					request
				});
			},
			onUserDelete: (userId) => {
				dispatch({
					type: 'USER_DELETE',
					userId
				})
			},
			onUserUpdateSettings: (userId, settings) => {
				dispatch({
					type: 'USER_UPDATE_SETTINGS',
					userId,
					settings
				})
			},
			onGuestUserRegister: (userId) => {
				dispatch({
					type: 'USER_REGISTER_GUEST',
					userId,
				})
			},
			onUserExportOverview: () => {
				dispatch({
					type: 'USER_EXPORT_OVERVIEW'
				});
			},
			onResendInvitation: (userId) => {
				dispatch({
					type: 'USER_RESEND_INVITATION',
					userId,
				})
			}
		}
	}
)(UserOverviewComponent));
