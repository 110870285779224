import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Box, Button, TextField} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SessionThemedContainer from "./SessionThemedContainer";
import {withRouter} from "../common/RouterHelper";

class SessionResetPasswordComponent extends Component {

	constructor(props) {
		super(props);

		this.payload =  this.props.router.params.payload;

		this.state = {
			password: '',
			passwordConfirmation: '',
			passwordChangeError: false,
		}
	}

	componentDidMount() {
		this.props.onFetchCompanyInfo();
	}

	render() {
		return <SessionThemedContainer style={this.props.style} title={this.props.t('session.resetPassword')}>
			<Box component="form" noValidate onSubmit={this.onSubmit}>
				<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1}}>
					<TextField
						required
						id="password"
						label={this.props.t('user.password')}
						variant="outlined"
						type="password"
						value={this.state.password}
						error={this.state.passwordChangeError}
						helperText={this.state.passwordChangeErrorText}
						onChange={this.onPasswordChange}
						sx={{flex: 1, minWidth: '250px', mt: 0}}
					/>
					<TextField
						required
						id="password-confirmation"
						label={this.props.t('user.passwordConfirmation')}
						variant="outlined"
						type="password"
						value={this.state.passwordConfirmation}
						error={this.state.passwordChangeError}
						helperText={this.state.passwordChangeErrorText}
						onChange={this.onPasswordConfirmationChange}
						sx={{flex: 1, minWidth: '250px', mt: 0}}
					/>

					<Alert icon={false} severity="info" sx={{width: '100%'}}>
						{this.props.t('user.passwordRequirements')}
					</Alert>
				</Box>

				<Button type="submit" sx={{mt: 1, maxWidth: '670px', width: '100%'}}
						disabled={this.props.busy}
						variant="contained" color="primary"
				>{this.props.t('next')}</Button>
			</Box>

			{this.props.serverError &&
				<Box sx={{width: '100%'}}>
					<ServerErrorComponent serverError={this.props.t(this.props.serverError)}/>
				</Box>
			}

			{this.props.resetPasswordUpdateSuccess &&
				<Alert severity="success"
					   sx={{mt: 2}}>{this.props.t('session.resetPasswordUpdateSuccess')}</Alert>}
		</SessionThemedContainer>
	}

	onPasswordChange = (e) => {
		let passwordChangeError = this.state.passwordConfirmation !== e.target.value;
		this.setState({
			password: e.target.value,
			passwordChangeError: passwordChangeError,
			passwordChangeErrorText: passwordChangeError ? this.props.t('serverError.PASSWORD_MISMATCH') : ''
		});
	}

	onPasswordConfirmationChange = (e) => {
		let passwordChangeError = this.state.password !== e.target.value;
		this.setState({
			passwordConfirmation: e.target.value,
			passwordChangeError: passwordChangeError,
			passwordChangeErrorText: passwordChangeError ? this.props.t('serverError.PASSWORD_MISMATCH') : ''
		});
	}

	onSubmit = (event) => {
		event.preventDefault();
		if (!this.props.resetPasswordUpdateSuccess) {
			this.props.onResetPasswordUpdate(this.payload, this.state.password, this.state.passwordConfirmation);
		} else {
			this.props.router.navigate('/login');
		}
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			busy: state.session.busy,
			serverError: state.session.serverError,
			resetPasswordUpdateSuccess: state.session.resetPasswordRequestSuccess,
			style: state.session.loginInfo?.companyStyleSettings,
		}
	},
	dispatch => {
		return {
			onResetPasswordUpdate: (payload, password, passwordConfirmation) => {
				dispatch({
					type: 'SESSION_RESET_PASSWORD_UPDATE',
					payload,
					password,
					passwordConfirmation,
				});
			},
			onFetchCompanyInfo: () => {
				dispatch({
					type: 'SESSION_FETCH_COMPANY_INFO',
					currentHostName: window.location.hostname
				})
			},
		}
	}
)(SessionResetPasswordComponent)));
