import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {DEFAULT_APPROVE_COLLECTION_EMAIL_HEADERS, DEFAULT_APPROVE_EMAIL_HEADERS, DEFAULT_SIGN_COLLECTION_EMAIL_HEADERS, DEFAULT_SIGN_EMAIL_HEADERS} from "../common/EmailConstants";

export const EditorMailMessageForUserDialog = ({open, onClose, onUpdate, forSigning, sessionInfo, customEmailHeaders, customEmailHeaderForUser, user, collection}) => {

	const {t} = useTranslation();
	const [localEmailHeader, setLocalEmailHeader] = useState(customEmailHeaderForUser);

	// reset localEmailHeaders when opening/closing dialog
	useEffect(() => {
		if (!open) {
			setLocalEmailHeader(null);
		} else if (open) {
			setLocalEmailHeader(customEmailHeaderForUser);
		}
	}, [open, setLocalEmailHeader, customEmailHeaderForUser]);


	const defaultEmailHeader = useMemo(
		() => {
			if (!user) {
				return '';
			} else if (!!customEmailHeaders[user.languageIsoCode]) {
				return customEmailHeaders[user.languageIsoCode].replace('{0}', sessionInfo?.userFullName);
			} else if (forSigning) {
				return (collection ? DEFAULT_SIGN_COLLECTION_EMAIL_HEADERS : DEFAULT_SIGN_EMAIL_HEADERS)[user.languageIsoCode].replace('{0}', sessionInfo?.userFullName);
			} else {
				return (collection ? DEFAULT_APPROVE_COLLECTION_EMAIL_HEADERS : DEFAULT_APPROVE_EMAIL_HEADERS)[user.languageIsoCode].replace('{0}', sessionInfo?.userFullName);
			}
		},
		[forSigning, user, customEmailHeaders, collection]
	);

	const emailHeader = useMemo(
		() => localEmailHeader || defaultEmailHeader,
		[localEmailHeader, user, defaultEmailHeader]
	);

	const onChangeEmailHeader = useCallback((e) => {
		setLocalEmailHeader(e.target.value);
	}, [localEmailHeader, setLocalEmailHeader, user]);

	const onConfirm = useCallback(() => {
		onUpdate(localEmailHeader);
	}, [localEmailHeader]);

	const onReset = useCallback(() => {
		setLocalEmailHeader(undefined);
	}, [localEmailHeader, setLocalEmailHeader]);

	const onLocalClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}

	return <Dialog open={open} onClose={onLocalClose} fullWidth maxWidth="md">
		{user && <DialogTitle>
			{forSigning ?
				`${t('editor.emailMessageForUserHeaderSigners')} ${user.firstName} ${user.lastName} (${user.email})`
				:
				`${t('editor.emailMessageForUserHeaderApprovers')} ${user.firstName} ${user.lastName} (${user.email})`
			}
		</DialogTitle>}
		<DialogContent>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
				<Typography variant="body2">
					{forSigning ?
						t('editor.emailMessageForUserDescriptionSigners')
						:
						t('editor.emailMessageForUserDescriptionApprovers')
					}
				</Typography>
				<TextField
					multiline
					rows={5}
					value={emailHeader}
					onChange={onChangeEmailHeader}
					fullWidth
					size="small"
					sx={{mt: 2}}
					inputProps={{maxLength: 500, style: {fontFamily: 'monospace', fontSize: 'smaller'}}}
				/>
				<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Typography variant="body2">{emailHeader.length + "/500"}</Typography>
				</Box>
				<Box sx={{display: 'flex', gap: 1, alignItems: 'centered'}}>
					<Button variant="contained"
							onClick={onReset}
							color="secondary"
							size="small"
							editor="btn-editor-mail-reset"
					>
						{t('editor.emailMessageReset')}
					</Button>
				</Box>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button onClick={onLocalClose} id="btn-cancel">{t('back')}</Button>
			<Button onClick={onConfirm} variant="contained"
					id="btn-confirm">{t('confirm')}</Button>
		</DialogActions>
	</Dialog>

}