import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Button, IconButton, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import ServerErrorComponent from "../common/ServerErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyWebhookSettingsConfigCreateDialog from "./CompanyWebhookSettingsConfigCreateDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {formatInTimeZone} from "date-fns-tz";

class CompanyWebhookSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// dialogs
			createDialogOpen: false,
			deleteWebhookConfigId: null,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyWebhookConfigActionSuccessFully && !prevProps.companyWebhookConfigActionSuccessFully) {
			this.onCompanyWebhookConfigFetchOverview();
		}
	}

	componentDidMount() {
		this.onCompanyWebhookConfigFetchOverview();
	}

	render() {
		const busy = this.props.companyBusy;

		const dataGridColumns = [
			{
				field: 'description',
				headerName: this.props.t('company.webhookSettingsConfigDescription'),
				editable: false,
				flex: 1
			},
			{
				field: 'expiresAt',
				headerName: this.props.t('company.webhookSettingsConfigExpiry'),
				editable: false,
				flex: 1,
				valueFormatter: (value) => !!value ? formatInTimeZone(value, 'Europe/Brussels', 'dd-LL-yyyy') : '',
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 90,
				align: 'right',
				renderCell: (cellValues) => (<>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delete')}
						onClick={() => this.setState({deleteWebhookConfigId: cellValues.row.id})}
					>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</>)
			},
		];

		return <Box>
			<Typography variant="h6">{this.props.t('company.webhookSettingsHeader')}</Typography>
			<Typography>{this.props.t('company.webhookSettingsHeaderDescription')}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1, gap: 1}}>
				<Button
					variant="contained"
					onClick={this.onCreationDialogOpen}
					startIcon={<AddIcon/>}
					disabled={busy}
					id="btn-webhook-config-create"
				>
					{this.props.t('company.webhookSettingsConfigCreate')}
				</Button>
			</Box>
			<DataGrid
				autoHeight
				disableColumnSelector
				columns={dataGridColumns}

				loading={busy}

				disableColumnFilter
				disableRowSelectionOnClick

				rows={this.props.companyWebhookConfigList || []}
				rowCount={this.props.companyWebhookConfigList?.length || 0}
				density="compact"
			/>
			<CompanyWebhookSettingsConfigCreateDialog
				open={this.state.createDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onCreate}
			/>
			<ConfirmationDialog
				open={!!this.state.deleteWebhookConfigId}
				title={this.props.t('company.webhookSettingsConfigDelete')}
				confirm={this.props.t('company.webhookSettingsConfigDeleteConfirm')}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onCompanyWebhookConfigDelete}/>
		</Box>
	}

	onCreationDialogOpen = () => {
		this.setState({createDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({createDialogOpen: false});
	}

	onCreate = (request) => {
		this.setState({createDialogOpen: false}, () => this.props.onCompanyWebhookConfigCreate(request));
	}

	onDeleteDialogClose = () => {
		this.setState({deleteWebhookConfigId: null});
	}

	onCompanyWebhookConfigDelete = () => {
		const webhookConfigId = this.state.deleteWebhookConfigId;
		this.setState({deleteWebhookConfigId: null}, () => this.props.onCompanyWebhookConfigDelete(webhookConfigId));
	}

	onCompanyWebhookConfigFetchOverview = () => {
		this.props.onCompanyWebhookConfigFetchOverview();
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyWebhookConfigList: state.company.webhookConfigList,
			companyWebhookConfigActionSuccessFully: state.company.webhookConfigActionSuccessFully,
		}
	},
	dispatch => {
		return {
			onCompanyWebhookConfigFetchOverview: () => {
				dispatch({
					type: 'COMPANY_WEBHOOK_CONFIG_FETCH_OVERVIEW'
				});
			},
			onCompanyWebhookConfigCreate: (request) => {
				dispatch({
					type: 'COMPANY_WEBHOOK_CONFIG_CREATE',
					request
				});
			},
			onCompanyWebhookConfigDelete: (webhookConfigId) => {
				dispatch({
					type: 'COMPANY_WEBHOOK_CONFIG_DELETE',
					webhookConfigId
				});
			},
		}
	}
)(CompanyWebhookSettingsComponent));
