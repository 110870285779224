import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import UserProfileSettingsComponent from "./UserProfileSettingsComponent";
import UserProfileSecuritySettingsComponent from "./UserProfileSecuritySettingsComponent";

const TABS = ['PROFILE', 'SECURITY'];

class UserProfileComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: TABS.at(0),
			requestedTab: TABS.at(0),
			shouldSave: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// save action has completed (succeeded/failed)
		if ((prevProps.sessionPendingChanges && !this.props.sessionPendingChanges) ||
			(!prevProps.sessionServerError && !!this.props.sessionServerError)) {
			this.setState({shouldSave: false});
		}
	}

	render() {
		return <AppContainer
			needsSession
			onSessionCreated={this.onSessionCreated}
			hasPendingChanges={this.props.sessionPendingChanges}
			saveIsBusy={this.props.sessionBusy}
			saveHasError={!!this.props.sessionServerError}
			onSave={this.onSave}
			customNavigation={this.state.tab !== this.state.requestedTab}
			onCustomNavigationComplete={this.onCustomNavigationComplete}
			onCustomNavigationCanceled={this.onCustomNavigationCanceled}
		>

			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
					<Tab key="PROFILE" value="PROFILE" label={this.props.t('user.settingsTab_PROFILE')}></Tab>
					{!!this.props.sessionInfo && !this.props.sessionInfo.guestAccess && this.props.sessionInfo.userPersonalApiKeyAllowed &&
						<Tab key="SECURITY" value="SECURITY" label={this.props.t('user.settingsTab_SECURITY')}></Tab>}
				</Tabs>
				{this.renderCurrentTab()}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case 'PROFILE':
				return <UserProfileSettingsComponent shouldSave={this.state.shouldSave}/>;
			case 'SECURITY':
				return <UserProfileSecuritySettingsComponent/>;
		}
	}

	onSessionCreated = (sessionInfo) => {
		if (!!sessionInfo?.companyMfaNeedAction) {
			this.onChangeTab(null, TABS.at(1));
		}
	}

	onChangeTab = (e, requestedTab) => {
		this.setState({requestedTab});
	}

	onCustomNavigationComplete = () => {
		this.setState({tab: this.state.requestedTab});
	}

	onCustomNavigationCanceled = () => {
		this.setState({requestedTab: this.state.tab});
	}

	onSave = () => {
		this.setState({shouldSave: true});
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionServerError: state.session.serverError,
			sessionPendingChanges: state.session.pendingChanges,
			sessionBusy: state.session.busy,
		}
	},
	dispatch => {
		return {}
	}
)(UserProfileComponent));
