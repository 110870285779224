import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskIcon from "@mui/icons-material/Task";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachmentIcon from '@mui/icons-material/Attachment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {withRouter} from "../common/RouterHelper";

const ACCORDION_DEFAULT_EXPANDED = (window.innerWidth > 1600);
const MAX_DECLINE_REASON_LENGTH = 60;

class EditorOverviewComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1, mt: 2}}>
			{this.renderOverview(this.props.document)}
		</Box>
	}

	renderOverview = (document) => {
		if (document?.documentCollection) {
			return <Box sx={{flexGrow: 1, width: 600, maxWidth: 900}}>
				{this.renderDocuments()}
			</Box>
		} else {
			return <Box sx={{flexGrow: 1, width: 600, maxWidth: 900}}>
				{this.renderApprovers()}
				{this.renderSigners()}
				{this.renderMultisigners()}
			</Box>
		}
	}

	renderApprovers = () => {
		const approvers = (this.props.document?.approvers || []).filter(approver => approver.approvalRequestState === 'APPROVED' || approver.approvalRequestState === 'DECLINED');
		if (approvers.length === 0) {
			return null;
		}
		const summary = this.props.t('editor.overviewApprovers');
		const description = this.props.t('editor.overviewApproversDescription');
		const items = approvers.map(this.renderApprover);
		return this.renderSummary(summary, description, items);
	}

	renderSigners = () => {
		const signers = this.props.document?.signers || [];
		if (signers.length === 0) {
			return null;
		}
		const summary = this.props.t('editor.overviewSigners');
		const description = this.props.t('editor.overviewSignersDescription');
		const items = signers.map(this.renderSigner);
		return this.renderSummary(summary, description, items);
	}

	renderMultisigners = () => {
		const multiSigners = (this.props.document?.children || [])
			.filter(child => child.childType === 'MULTI_SIGN')
			.flatMap(child => child.signers);
		if (multiSigners.length === 0) {
			return null;
		}
		const summary = this.props.t('editor.overviewMultisigners');
		const description = this.props.t('editor.overviewMultisignersDescription');
		const items = multiSigners.map(this.renderSigner);
		return this.renderSummary(summary, description, items);
	}

	renderDocuments = () => {
		const childDocuments = this.props.document?.children || [];
		if (childDocuments.length === 0) {
			return null;
		}
		const summary = this.props.t('editor.overviewDocuments');
		const description = this.props.t('editor.overviewDocumentsDescription');
		const items = this.props.document?.children.map(this.renderDocument);
		return this.renderSummary(summary, description, items);
	}

	renderApprover = (approver) => {
		let icon = null;
		if ('APPROVED' === approver.approvalRequestState) {
			icon = <TaskIcon/>;
		} else if ('DECLINED' === approver.approvalRequestState) {
			icon = <ThumbDownAltIcon/>;
		}

		let text = this.props.t('approval.approvalRequestState_' + approver.approvalRequestState);
		if (approver.declineReason) {
			text += ": " + approver.declineReason;
		}

		return <ListItem disablePadding={true} key={approver.id}>
			<ListItemIcon sx={{minWidth: '35px'}}>{icon}</ListItemIcon>
			<ListItemText primary={approver.person.fullName} secondary={text}/>
		</ListItem>;
	}

	renderSigner = (signer) => {
		let icon = null;
		if ('SIGNED' === signer.signRequestState) {
			icon = <EditIcon/>;
		} else if ('DECLINED' === signer.signRequestState) {
			icon = <NotInterestedIcon/>;
		} else if ('CLOSED' === signer.signRequestState) {
			icon = <CloseIcon/>;
		} else if ('EXPIRED' === signer.signRequestState) {
			icon = <AccessTimeIcon/>;
		}

		let text = this.props.t('signing.signRequestState_' + signer.signRequestState);
		if (signer.signedWithConfig) {
			text += ' (' + signer.signedWithConfig.label + ')';
		} else if (signer.declineReason) {
			let declineReason = signer.declineReason
			if (declineReason.length > MAX_DECLINE_REASON_LENGTH) {
				declineReason = declineReason.substring(0, MAX_DECLINE_REASON_LENGTH - 3) + '...';
			}
			text += ': ' + declineReason;
		}

		return <ListItem disablePadding={true} key={signer.id} secondaryAction={signer.hasDeclineAttachment ?
			<IconButton>
				<AttachmentIcon onClick={this.props.onDocumentDownloadDeclineAttachment}/>
			</IconButton> : null
		}>
			<ListItemIcon sx={{minWidth: '35px'}}>{icon}</ListItemIcon>
			<ListItemText primary={signer.person.fullName} secondary={text}/>
		</ListItem>;
	}

	renderDocument = (document) => {
		const icon = <InsertDriveFileIcon/>;

		let text = this.props.t('document.status_' + document.state);

		return <ListItem disablePadding={true} key={document.id}>
			<ListItemIcon sx={{minWidth: '35px'}}>{icon}</ListItemIcon>
			<ListItemText primary={document.name} secondary={text}/>
		</ListItem>;
	}

	renderSummary = (summary, description, items) => {
		return <Accordion variant="outlined"
						  disableGutters={true}
						  defaultExpanded={ACCORDION_DEFAULT_EXPANDED}
						  sx={{
							  '&:before': {
								  display: 'none',
							  }
						  }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
				<Typography variant="h6">{summary}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 0}}>
				<Typography
					variant="body">{description}</Typography>
				<List dense={true}>
					{items}
				</List>
			</AccordionDetails>
		</Accordion>
	}

}

export default withRouter(withTranslation()(EditorOverviewComponent));
