const defaultState = {
	// general state
	busy: false,
	serverError: false,
	pendingChanges: false,

	// edit info
	editInfo: null,

	// possible approvers signers
	possibleUsersList: [],
	possibleUsersCount: 0,

	// action logs
	actionLogs: [],
};

const editor = (state = defaultState, action) => {
	switch (action.type) {
		case 'EDITOR_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'EDITOR_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'EDITOR_NAVIGATE':
			return {
				...state,
				editInfo: null,
			}

		case 'EDITOR_FETCH_EDIT_INFO':
			return {
				...state,
				busy: true,
				serverError: null,
				editInfo: null,
			}

		case 'EDITOR_FETCH_EDIT_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				pendingChanges: false,
				editInfo: action.data
			}

		case 'EDITOR_FETCH_POSSIBLE_USERS':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'EDITOR_FETCH_POSSIBLE_USERS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				possibleUsersList: action.list,
				possibleUsersCount: action.count,
			}

		case 'EDITOR_SAVE_DOCUMENT':
		case 'EDITOR_SEND_DOCUMENT':
		case 'EDITOR_CLOSE_DOCUMENT':
		case 'EDITOR_DELETE_DOCUMENT':
		case 'EDITOR_RESTART_DOCUMENT':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'EDITOR_SAVE_DOCUMENT_SUCCESS':
		case 'EDITOR_SEND_DOCUMENT_SUCCESS':
		case 'EDITOR_CLOSE_DOCUMENT_SUCCESS':
		case 'EDITOR_RESTART_DOCUMENT_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				pendingChanges: false,
				editInfo: {
					...state.editInfo,
					document: action.data
				}
			}

		case 'EDITOR_UPDATE_DOCUMENT':
			return {
				...state,
				editInfo: {
					...state.editInfo,
					document: action.document
				},
				pendingChanges: true
			}

		case 'EDITOR_CHANGE_DOCUMENT':
			return {
				...state,
				editInfo: {
					...state.editInfo,
					document: action.document
				},
			}

		case 'EDITOR_FETCH_ACTION_LOGS':
			return {
				...state,
				busy: true,
				serverError: null,
				actionLogs: []
			}

		case 'EDITOR_FETCH_ACTION_LOGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				actionLogs: action.data
			}

		default:
			return state;
	}
}

export default editor;
