import React, {Component, Fragment, useCallback, useState} from "react";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {useTranslation, withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import {DateTimePicker} from "@mui/x-date-pickers";
import {addWeeks, format, isValid, parseISO, startOfHour} from "date-fns";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ServerErrorComponent from "../common/ServerErrorComponent";
import UserNotificationSettingComponent from "./UserNotificationSettingComponent";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import {SIGNER_SMS_OTP_NUMBER_ERROR} from "../editor/EditorUtils";
import UserMetaAttributeSettingsComponent from "./UserMetaAttributeSettingsComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";

const WIZARD_PAGE_DISABLED = -1;
const WIZARD_PAGE_FOLDER_PRIVILEGES_ETC = 0;
const WIZARD_PAGE_SIGNING_STUFF = 1;
const WIZARD_PAGE_FIRST = WIZARD_PAGE_FOLDER_PRIVILEGES_ETC;
const WIZARD_PAGE_LAST = WIZARD_PAGE_SIGNING_STUFF;

const deriveState = (props) => ({
	wizardPage: props.enableWizard ? WIZARD_PAGE_FIRST : WIZARD_PAGE_DISABLED
});

const DATETIME_FORMAT = "yyyy-LL-dd'T'HH:mm";

const UserGeneralSettingsComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();

	const onChangeFirstName = useCallback((e) => {
		onUserChangeSetting('firstName', null, e.target.value);
	}, [onUserChangeSetting]);

	const onChangeLastName = useCallback((e) => {
		onUserChangeSetting('lastName', null, e.target.value);
	}, [onUserChangeSetting]);

	const onChangeMemo = useCallback((e) => {
		onUserChangeSetting('memo', null, e.target.value);
	}, [onUserChangeSetting]);

	const onChangeEmail = useCallback((e) => {
		onUserChangeSetting('email', null,e.target.value);
	}, [onUserChangeSetting]);

	const onChangeDefaultRemarkVisualSignature = useCallback((e) => {
		onUserChangeSetting('defaultRemarkVisualSignature', null, e.target.value);
	}, [onUserChangeSetting]);

	const onChangeLanguage = useCallback((e) => {
		onUserChangeSetting('languageIsoCode', null, e.target.value);
	}, [onUserChangeSetting]);

	const onChangePhoneNumber = useCallback((e) => {
		onUserChangeSetting('phoneNumber', null, e.target.value);
	}, [onUserChangeSetting]);

	return <Box id="input-user-settings-general-text-group">
		<TextField
			variant="outlined"
			label={t('user.firstName')}
			required
			value={settings.firstName}
			onChange={onChangeFirstName}
			inputProps={{maxLength: 255}}
			autoComplete="off"
			autoFocus
			sx={{mb: 2, pr: 1, width: 0.5}}
		/>

		<TextField
			variant="outlined"
			label={t('user.lastName')}
			required
			value={settings.lastName}
			onChange={onChangeLastName}
			inputProps={{maxLength: 255}}
			autoComplete="off"
			sx={{mb: 2, width: 0.5}}
		/>

		<TextField
			variant="outlined"
			label={t('user.email')}
			required
			value={settings.email}
			onChange={onChangeEmail}
			autoComplete="off"
			fullWidth
			sx={{mb: 2, pr: 1, width: 0.7}}
		/>

		<TextField
			variant="outlined"
			label={t('user.memo')}
			value={settings.memo || ''}
			onChange={onChangeMemo}
			inputProps={{maxLength: 255}}
			autoComplete="off"
			fullWidth
			sx={{mb: 2, width: 0.3}}
		/>

		<FormControl sx={{mb: 2, width: 0.3, pr: 1}}>
			<InputLabel>{t('user.settingsLanguage')}</InputLabel>
			<Select
				label={t('user.settingsLanguage')}
				value={settings.languageIsoCode}
				onChange={onChangeLanguage}
			>
				{['nl', 'fr', 'en'].map(value => <MenuItem key={value}
														   value={value}>{t('language.' + value)}</MenuItem>)}
			</Select>
		</FormControl>

		<TextField
			variant="outlined"
			label={t('user.defaultRemarkVisualSignature')}
			value={settings.defaultRemarkVisualSignature || ''}
			onChange={onChangeDefaultRemarkVisualSignature}
			inputProps={{maxLength: 255}}
			autoComplete="off"
			fullWidth
			sx={{ mb: 2, width: 0.7}}
		/>

		<TextField
			variant="outlined"
			label={t('user.phoneNumber')}
			value={settings.phoneNumber || ''}
			onChange={onChangePhoneNumber}
			inputProps={{maxLength: 255}}
			autoComplete="off"
			error={!!settings.phoneNumber?.trim() && SIGNER_SMS_OTP_NUMBER_ERROR(settings.phoneNumber)}
			sx={{mb: 2, width: 1}}
		/>
	</Box>;
}

const UserMemoSettingComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();

	const onChangeMemo = useCallback((e) => {
		onUserChangeSetting('memo', null, e.target.value);
	}, [onUserChangeSetting]);

	return <TextField
		variant="outlined"
		size="small"
		label={t('user.memo')}
		value={settings.memo || ''}
		onChange={onChangeMemo}
		inputProps={{maxLength: 255}}
		autoComplete="off"
		fullWidth
	/>;
}

const UserVisualSignatureComponent = ({settings, userId, onUserChangeVisualSignature}) => {

	const {t} = useTranslation();
	const [popupSignature, setPopupSignature] = useState(false);

	const onSignaturePopupOpen = useCallback(() => {
		setPopupSignature(true);
	}, []);

	const onSignaturePopupClose = useCallback(() => {
		setPopupSignature(false);
	}, []);

	const onUploadNewVisualSignature = useCallback(() => {
		const fileEl = document.getElementById("file");
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index1 = result.indexOf(';');
					const index2 = result.indexOf(',');
					if (index1 > 0 && index2 > index1) {
						onUserChangeVisualSignature(userId, result.substring(index2 + 1), result.substring(5, index1), file.size);
					}
				}
				reader.readAsDataURL(file);
			}
		};
		fileEl.click();
	}, [userId, onUserChangeVisualSignature]);

	const onClearVisualSignature = useCallback(() => {
		onUserChangeVisualSignature(userId, null, null, null);
	}, [userId, onUserChangeVisualSignature]);

	return <>
		<Typography>{t('user.settingsVisualSignatureDescription')}</Typography>
		{!settings.visualSignature &&
			<Button color="secondary"
					variant="contained"
					startIcon={<AddIcon/>}
					onClick={onUploadNewVisualSignature}
					id="btn-user-settings-signature-upload">
				{t('user.settingsVisualSignatureUpload')}
			</Button>
		}
		{!!settings.visualSignature &&
			<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
				<img
					style={{
						width: '80px',
						maxHeight: '80px',
						cursor: 'zoom-in',
						border: '1px solid black',
						borderRadius: 5
					}}
					src={'data:image/png;base64,' + settings.visualSignature}
					alt={t('user.visualSignature')}
					onClick={onSignaturePopupOpen}
				/>
				<IconButton
					variant="contained"
					color="primary"
					size="large"
					title={t('user.visualSignatureDelete')}
					onClick={onClearVisualSignature}>
					<DeleteIcon/>
				</IconButton>
			</Box>
		}
		<input type="file" id="file" accept=".png, .jpeg, .jpg" style={{display: 'none'}}></input>
		{popupSignature && <Modal
			open
			onClose={onSignaturePopupClose}
			style={{display:'flex',alignItems:'center',justifyContent:'center'}}
		>
			<img
				style={{
					maxWidth: '40%',
					border: '1px solid black',
					borderRadius: 5,
					backgroundColor: '#FFF'
				}}
				src={'data:image/png;base64,' + settings.visualSignature}
				alt={t('user.visualSignature')}
				onClick={onSignaturePopupClose}
			/>
		</Modal>}
	</>;
}

const UserOutOfOfficeSettingsComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();

	const onChangeOutOfOfficeStatus = useCallback((e) => {
		let outOfOfficeSettings;
		if ('CONFIGURED_ACTIVE' === e.target.value) {
			// initialize the dates
			let date = startOfHour(new Date());
			outOfOfficeSettings = {
				status: 'CONFIGURED_ACTIVE',
				fromTime: format(date, DATETIME_FORMAT),
				untilTime: format(addWeeks(date, 1), DATETIME_FORMAT),
			}
		} else {
			// clear the dates
			outOfOfficeSettings = {
				status: e.target.value,
				fromTime: null,
				untilTime: null
			}
		}
		onUserChangeSetting('outOfOfficeSettings', null, outOfOfficeSettings);
	}, [onUserChangeSetting]);

	const onChangeOutOfOfficeFromTime = useCallback((date) => {
		let formattedDate;
		if (!!date && isValid(date)) {
			formattedDate = format(date, DATETIME_FORMAT);
		}
		onUserChangeSetting('outOfOfficeSettings', 'fromTime', formattedDate);
	}, [onUserChangeSetting]);

	const onChangeOutOfOfficeUntilTime = useCallback((date) => {
		let formattedDate;
		if (!!date && isValid(date)) {
			formattedDate = format(date, DATETIME_FORMAT);
		}
		onUserChangeSetting('outOfOfficeSettings', 'untilTime', formattedDate);
	}, [onUserChangeSetting]);

	return <>
		<RadioGroup
			defaultValue="NOT_ACTIVE"
			name="out-of-office-radiobutton-group"
			onChange={onChangeOutOfOfficeStatus}
		>
			<FormControlLabel value="NOT_ACTIVE"
							  checked={'NOT_ACTIVE' === settings.outOfOfficeSettings.status}
							  control={<Radio/>}
							  label={t('user.outOfOfficeNotActive')}/>
			<FormControlLabel value="ALWAYS_ACTIVE"
							  checked={'ALWAYS_ACTIVE' === settings.outOfOfficeSettings.status}
							  control={<Radio/>}
							  label={t('user.outOfOfficeAlwaysActive')}/>
			<Box>
				<FormControlLabel value="CONFIGURED_ACTIVE"
								  checked={'CONFIGURED_ACTIVE' === settings.outOfOfficeSettings.status}
								  control={<Radio/>}
								  label={t('user.outOfOfficeConfiguredActive')}/>
				<DateTimePicker
					slotProps={{
						textField: {
							sx: {mr: 2},
							autoComplete: 'off',
							size: 'small',
							inputProps: {
								placeholder: t('locale.datetimeFormat'),
								id: 'input-user-settings-out-of-office-startdate-text'
							}
						}
					}}
					label={t('user.outOfOfficeConfiguredActiveFrom')}
					value={!!settings.outOfOfficeSettings.fromTime ? parseISO(settings.outOfOfficeSettings.fromTime) : null}
					onChange={onChangeOutOfOfficeFromTime}
					format={"dd/LL/yyyy HH:mm"}
					minutesStep={15}
					ampm={false}
					disabled={'CONFIGURED_ACTIVE' !== settings.outOfOfficeSettings.status}
				/>

				<DateTimePicker
					slotProps={{
						textField: {
							size: 'small',
							autoComplete: 'off',
							inputProps: {
								placeholder: t('locale.datetimeFormat'),
								id: 'input-user-settings-out-of-office-stopdate-text'
							}
						}
					}}
					label={t('user.outOfOfficeConfiguredActiveUntil')}
					value={settings.outOfOfficeSettings.untilTime ? parseISO(settings.outOfOfficeSettings.untilTime) : null}
					onChange={onChangeOutOfOfficeUntilTime}
					format={"dd/LL/yyyy HH:mm"}
					minutesStep={15}
					ampm={false}
					disabled={'CONFIGURED_ACTIVE' !== settings.outOfOfficeSettings.status}
				/>
			</Box>
		</RadioGroup>
	</>;
}

const UserCommunicationSettingsComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();

	const onChangeProfileEmailInvite = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.INVITE;
		} else {
			settings.userNotifications.map.INVITE = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailReminder = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.REMINDER;
		} else {
			settings.userNotifications.map.REMINDER = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailApproval = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.APPROVAL;
		} else {
			settings.userNotifications.map.APPROVAL = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailFullySigned = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.FULLY_SIGNED;
		} else {
			settings.userNotifications.map.FULLY_SIGNED = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailSignaturePlaced = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.SIGNATURE_PLACED;
		} else {
			settings.userNotifications.map.SIGNATURE_PLACED = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailClosed = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.CLOSED;
		} else {
			settings.userNotifications.map.CLOSED = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailExpired = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.EXPIRED;
		} else {
			settings.userNotifications.map.EXPIRED = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	const onChangeProfileEmailMessage = useCallback((value) => {
		if (!value) {
			delete settings.userNotifications.map.MESSAGE;
		} else {
			settings.userNotifications.map.MESSAGE = value;
		}
		onUserChangeSetting('userNotifications', null, settings.userNotifications);
	}, [settings, onUserChangeSetting]);

	return <>
		<Typography>{t('user.settingsCommunicationReceiveEmail')}</Typography>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.INVITE}
			onChange={onChangeProfileEmailInvite}
			label={t('user.settingsInviteUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.REMINDER}
			onChange={onChangeProfileEmailReminder}
			label={t('user.settingsReminderUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.APPROVAL}
			onChange={onChangeProfileEmailApproval}
			label={t('user.settingsApprovalUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.FULLY_SIGNED}
			onChange={onChangeProfileEmailFullySigned}
			label={t('user.settingsFullySignedUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.SIGNATURE_PLACED}
			onChange={onChangeProfileEmailSignaturePlaced}
			label={t('user.settingsSignatureUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.CLOSED}
			onChange={onChangeProfileEmailClosed}
			label={t('user.settingsClosedUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.EXPIRED}
			onChange={onChangeProfileEmailExpired}
			label={t('user.settingsExpiredUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
		<UserNotificationSettingComponent
			value={settings.userNotifications.map.MESSAGE}
			onChange={onChangeProfileEmailMessage}
			label={t('user.settingsMessageUserNotification')}
			sx={{m: 0, mb: 1, width: 0.33}}
		/>
	</>;
}

const UserPermissionSettingsComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();

	const onChangeAdmin = useCallback((e, value) => {
		onUserChangeSetting('admin', null, value);
	}, [onUserChangeSetting]);

	const onChangeUserManager = useCallback((e, value) => {
		// if the userManager is disabled, automatically disable outOfOfficeManager
		if (!value) {
			onUserChangeSetting('outOfOfficeManager', null, false);
		}
		onUserChangeSetting('userManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeUserGroupManager = useCallback((e, value) => {
		onUserChangeSetting('userGroupManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeSignerGroupManager = useCallback((e, value) => {
		onUserChangeSetting('signerGroupManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeDocumentFolderManager = useCallback((e, value) => {
		onUserChangeSetting('documentFolderManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeOutOfOfficeManager = useCallback((e, value) => {
		onUserChangeSetting('outOfOfficeManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeTemplateManager = useCallback((e, value) => {
		onUserChangeSetting('templateManager', null, value);
	}, [onUserChangeSetting]);

	const onChangeCanInviteRegisteredUsers = useCallback((e, value) => {
		onUserChangeSetting('canInviteRegisteredUsers', null, value);
	}, [onUserChangeSetting]);

	const onChangeCanInviteGuestUsers = useCallback((e, value) => {
		onUserChangeSetting('canInviteGuestUsers', null, value);
	}, [onUserChangeSetting]);

	const onChangeCanInviteContacts = useCallback((e, value) => {
		onUserChangeSetting('canInviteContacts', null, value);
	}, [onUserChangeSetting]);

	const onChangeActionLogViewer = useCallback((e, value) => {
		onUserChangeSetting('actionLogViewer', null, value);
	}, [onUserChangeSetting]);

	return <>
		{settings.guest && <Typography sx={{fontSize: 'smaller'}}>{t('user.settingsPermissionsGuestDisabled')}</Typography>}
		<Box>
			<Typography sx={{opacity: settings.guest ? 0.38 : 1}}>{t('user.settingsPermissionsUserIs')}</Typography>
			<FormControlLabel
				control={<Checkbox checked={settings.admin}
								   sx={{ p: 0.25 }}
								   onChange={onChangeAdmin}/>}
				label={t('user.settingsAdmin')}
				disabled={settings.guest}
				sx={{ m: 0, mb: 1, width: 0.33 }}
			/>
		</Box>
		<Box sx={{mb: 1}}>
			<Typography sx={{opacity: settings.guest ? 0.38 : 1}}>{t('user.settingsPermissionsUserCanManage')}</Typography>
			<Grid container>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.userManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeUserManager}/>}
						label={t('user.settingsUserManager')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, width: '100%'}}
					/>
					<FormControlLabel
						control={<Checkbox checked={settings.outOfOfficeManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeOutOfOfficeManager}/>}
						label={t('user.settingsOutOfOfficeManager')}
						disabled={settings.guest || !settings.userManager || settings.admin}
						sx={{ mb: 1, ml:2, width: '70%'}}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.userGroupManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeUserGroupManager}/>}
						label={t('user.settingsUserGroupManager')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, mb: 1}}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.documentFolderManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeDocumentFolderManager}/>}
						label={t('user.settingsDocumentFolderManager')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, mb: 1 }}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.signerGroupManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeSignerGroupManager}/>}
						label={t('user.settingsSignerGroupManager')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, mb: 1 }}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.templateManager || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeTemplateManager}/>}
						label={t('user.settingsTemplateManager')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, mb: 1 }}
					/>
				</Grid>
			</Grid>
		</Box>
		<Box sx={{mb: 1}}>
			<Typography sx={{opacity: settings.guest ? 0.38 : 1}}>{t('user.settingsPermissionsUserCanInvite')}</Typography>
			<Grid container>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.userManager || settings.admin || settings.canInviteRegisteredUsers}
										   sx={{ p: 0.25 }}
										   onChange={onChangeCanInviteRegisteredUsers}/>}
						label={t('user.settingsInviteRegisteredUsers')}
						disabled={settings.guest || settings.userManager || settings.admin}
						sx={{m: 0}}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.userManager || settings.admin || settings.canInviteGuestUsers}
										   sx={{ p: 0.25 }}
										   onChange={onChangeCanInviteGuestUsers}/>}
						label={t('user.settingsInviteGuestUsers')}
						disabled={settings.guest || settings.userManager || settings.admin}
						sx={{m: 0}}
					/>
				</Grid>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.userManager || settings.admin || settings.canInviteContacts}
										   sx={{ p: 0.25 }}
										   onChange={onChangeCanInviteContacts}/>}
						label={t('user.settingsInviteContacts')}
						disabled={settings.guest || settings.userManager || settings.admin}
						sx={{m: 0}}
					/>
				</Grid>
			</Grid>
		</Box>
		<Box sx={{mb: 1}}>
			<Typography sx={{opacity: settings.guest ? 0.38 : 1}}>{t('user.settingsPermissionsUserCanView')}</Typography>
			<Grid container>
				<Grid item sx={{width: 0.33}}>
					<FormControlLabel
						control={<Checkbox checked={settings.actionLogViewer || settings.admin}
										   sx={{ p: 0.25 }}
										   onChange={onChangeActionLogViewer}/>}
						label={t('user.settingsActionLogViewer')}
						disabled={settings.guest || settings.admin}
						sx={{ m: 0, mb: 1 }}
					/>
				</Grid>
			</Grid>
		</Box>
	</>;
}

const UserGroupsSettingsComponent = ({settings, onUserChangeSetting}) => {
	const {t} = useTranslation();
	const [filterValue, setFilterValue] = useState('');

	const onChangeFilterValue = useCallback((e) => setFilterValue(e.target.value), []);

	const onMemberChange = useCallback((groupId, member) => {
		const userGroupsWithMembership = settings.userGroupsWithMembership
			.map(group => group.id === groupId ? {
				...group,
				member,
				admin: (!member ? false : group.admin),
			} : group);
		onUserChangeSetting('userGroupsWithMembership', null, userGroupsWithMembership);
	}, [settings, onUserChangeSetting]);

	const onAdminChange = useCallback((groupId, admin) => {
		const userGroupsWithMembership = settings.userGroupsWithMembership
			.map(group => group.id === groupId ? {
				...group,
				member: (admin ? true : group.member),
				admin
			} : group);
		onUserChangeSetting('userGroupsWithMembership', null, userGroupsWithMembership);
	}, [settings, onUserChangeSetting]);

	const columns = [
		{
			field: 'name',
			headerName: t('user.settingsUserGroupName'),
			editable: false,
			flex: 1
		},
		{
			field: 'member',
			headerName: t('user.settingsUserGroupMember'),
			editable: false,
			width: 100,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.member}
					disabled={cellValues.row.admin}
					onChange={(e) => onMemberChange(cellValues.row.id, e.target.checked)}
				/>
		},
		{
			field: 'admin',
			headerName: t('user.settingsUserGroupAdmin'),
			editable: false,
			width: 100,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.admin}
					onChange={(e) => onAdminChange(cellValues.row.id, e.target.checked)}
				/>
		}
	];

	return <DataGrid
		autoHeight
		disableColumnSelector
		disableColumnFilter
		disableRowSelectionOnClick
		pagination

		initialState={{
			pagination: {
				paginationModel: { pageSize: 10, page: 0 },
			},
		}}

		slots={{toolbar: FlexibleToolbar}}
		slotProps={{
			toolbar: {
				filterId: 'input-user-settings-user-group-filter-value',
				filterValue,
				onChangeFilterValue,
			}
		}}

		columns={columns}

		rows={settings.userGroupsWithMembership
			.filter(group => group.name.toUpperCase().indexOf(filterValue.toUpperCase()) >= 0)}
		pageSizeOptions={[10]}
		density="compact"
	/>
}

const UserFolderSettingsComponent = ({settings, onUserChangeSetting}) => {

	const {t} = useTranslation();
	const [filterValue, setFilterValue] = useState('');

	const onChangeFilterValue = useCallback((e) => setFilterValue(e.target.value), []);

	const onFolderPermissionsChange = useCallback((folderId, permission, enabled) => {
		const permissions = (enabled && 'EDIT_DOCUMENTS' === permission) ?
			['EDIT_DOCUMENTS', 'VIEW_DOCUMENTS'] : [permission];
		const foldersWithPermissions = settings.foldersWithPermissions
			.map(folder => folder.id === folderId ? {
				...folder,
				permissions: folder.permissions
					.filter(p => permissions.indexOf(p) === -1)
					.concat(enabled ? permissions : [])
			} : folder);
		onUserChangeSetting('foldersWithPermissions', null, foldersWithPermissions);
	}, [settings, onUserChangeSetting]);

	const columns = [
		{
			field: 'name',
			headerName: t('user.settingsDocumentFolderName'),
			editable: false,
			flex: 1
		},
		{
			field: 'editFolder',
			headerName: t('folder.permissionsEditFolderPermission'),
			editable: false,
			width: 150,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.permissions.indexOf('EDIT_FOLDER') >= 0}
					onChange={(e) => onFolderPermissionsChange(cellValues.row.id, 'EDIT_FOLDER', e.target.checked)}
				/>
		},
		{
			field: 'editDocuments',
			headerName: t('folder.permissionsEditDocumentsPermission'),
			editable: false,
			width: 150,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
					onChange={(e) => onFolderPermissionsChange(cellValues.row.id, 'EDIT_DOCUMENTS', e.target.checked)}
				/>
		},
		{
			field: 'viewDocuments',
			headerName: t('folder.permissionsViewDocumentsPermission'),
			editable: false,
			width: 150,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.permissions.indexOf('VIEW_DOCUMENTS') >= 0}
					disabled={cellValues.row.permissions.indexOf('EDIT_DOCUMENTS') >= 0}
					onChange={(e) => onFolderPermissionsChange(cellValues.row.id, 'VIEW_DOCUMENTS', e.target.checked)}
				/>
		}
	];

	return <DataGrid
		autoHeight
		disableColumnSelector
		disableColumnFilter
		disableRowSelectionOnClick
		pagination

		initialState={{
			pagination: {
				paginationModel: { pageSize: 10, page: 0 },
			},
		}}

		slots={{toolbar: FlexibleToolbar}}
		slotProps={{
			toolbar: {
				filterId: 'input-user-settings-folder-permissions-filter-value',
				filterValue,
				onChangeFilterValue,
			}
		}}

		columns={columns}

		rows={settings.foldersWithPermissions
			.filter(folder => folder.name.toUpperCase().indexOf(filterValue.toUpperCase()) >= 0)}
		pageSizeOptions={[10]}
		density="compact"
	/>
}

const SignerGroupsSettingsComponent = ({settings, onUserChangeSetting}) => {
	const {t} = useTranslation();
	const [filterValue, setFilterValue] = useState('');

	const onChangeFilterValue = useCallback((e) => setFilterValue(e.target.value), []);

	const onMemberChange = useCallback((groupId, member) => {
		const signerGroupsWithMembership = settings.signerGroupsWithMembership
			.map(group => group.id === groupId ? {
				...group,
				member,
			} : group);
		onUserChangeSetting('signerGroupsWithMembership', null, signerGroupsWithMembership);
	}, [settings, onUserChangeSetting]);

	const columns = [
		{
			field: 'name',
			headerName: t('user.settingsSignerGroupName'),
			editable: false,
			flex: 1
		},
		{
			field: 'member',
			headerName: t('user.settingsSignerGroupMember'),
			editable: false,
			width: 150,
			align: 'center',
			renderCell: (cellValues) =>
				<Checkbox
					checked={cellValues.row.member}
					onChange={(e) => onMemberChange(cellValues.row.id, e.target.checked)}
				/>
		}
	];

	return <DataGrid
		autoHeight
		disableColumnSelector
		disableColumnFilter
		disableRowSelectionOnClick
		pagination

		initialState={{
			pagination: {
				paginationModel: { pageSize: 10, page: 0 },
			},
		}}

		slots={{toolbar: FlexibleToolbar}}
		slotProps={{
			toolbar: {
				filterId: 'input-user-settings-signer-group-filter-value',
				filterValue,
				onChangeFilterValue,
			}
		}}

		columns={columns}

		rows={settings.signerGroupsWithMembership
			.filter(group => group.name.toUpperCase().indexOf(filterValue.toUpperCase()) >= 0)}
		pageSizeOptions={[10]}
		density="compact"
	/>
}

class UserSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = deriveState(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(deriveState(this.props), () => this.props.onUserFetchSettings(this.props.userId));
		}
		if (!prevProps.userSettings && !!this.props.userSettings && this.props.userSettings.guest && WIZARD_PAGE_FOLDER_PRIVILEGES_ETC === this.state.wizardPage) {
			this.setState({wizardPage: WIZARD_PAGE_SIGNING_STUFF});
		}
	}

	render() {
		const userSettings = this.props.userSettings;
		const sessionInfo = this.props.sessionInfo;
		const wizardPage = this.state.wizardPage;

		return <>
			<Dialog open={this.props.open} onClose={this.onClose} fullWidth maxWidth="md">
				<DialogTitle>{this.props.t('user.settings')}</DialogTitle>
				{!userSettings && <DialogContent><LoadingComponent/></DialogContent>}
				{userSettings && <DialogContent>
					<ServerErrorComponent serverError={this.props.userSignatureUpdateError} />
					<Typography>{`${userSettings.firstName} ${userSettings.lastName}`}</Typography>
					<Stepper activeStep={-1} orientation="vertical">
						{WIZARD_PAGE_DISABLED == wizardPage && <Step active>
							<StepLabel>{this.props.t('user.settingsGeneral')}</StepLabel>
							<StepContent>
								<UserGeneralSettingsComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting} />
							</StepContent>
						</Step>}

						{WIZARD_PAGE_SIGNING_STUFF == wizardPage && <Step active>
							<StepLabel>{this.props.t('user.memo')}</StepLabel>
							<StepContent>
								<UserMemoSettingComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting} />
							</StepContent>
						</Step>}

						{(WIZARD_PAGE_DISABLED == wizardPage || WIZARD_PAGE_SIGNING_STUFF == wizardPage) && <Step active>
							<StepLabel>{this.props.t('user.settingsVisualSignature')}</StepLabel>
							<StepContent>
								<UserVisualSignatureComponent settings={userSettings} userId={this.props.userId}
															  onUserChangeVisualSignature={this.props.onUserChangeVisualSignature} />
							</StepContent>
						</Step>}

						{WIZARD_PAGE_DISABLED == wizardPage && <Step active>
							<StepLabel>{this.props.t('user.settingsUserMetaAttributes')}</StepLabel>
							<StepContent>
								<UserMetaAttributeSettingsComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting}/>
							</StepContent>
						</Step>}

						{WIZARD_PAGE_DISABLED == wizardPage &&
							(sessionInfo.companyAdmin || sessionInfo.companyOutOfOfficeManager) && <Step active>
								<StepLabel>{this.props.t('user.settingsOutOfOffice')}</StepLabel>
								<StepContent>
									<UserOutOfOfficeSettingsComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting}/>
								</StepContent>
							</Step>}

						{(WIZARD_PAGE_DISABLED == wizardPage || WIZARD_PAGE_SIGNING_STUFF == wizardPage) && <Step active>
							<StepLabel>{this.props.t('user.settingsCommunication')}</StepLabel>
							<StepContent>
								<UserCommunicationSettingsComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting} />
							</StepContent>
						</Step>}

						{(WIZARD_PAGE_DISABLED == wizardPage || WIZARD_PAGE_FOLDER_PRIVILEGES_ETC == wizardPage) &&
							(sessionInfo.companyAdmin || sessionInfo.applicationAdmin || sessionInfo.childCompanyApplicationAdmin) &&
							<Step active>
								<StepLabel>{this.props.t('user.settingsPermissions')}</StepLabel>
								<StepContent>
									<UserPermissionSettingsComponent settings={userSettings} onUserChangeSetting={this.props.onUserChangeSetting} />
								</StepContent>
							</Step>
						}

						{(WIZARD_PAGE_FOLDER_PRIVILEGES_ETC == wizardPage) &&
							<Step active>
								<StepLabel>{this.props.t('user.settingsUserGroups')}</StepLabel>
								<StepContent>
									{sessionInfo.userGroupOverviewAccessible ?
										<UserGroupsSettingsComponent settings={userSettings}
																	 onUserChangeSetting={this.props.onUserChangeSetting}/>
										:
										<Alert severity="warning">{this.props.t('user.settingsUserGroupsNoAccess')}</Alert>
									}
								</StepContent>
							</Step>
						}

						{(WIZARD_PAGE_FOLDER_PRIVILEGES_ETC == wizardPage) &&
							<Step active>
								<StepLabel>{this.props.t('user.settingsDocumentFolders')}</StepLabel>
								<StepContent>
									{sessionInfo.documentFolderOverviewAccessible ?
										<UserFolderSettingsComponent settings={userSettings}
																	 onUserChangeSetting={this.props.onUserChangeSetting}/>
										:
										<Alert severity="warning">{this.props.t('user.settingsDocumentFoldersNoAccess')}</Alert>
									}
								</StepContent>
							</Step>
						}

						{(WIZARD_PAGE_SIGNING_STUFF == wizardPage) &&
							<Step active>
								<StepLabel>{this.props.t('user.settingsSignerGroups')}</StepLabel>
								<StepContent>
									{sessionInfo.signerGroupOverviewAccessible ?
										<SignerGroupsSettingsComponent settings={userSettings}
																	   onUserChangeSetting={this.props.onUserChangeSetting}/>
										:
										<Alert severity="warning">{this.props.t('user.settingsSignerGroupsNoAccess')}</Alert>
									}
								</StepContent>
							</Step>
						}

						{(WIZARD_PAGE_DISABLED == wizardPage || WIZARD_PAGE_LAST == wizardPage) && <Step active>
							<StepLabel>{this.props.t('user.settingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('user.settingsFinishDescription')}</Typography>
							</StepContent>
						</Step>}
					</Stepper>
				</DialogContent>}

				<DialogActions>
					<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
					{(WIZARD_PAGE_DISABLED != wizardPage) &&
						<Button
							disabled={WIZARD_PAGE_FIRST == wizardPage || userSettings.guest}
							onClick={this.onWizardPrevious}
							id="btn-settings-previous"
						>
							{this.props.t('previous')}
						</Button>
					}
					{(WIZARD_PAGE_DISABLED != wizardPage) && (WIZARD_PAGE_LAST != wizardPage) &&
						<Button
							onClick={this.onWizardNext}
							id="btn-settings-next"
						>
							{this.props.t('next')}
						</Button>
					}
					{(WIZARD_PAGE_DISABLED == wizardPage || WIZARD_PAGE_LAST == wizardPage) &&
						<Button
							variant="contained"
							disabled={!this.canContinue()}
							onClick={this.onUpdate}
							id="btn-settings-save"
						>
							{this.props.t('user.settingsSave')}
						</Button>
					}
				</DialogActions>
			</Dialog>
		</>
	}

	canContinue = () => {
		return !!this.props.userSettings && !!this.props.userSettings.firstName?.trim() && !!this.props.userSettings.lastName?.trim() && !!this.props.userSettings.email.trim() &&
			(!this.props.userSettings.phoneNumber?.trim() || !SIGNER_SMS_OTP_NUMBER_ERROR(this.props.userSettings.phoneNumber?.trim())) &&
			(this.props.userSettings.outOfOfficeSettings.status !== 'CONFIGURED_ACTIVE' ||
			(!!this.props.userSettings.outOfOfficeSettings.fromTime && !!this.props.userSettings.outOfOfficeSettings.untilTime));
	}

	onWizardPrevious = () => {
		this.setState({ wizardPage: Math.max(this.state.wizardPage - 1, WIZARD_PAGE_FIRST) });
	}

	onWizardNext = () => {
		this.setState({ wizardPage: Math.min(this.state.wizardPage + 1, WIZARD_PAGE_LAST) });
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onUpdate = () => {
		this.props.onUserUpdateSettings(this.props.userSettings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			userSettings: state.user.settings,
			userSignatureUpdateError: state.user.signatureUpdateError,

			sessionInfo: state.session.info,
		}
	},
	dispatch => {
		return {
			onUserFetchSettings: (userId) => {
				dispatch({
					type: 'USER_FETCH_SETTINGS',
					userId
				});
			},
			onUserChangeSetting: (key, subkey, value) => {
				dispatch({
					type: 'USER_CHANGE_SETTING',
					key,
					subkey,
					value
				});
			},
			onUserChangeVisualSignature: (userId, visualSignature, mimeType, fileSize) => {
				dispatch({
					type: 'USER_UPDATE_VISUAL_SIGNATURE',
					userId,
					visualSignature,
					mimeType,
					fileSize
				});
			},
		}
	}
)(UserSettingsDialog));
