import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Step, StepContent, StepLabel, Stepper, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const defaultStateGen = (props) => ({
	name: props.current?.name || '',
	vaultUrl: props.current?.vaultUrl || '',
	certificateName: props.current?.certificateName || '',
	clientId: props.current?.clientId || '',
	clientSecret: props.current?.clientSecret || '',
	clientSecretLength: props.current?.clientSecretLength || undefined,
	tenantId: props.current?.tenantId || '',
	keyId: props.current?.keyId || '',
	showSecret: false
})

class CompanyKeyVaultCertificateConfigDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultStateGen(this.props)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultStateGen(this.props));
		}
	}

	render() {
		return <>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="md"
			>
				<DialogTitle>{this.props.t('company.certificateSettingsConfig')}</DialogTitle>
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('company.certificateSettingsConfigHeader')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigName')}
										required={true}
										value={this.state.name || ''}
										onChange={this.onChangeName}
										autoComplete="off"
										autoFocus
										fullWidth
										id="input-company-certificateconfig-name"
									/>
								</Box>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.certificateSettingsConfigVaultConnectionHeader')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigVaultUrl')}
										required={true}
										value={this.state.vaultUrl || ''}
										onChange={this.onChangeVaultUrl}
										autoComplete="off"
										fullWidth
										id="input-company-certificateconfig-vaulturl"
									/>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigTenantId')}
										required={true}
										value={this.state.tenantId || ''}
										onChange={this.onChangeTenantId}
										autoComplete="off"
										fullWidth
										id="input-company-certificateconfig-tenantid"
									/>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigClientId')}
										required={true}
										value={this.state.clientId || ''}
										onChange={this.onChangeClientId}
										autoComplete="off"
										fullWidth
										id="input-company-certificateconfig-clientid"
									/>
									<TextField
										variant="outlined"
										type={this.state.showSecret ? 'text' : 'password'}
										label={this.props.t('company.certificateSettingsConfigClientSecret')}
										value={!!this.state.clientSecret ? this.state.clientSecret : (!!this.state.clientSecretLength ? '*'.repeat(this.state.clientSecretLength) : '')}
										required={true}
										onChange={this.onChangeClientSecret}
										fullWidth
										autoComplete="new-password"
										id="input-company-certificateconfig-clientsecret"
										InputProps={{
											endAdornment: <InputAdornment position="end">
												<IconButton
													sx={{visibility: !!this.state.clientSecretLength ? 'hidden' : 'visible'}}
													onClick={this.onToggleShowSecret}
													edge="end">{this.state.showSecret ? <VisibilityOff/> : <Visibility/>}
												</IconButton>
											</InputAdornment>
										}}
									/>
								</Box>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.certificateSettingsConfigCertificateHeader')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigCertificateName')}
										required={true}
										value={this.state.certificateName || ''}
										onChange={this.onChangeCertificateName}
										autoComplete="off"
										fullWidth
										id="input-company-certificateconfig-certificatename"
									/>
									<TextField
										variant="outlined"
										label={this.props.t('company.certificateSettingsConfigKeyId')}
										required={true}
										value={this.state.keyId || ''}
										onChange={this.onChangeKeyId}
										autoComplete="off"
										fullWidth
										id="input-company-certificateconfig-keyid"
									/>
								</Box>
							</StepContent>
						</Step>

					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-company-certificateconfig-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							onClick={this.onConfirm}
							disabled={!this.canContinue()}
							id="btn-company-certificateconfig-settings-confirm"
					>
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onConfirm();
		}
	}

	canContinue = () => {
		return (!!this.state.name && !!this.state.vaultUrl && !!this.state.certificateName && !!this.state.clientId && (!!this.state.clientSecret || !!this.state.clientSecretLength) && !!this.state.tenantId && !!this.state.keyId);
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeVaultUrl = (e) => {
		this.setState({vaultUrl: e.target.value});
	}

	onChangeCertificateName = (e) => {
		this.setState({certificateName: e.target.value});
	}

	onChangeClientId = (e) => {
		this.setState({clientId: e.target.value});
	}

	onToggleShowSecret = (e) => {
		this.setState({showSecret: !this.state.showSecret})
	}

	onChangeClientSecret = (e) => {
		this.setState({clientSecret: e.target.value, clientSecretLength: undefined});
	}

	onChangeTenantId = (e) => {
		this.setState({tenantId: e.target.value});
	}

	onChangeKeyId = (e) => {
		this.setState({keyId: e.target.value});
	}

	onConfirm = () => {
		this.props.onConfirm({
			id: this.props.current?.id,
			internalId: this.props.current?.internalId,
			name: this.state.name,
			vaultUrl: this.state.vaultUrl,
			certificateName: this.state.certificateName,
			clientId: this.state.clientId,
			clientSecret: !!this.state.clientSecret ? this.state.clientSecret : undefined,
			clientSecretLength: this.state.clientSecretLength,
			tenantId: this.state.tenantId,
			keyId: this.state.keyId
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(CompanyKeyVaultCertificateConfigDialog));

